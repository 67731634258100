import { mailerUrl } from '../config'
import {
  IAPIResult,
  TTableData,
  TRowData,
  IRow,
  TAssignmentModel,
  IColumn,
  IKeyValuePair,
  TAssignmentSectionData,
  TAssignmentLotPage,
  UploadDocumentParams,
  TSetSendFileRequest,
  TAssignmentRequest,
  AssignmentTab,
  TAssignmentLotRequest,
  TLotPositionWinners,
  TLotPositionItemWinners,
  ELotStatusDesc,
  IAssignmentLot,
  EmptyPricePositions,
  TExportPriceHistoryRequest,
} from '../types'

import { apiClient, ApiResponse } from './base-api-service/base-api-service'

const MAILER_URL = mailerUrl || 'https://tender-mailer.dev.gmini.tech'

export const getMailerUrl = (lotId: string): string =>
  `${MAILER_URL}/tenders/new/grandtender/sendLot/${lotId}`

export const getMailerUrlSendAllTz = (tzId: string): string =>
  `${MAILER_URL}/tenders/new/grandtender/sendTz/${tzId}`

const headers: Record<string, string> = { 'Content-Type': 'application/json' }

const assignmentsPath = {
  [AssignmentTab.All]: '/tender/page/filter',
  [AssignmentTab.Closed]: '/tender/page/closed/filter',
  [AssignmentTab.Archived]: '/tender/page/archived/filter',
}

export class AssignmentService {
  static getTenders(
    params: TAssignmentRequest,
    tab: AssignmentTab,
  ): Promise<ApiResponse<IAPIResult<TTableData>>> {
    return apiClient.post({
      path: assignmentsPath[tab],
      headers,
      body: params,
    })
  }

  static getTendersSelectAll(
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/tender/select/all/${categoryId}`,
      headers,
    })
  }

  static getTendersClosedSelectAll(
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/tender/select/past/${categoryId}`,
      headers,
    })
  }

  static getTenderData(
    assignmentId: string,
  ): Promise<ApiResponse<IAPIResult<TAssignmentModel>>> {
    return apiClient.post({ path: `/tender/detail/${assignmentId}`, headers })
  }

  static addLotPositionWinners(
    params: TLotPositionWinners,
  ): Promise<ApiResponse<IAPIResult<any>>> {
    return apiClient.post({
      path: `/tender/select/winners`,
      headers,
      body: params,
    })
  }

  static getLotPositionWinners(
    params: TLotPositionItemWinners,
  ): Promise<ApiResponse<IAPIResult<TLotPositionWinners>>> {
    return apiClient.post({
      path: `/tender/select/winners/result`,
      headers,
      body: params,
    })
  }

  static getLotSelectAll(
    lotId: string,
    tenderId: string,
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/lot/select/category `,
      headers,
      body: {
        lotId,
        tenderId,
        categoryId,
      },
    })
  }

  static addTender(
    assignment: TRowData,
  ): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.put({
      path: `/tender`,
      headers,
      body: { data: assignment },
    })
  }

  static deleteTenders(
    ids: Array<string>,
  ): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.post({ path: `/tender/delete`, headers, body: ids })
  }

  static exportPriceHistoryXls(
    body: TExportPriceHistoryRequest,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    return apiClient.post({
      path: 'export/lot/price-history',
      body,
      responseType: 'blob',
    })
  }

  static exportTendersByIds(
    ids: Array<string>,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    return apiClient.post({
      path: `/export/tender/list`,
      body: ids,
      responseType: 'blob',
    })
  }

  static searchTenders(
    query: string,
  ): Promise<ApiResponse<IAPIResult<TRowData>>> {
    return apiClient.post({
      path: `/tender/search`,
      headers,
      body: {
        query,
      },
    })
  }

  static addProperty(
    model: IColumn,
    categoryId: string,
    sectionId: string,
  ): Promise<ApiResponse<IAPIResult<IColumn>>> {
    return apiClient.put({
      path: `/category/tender/field`,
      headers,
      body: {
        categoryId,
        property: model,
        sectionId,
      },
    })
  }

  static editProperty(
    model: IColumn,
    sectionId: string,
  ): Promise<ApiResponse<IAPIResult<IColumn>>> {
    return apiClient.post({
      path: `/category/tender/field`,
      headers,
      body: {
        ...model,
        sectionId,
      },
    })
  }

  static removeProperty(key: string): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.delete({ path: `/category/tender/field/${key}`, headers })
  }

  static addToAssignment(
    positionIds: Array<string>,
    lotId: string,
  ): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.post({
      path: `/tender/item`,
      headers,
      body: {
        sourceIds: positionIds,
        targetId: lotId,
      },
    })
  }

  static addCategoriesToLot(
    categoryIds: Array<string>,
    lotId: number,
  ): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.post({
      path: `/item/getAllSelectedItems`,
      headers,
      body: {
        categoryIds,
        lotId,
      },
    })
  }

  static addLotToAssignment(
    idAddTo: number,
    name: string,
    description: string,
    specializationsIds: Array<number>,
  ): Promise<ApiResponse<IAPIResult<IAssignmentLot>>> {
    return apiClient.put({
      path: `/tender/lot`,
      headers,
      body: {
        idAddTo,
        name,
        description,
        specializationsIds,
      },
    })
  }

  static editAssignment(data: IRow): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.post({
      path: `/tender`,
      headers,
      body: {
        ...data,
        // data: data.data.filter(i => i.value !== ''),
      },
    })
  }

  static deleteAssignment(id: number): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.post({ path: `/tender/delete`, headers, body: [id] })
  }

  static changeStatusAssignment(
    tenderId: number,
    status: string,
  ): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.post({
      path: `/tender/status`,
      headers,
      body: {
        tenderId,
        status,
      },
    })
  }

  static addLotProperty(
    lotId: string,
    name: string,
  ): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.put({
      path: `/lot/field`,
      headers,
      body: {
        lotId,
        name,
      },
    })
  }

  static editLotProperty(
    key: string,
    name: string,
  ): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.post({
      path: `/lot/field`,
      headers,
      body: {
        fieldId: key,
        name,
      },
    })
  }

  static removeLotProperty(key: string): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.delete({ path: `/lot/field/${key}`, headers })
  }

  // TODO 1193
  static exportTemplate(
    lotId: string,
    categories: {
      categoryId: string
      propositionCustomFieldIds: string[]
      columnIds: string[]
    }[],
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    return apiClient.post({
      path: `/export/tender`,
      body: { lotId, categories },
      responseType: 'blob',
    })
  }

  // TODO 1193
  static exportLot(params: {
    lotId: string
    providersIds: Array<string>
    categories: Array<{
      categoryId: string
      propositionCustomFieldIds: Array<string>
    }>
  }): Promise<ApiResponse<IAPIResult<Blob>>> {
    return apiClient.post({
      path: `/export/lot`,
      body: params,
      responseType: 'blob',
    })
  }

  static removeAssignmentLotPositions(
    lotId: string,
    positionsIds: Array<string>,
  ): Promise<ApiResponse<IAPIResult<string>>> {
    return apiClient.post({
      path: `/lot/item/delete`,
      headers,
      body: {
        lotId,
        itemIds: positionsIds,
      },
    })
  }

  static removeAssignmentLotSupplier(
    lotId: string,
    providerId: string,
  ): Promise<ApiResponse<IAPIResult<string>>> {
    return apiClient.post({
      path: `/lot/provider/delete`,
      headers,
      body: {
        lotId,
        providerId,
      },
    })
  }

  static removeAssignmentLot(
    lotId: string,
  ): Promise<ApiResponse<IAPIResult<string>>> {
    return apiClient.delete({ path: `/lot/${lotId}`, headers })
  }

  static combineAssignmentLot(
    sourceId: string,
    destinationId: string,
  ): Promise<ApiResponse<IAPIResult<string>>> {
    return apiClient.post({
      path: `/lot/merge`,
      headers,
      body: {
        sourceId,
        destinationId,
      },
    })
  }

  static changeLotAssignment(
    lotId: string,
    assignmentId: string,
  ): Promise<ApiResponse<IAPIResult<string>>> {
    return apiClient.post({
      path: `/lot/move`,
      headers,
      body: {
        lotId,
        tenderId: assignmentId,
      },
    })
  }

  // TODO 1193 Проверить
  static getEmptyPricePositions(
    lotId: string,
    tenderId: string,
  ): Promise<ApiResponse<IAPIResult<EmptyPricePositions>>> {
    return apiClient.post({
      path: `/lot/empty-price-position-name`,
      headers,
      body: {
        lotId,
        tenderId,
        page: {
          page: 1,
          perPage: 100,
        },
      },
    })
  }

  static changeLotName(
    lotId: string,
    name: string,
    description: string,
    status: keyof typeof ELotStatusDesc,
    specializationIds?: Array<number>,
  ): Promise<ApiResponse<IAPIResult<string>>> {
    return apiClient.post({
      path: `/lot`,
      headers,
      body: {
        lotId,
        name,
        description,
        specializationIds,
        status,
      },
    })
  }

  static movePositions(
    fromLotId: string,
    toLotId: string,
    positionsIds: Array<string>,
    removeFromCurrentLot: boolean,
  ): Promise<ApiResponse<IAPIResult<string>>> {
    return apiClient.post({
      path: `/lot/item/${removeFromCurrentLot ? 'move' : 'copy'}`,
      headers,
      body: {
        from: fromLotId,
        to: toLotId,
        itemIds: positionsIds,
      },
    })
  }
  // TODO 1193
  static addAssignmentLotSupplier(
    lotId: string,
    supplierId: string,
    file: File,
    isNewVersion?: boolean,
  ): Promise<ApiResponse<IAPIResult<{}>>> {
    const params = { providerId: supplierId }
    const formData = new FormData()
    formData.append('file', file)
    return apiClient.post({
      path: `/import/tender/${lotId}`,
      body: formData,
      params: isNewVersion ? { ...params, 'is-new-version': true } : params,
    })
  }
  // TODO 1193
  static updateAssignmentLotSupplier(
    lotId: string,
    supplierId: string,
    file: File,
  ): Promise<ApiResponse<IAPIResult<{}>>> {
    const params = { providerId: supplierId }
    const formData = new FormData()
    formData.append('file', file)
    return apiClient.post({
      path: `/import/tender/recalc/${lotId}`,
      body: formData,
      params,
    })
  }

  static getLotCategory(
    params: TAssignmentLotRequest,
  ): Promise<ApiResponse<IAPIResult<TAssignmentLotPage>>> {
    return apiClient.post({ path: `/lot/filter`, headers, body: params })
  }

  static getAssignmentLots(
    assignmentId: string,
  ): Promise<ApiResponse<IAPIResult<TRowData>>> {
    return apiClient.post({ path: `/lot/search/${assignmentId}`, headers })
  }

  static getAssignmentById(
    assignmentId: string,
  ): Promise<ApiResponse<IAPIResult<TTableData>>> {
    return apiClient.get({ path: `/tender/${assignmentId}`, headers })
  }

  static getArgeementCatalog(
    query: string,
  ): Promise<ApiResponse<IAPIResult<Array<IKeyValuePair>>>> {
    return apiClient.post({
      path: `/category/agreement/search`,
      headers,
      body: {
        query,
      },
    })
  }

  static searchSections(
    categoryId: string,
    query: string,
  ): Promise<ApiResponse<IAPIResult<TRowData>>> {
    return apiClient.post({
      path: `/section/search/${categoryId}`,
      headers,
      body: {
        query,
      },
    })
  }

  static addSection(
    categoryId: string,
    name: string,
  ): Promise<ApiResponse<IAPIResult<IKeyValuePair>>> {
    return apiClient.put({
      path: `/section`,
      headers,
      body: {
        name,
        categoryId,
      },
    })
  }

  static editSection(
    id: string,
    name: string,
  ): Promise<ApiResponse<IAPIResult<IKeyValuePair>>> {
    return apiClient.post({
      path: `/section`,
      headers,
      body: {
        id,
        name,
      },
    })
  }

  static removeSection(id: string): Promise<ApiResponse<IAPIResult<void>>> {
    return apiClient.delete({ path: `/section/${id}`, headers })
  }

  static getAssignmentSectionData(
    assignmentId: string,
  ): Promise<ApiResponse<IAPIResult<TAssignmentSectionData>>> {
    return apiClient.get({ path: `/section/${assignmentId}`, headers })
  }
  // TODO 1193
  static uploadDocument(
    params: UploadDocumentParams,
  ): Promise<ApiResponse<IAPIResult<unknown>>> {
    const formData = new FormData()

    Object.keys(params).forEach(key => {
      formData.append(key, params[key as keyof UploadDocumentParams])
    })
    return apiClient.post({
      path: `/attachment/savetotendertask`,
      body: formData,
    })
  }

  static deleteDocument(
    id: string | number,
  ): Promise<ApiResponse<IAPIResult<unknown>>> {
    return apiClient.delete({ path: `/attachment/delete/${id}` })
  }

  static setSendFlag({ id, value }: TSetSendFileRequest): Promise<unknown> {
    return apiClient.patch({
      path: `/attachment/${id}`,
      headers,
      body: {
        sendFile: value,
      },
    })
  }

  static setExportSectionFlag(
    tenderId: number,
    sectionId: number,
    value: boolean,
  ): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.post({
      path: '/section/set-export',
      headers,
      body: {
        tenderId,
        sectionId,
        export: value,
      },
    })
  }
}
