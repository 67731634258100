/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react'
import { Table, TablePagination } from '@material-ui/core'
import * as _ from 'lodash'

import { IImprovedTableToolbar, TSort, TColumn, IColumn } from '../../types'

import { LocalStorageHelper } from '../../utils'

import { ImprovedTableHead } from './ImprovedTableHead/ImprovedTableHead'
import { ImprovedTableBody } from './ImprovedTableBody/ImprovedTableBody'
import { ImprovedTableToolbar } from './ImprovedTableToolbar/ImprovedTableToolbar'
import * as I from './IImprovedTable'
import { Container, Root } from './Style'
import './ImageStyle.css'

export function ImprovedTable({
  columns,
  data,
  pagination,
  TitleComponent,
  showAddBtn,
  showToolbar = true,
  showEditPropertyButtons = true,
  showPagination = true,
  showSections = false,
  isDeepTreeLevel,
  addBtnText,
  onAddBtnClick,
  nameCatalog,
  dataIsLoading = false,
  isRowChecked = true,
  onMoveToLotsBtnClick,
  onGetCheckedCategories,
  categoryId,
  localStorageTableId,
  hideDisplayColumns,
  getDataHandler,
  onSelectNode,
  addPropertyHandler,
  editPropertyHandler,
  removePropertyHandler,
  handleChangePage,
  handleChangeRowsPerPage,
  getParamsSelectAllRequest,
  onAddSection,
  onEditSection,
  onRemoveSection,
  getSectionsDataSource,
  authRoleChecker,
  dataTestIdPrefix,
}: I.OwnProps): JSX.Element {
  const [sortDirection, setSortDirection] = useState<TSort>()
  const [sortColumnKey, setSortColumnKey] = useState<number>(0)
  const [selectedRows, setSelectedRows] = useState<Array<string>>([])

  const [visibleColumns, setVisibleColumns] = useState<TColumn>([])

  useEffect(() => {
    const columnSettings: TColumn | null = LocalStorageHelper.get(
      localStorageTableId,
    )
    const requiredColumns = columns.filter(
      column => column.required || column.base,
    )

    if (columnSettings) {
      const withoutRequired = columnSettings.filter(column => !column.base)

      setVisibleColumns(
        _.unionBy([...requiredColumns, ...withoutRequired], 'title'),
      )
      return
    }
    const firstRenderColWithRules = columns.filter(
      col => !(col.title === 'Автор' || col.title === 'Дата создания'),
    )
    setVisibleColumns(firstRenderColWithRules)
  }, [columns])

  useEffect(() => {
    if (categoryId) {
      setSelectedRows([])
    }
  }, [categoryId])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    key: number,
  ): void => {
    let newSortColumnKey = sortColumnKey
    let newSortDirection: 'asc' | 'desc' | undefined

    if (key !== sortColumnKey) {
      newSortColumnKey = key
      newSortDirection = 'asc'
    } else {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }

    setSortColumnKey(newSortColumnKey)
    setSortDirection(newSortDirection)

    handleChangePage(undefined, 0, newSortColumnKey, newSortDirection)
  }

  const checkboxClickHandler = (
    event: React.MouseEvent<HTMLElement>,
    name: string,
  ): void => {
    const selectedIndex = selectedRows.indexOf(name)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1))
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      )
    }
    setSelectedRows(newSelected)
  }

  const toolbarProps: IImprovedTableToolbar = {
    TitleComponent,
    selectedRowsCount: selectedRows.length,
    showAddBtn,
    addBtnText,
    onAddBtnClick,
    dataIsLoading,
    onGetCheckedCategories,
    isDeepTreeLevel,
    onMoveToLotsBtnClick: (): void => {
      if (onMoveToLotsBtnClick) {
        onMoveToLotsBtnClick(selectedRows)
      }
    },
    onSelectNode,
    authRoleChecker,
    dataTestIdPrefix,
  }

  const handleSelectedVisibleColumns = (columnsData: TColumn): void => {
    const uniqColumns = _.uniq(columnsData)
    setVisibleColumns(uniqColumns)

    LocalStorageHelper.set<TColumn>(localStorageTableId, uniqColumns)
  }

  const handleClickColumnVisibilityCheckbox = (
    event: React.MouseEvent<unknown>,
    column: IColumn,
  ): void => {
    const selectedIndex = _.findIndex(visibleColumns, c => c.key === column.key)
    let newSelected: TColumn = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(visibleColumns, column)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(visibleColumns.slice(1))
    } else if (selectedIndex === visibleColumns.length - 1) {
      newSelected = newSelected.concat(visibleColumns.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        visibleColumns.slice(0, selectedIndex),
        visibleColumns.slice(selectedIndex + 1),
      )
    }

    setVisibleColumns(newSelected)
    handleSelectedVisibleColumns(newSelected)
  }

  return (
    <Root elevation={0}>
      {showToolbar && <ImprovedTableToolbar {...toolbarProps} />}
      <>
        <Container>
          <Table
            aria-labelledby='tableTitle'
            size='small'
            aria-label='enhanced table'
            stickyHeader
          >
            <ImprovedTableHead
              handleClickColumnVisibilityCheckbox={
                handleClickColumnVisibilityCheckbox
              }
              visibleColumns={visibleColumns}
              key={categoryId}
              isRowChecked={isRowChecked}
              selectedRows={selectedRows}
              sortDirection={sortDirection}
              sortColumnKey={sortColumnKey}
              onRequestSort={handleRequestSort}
              getParamsSelectAllRequest={getParamsSelectAllRequest}
              setSelectedRows={setSelectedRows}
              data={data}
              columns={columns}
              showEditPropertyButtons={showEditPropertyButtons}
              addPropertyHandler={addPropertyHandler}
              editPropertyHandler={editPropertyHandler}
              removePropertyHandler={removePropertyHandler}
              onAddSection={onAddSection}
              onEditSection={onEditSection}
              onRemoveSection={onRemoveSection}
              getSectionsDataSource={getSectionsDataSource}
              hideDisplayColumns={hideDisplayColumns}
              showSections={showSections}
              dataTestIdPrefix={dataTestIdPrefix}
            />
            <ImprovedTableBody
              requiredColumns={columns.filter(c => c.required)}
              isRowChecked={isRowChecked}
              data={data}
              selectedRows={selectedRows}
              visibleColumns={visibleColumns}
              nameCatalog={nameCatalog}
              onRowSelectingChanged={checkboxClickHandler}
              showSections={showSections}
              dataTestIdPrefix={dataTestIdPrefix}
            />
          </Table>
        </Container>
        {showPagination && (
          <TablePagination
            component='div'
            rowsPerPageOptions={[10, 20, 50]}
            count={pagination.total}
            labelDisplayedRows={({ from, to, count }): string =>
              `${from}-${to} из ${count !== -1 ? count : `более чем ${to}`}`
            }
            labelRowsPerPage={'Строк на странице:'}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page - 1}
            onPageChange={(event: unknown, page: number): void => {
              handleChangePage(event, page, sortColumnKey, sortDirection)
            }}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement>,
            ): void => {
              handleChangeRowsPerPage(event, sortColumnKey, sortDirection)
            }}
            SelectProps={{
              inputProps: {
                'data-test-id': `${dataTestIdPrefix}TablePaginationSelect`,
              },
            }}
            backIconButtonProps={{
              datatype: `${dataTestIdPrefix}TablePaginationBackBtn`,
            }}
            nextIconButtonProps={{
              datatype: `${dataTestIdPrefix}TablePaginationNextBtn`,
            }}
          />
        )}
      </>
    </Root>
  )
}
