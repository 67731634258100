import { Typography } from '@material-ui/core'
import { IconButton } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

import { TabPanel } from '../../components/TabPanel/TabPanel'

export const SettingsButton = styled(IconButton)`
  && {
    margin-left: auto;
    margin-right: 0;
  }
`
export const TabPanelStyled = styled(TabPanel)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const Root = styled.div`
  display: flex;
  font-family: 'Proxima Nova Regular';
  margin: 0 -15px;
  height: 100%;
`

type FlexItemProps = {
  tabPanel?: boolean
}

export const FlexItem = styled.div`
  margin: 0 15px;
  ${({ tabPanel }: FlexItemProps) =>
    tabPanel
      ? css`
          flex: 1 1 auto;
          width: 100px;
        `
      : ''}
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & button {
    margin-right: 8px;
  }
`
export const Selected = styled(Typography)`
  flex: 1 1 auto;
` as typeof Typography

export const Table = styled.div`
  & .MuiDataGrid-cell.rowError {
    background-color: #fce6e6;
  }
  & .MuiDataGrid-cell.rowError:nth-child(even) {
    background-color: #f3dddd;
  }
`
