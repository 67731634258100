import styled from 'styled-components'
import { Checkbox, TableCell, TableHead, Button } from '@material-ui/core'

export const VisuallyHidden = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`
export const CheckedRow = styled(Checkbox)`
  && {
    padding: 0;
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0);
    }

    & .MuiTableCell-root {
      border: 0;
    }
  }
`
export const Header = styled(TableHead)`
  && {
    height: 46px;
    background-color: #f9f9fb;
    & .MuiSvgIcon-root {
      height: 24px;
      float: right;
    }
    & .MuiTableCell-root {
      border: 0;
    }
    & .MuiTableSortLabel-root {
      width: 100%;
    }
  }
`

type StyledTableCellProps = {
  sticky: boolean
}

export const StyledTableCell = styled(TableCell)`
  && {
    ${({ sticky }: StyledTableCellProps) => (sticky ? 'z-index: 3;' : '')}
  }
`
export const StickyCheckbox = styled(TableCell)`
  && {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    width: 64px;
    & + th {
      left: 64px;
    }
  }
`
export const CheckboxMenuButton = styled(Button)`
  && {
    justify-content: flex-start;
    min-width: 42px;
    padding: 0;
  }
`
export const ResizeHandle = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 12px;
  height: 20px;
  cursor: col-resize;
  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: rgba(224, 224, 224, 1);
    right: 0;
  }
`
export const WrappedTitle = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
