import styled from 'styled-components'
import { Typography } from '@gmini/ui-kit/lib/Typography'

import { IconButton } from '@gmini/ui-kit'

import { TabPanel as TabPanelInit } from '../../../components/TabPanel/TabPanel'

export const CatalogWrapper = styled.div`
  margin: 0 15px;
`

export const TabPanelWrapper = styled.div`
  margin: 20px 15px 0;
  flex: 1 1 auto;
  min-width: 0;
  width: 100%;
`

export const Root = styled.div`
  display: flex;
  font-family: 'Proxima Nova Regular';
  margin: 0 -15px;
  height: 100%;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & button {
    margin-right: 8px;
  }
`

export const SettingsButton = styled(IconButton)`
  && {
    margin-left: auto;
    margin-right: 0;
  }
`

export const Selected = styled(Typography)`
  flex: 1 1 auto;
`

export const TabPanel = styled(TabPanelInit)`
  display: flex;
  flex-direction: column;
  & * {
    min-height: 0;
  }
`

export const PathComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`

export const Path = styled.div`
  width: auto;
  max-width: 1200px;
`
