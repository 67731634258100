import { GridCellParams } from '@material-ui/x-grid'

import React, { SyntheticEvent, useCallback, useState } from 'react'

import {
  ClickAwayListener,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Link,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'

import { DEFAULT_DISPLAY_DATE_FORMAT, EColumnType } from '../../constants'
import { TShortAgreement } from '../../types'
import { renderCell } from '../../utils/renders'

import { StyledPopper, StyledTableCell } from './style'

type Props = {
  agreements: Array<TShortAgreement>
  params: GridCellParams
  type: EColumnType
  unit?: string
}

export const AssignmentAgreementsPopup: React.FC<Props> = ({
  agreements,
  params,
  type,
  unit,
}): React.ReactElement | null => {
  const [opened, setOpened] = useState(false)
  const handleOpen = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault()
      if (agreements.length) {
        setOpened(true)
      }
    },
    [agreements.length],
  )
  const handleClose = useCallback(() => {
    setOpened(false)
  }, [])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        arrow
        disableFocusListener
        disableTouchListener
        disableHoverListener
        open={opened}
        PopperComponent={StyledPopper}
        title={
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align='left'>№</StyledTableCell>
                <StyledTableCell align='left'>Дата начала</StyledTableCell>
                <StyledTableCell align='left'>Дата окончания</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agreements.map((data, index) => (
                <TableRow key={data.number}>
                  <StyledTableCell>
                    <RouterLink to={`/agreements/0/${data.number}`}>
                      {data.number}
                    </RouterLink>
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(data.startDate).format(DEFAULT_DISPLAY_DATE_FORMAT)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(data.expirationDate).format(
                      DEFAULT_DISPLAY_DATE_FORMAT,
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      >
        <Link href='#' onClick={handleOpen}>
          {renderCell(params, type, unit)}
        </Link>
      </Tooltip>
    </ClickAwayListener>
  )
}
