import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { TextField } from '@material-ui/core'

import { debounceTime } from 'rxjs/operators'

import { Subject } from 'rxjs'
import {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'

import _ from 'lodash'
import { toastr } from 'react-redux-toastr'

import { SearchService } from '../../services/search-service/search-service'

import {
  IKeyValuePair,
  TSearchDataAssignments,
  TAssignmentSearchRequestModel,
} from '../../types'
import { EAPIResponseStatus, closeIcon } from '../../constants'

import * as I from './IAppHeaderSearchBarAssignment'
import { CloseIcon, Search, StyledAutocomplete } from './Style'

export const AppHeaderSearchBarAssignment: React.FC<I.OwnProps> = ({
  searchFilterParams,
  flagBackPage,
  flagSentRequest,
  getSearchAssignmentFilterItems,
  handleChangeFlagSent,
  flagBackPageHandler,
  dropFilterItems,
  isArchived,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { tab } = useParams<{ tab: string }>()

  const [inputValue, setInputValue] = useState<string>('')
  const [value, setValue] = useState<string>('')

  const [optionsListOpen, setOptionsListOpen] = React.useState(false)
  const [onSearch$] = useState(
    () => new Subject<TAssignmentSearchRequestModel>(),
  )
  const [optionsListLoading, setOptionsListLoading] = useState<boolean>(false)

  const [flagSentRequestButton, setFlagSentRequestButton] = useState<boolean>(
    false,
  )
  const [flagCleanButton, setFlagCleanButton] = useState<boolean>(false)

  useEffect(() => {
    const subscription = onSearch$
      .pipe(debounceTime(400))
      .subscribe(async (debounced: TAssignmentSearchRequestModel) => {
        setOptionsListLoading(true)
        if (debounced && debounced.keyword && debounced.keyword?.length > 1) {
          const [err, result] = await SearchService.getAssignmentSearch(
            debounced,
          )

          if (
            result.data.status === EAPIResponseStatus.SUCCESS &&
            result.data.data
          ) {
            const searchValue: Array<TSearchDataAssignments> | undefined =
              result.data.data.searchResults

            if (searchValue && searchValue.length > 0) {
              const arr: Array<IKeyValuePair> = []
              searchValue.map(d =>
                d.names.map(data => arr.push({ key: d.path, value: data })),
              )
            }
          }

          if (err) {
            //TODO handle error
            toastr.error('Ошибка', '')
          }
          setOptionsListLoading(false)
        } else {
          setOptionsListLoading(false)
        }
      })

    return (): void => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [onSearch$])

  const paramsAssignment = {
    keyword: value === '' ? null : value,
    fetchTenders: true,
    tenderId: null,
    pageData: {
      page: 1,
      perPage: 10,
      categoryId: '',
    },
  }

  useEffect(() => {
    if (flagBackPage && inputValue) {
      setInputValue('')
      setValue('')
      flagBackPageHandler()
    }
    if (flagCleanButton && (inputValue || inputValue.length === 0)) {
      setInputValue('')
      setValue('')
      dropFilterItems()
      setFlagCleanButton(false)
      history.push(`/assignments/${tab}`)
    }
    // eslint-disable-next-line
  }, [flagBackPage, inputValue, flagCleanButton])

  useEffect(() => {
    if (getSearchAssignmentFilterItems) {
      if (value.length >= 0 && flagSentRequest) {
        const generalParams = _.assign(
          { ...searchFilterParams },
          paramsAssignment,
        )
        getSearchAssignmentFilterItems(
          generalParams,
          location.pathname.includes('search')
            ? (): number => 0
            : (): void => history.push(`/assignments/${tab}/search`),
        )
        handleChangeFlagSent(false)
      } else if (value.length > 1 && flagSentRequestButton) {
        const generalParams = _.assign(
          { ...searchFilterParams },
          paramsAssignment,
        )
        getSearchAssignmentFilterItems(
          generalParams,
          location.pathname.includes('search')
            ? (): number => 0
            : (): void => history.push(`/assignments/${tab}/search`),
        )
        setFlagSentRequestButton(false)
      } else {
        handleChangeFlagSent(false)
        setFlagSentRequestButton(false)
      }
    }
    // eslint-disable-next-line
  }, [flagSentRequest, value, flagSentRequestButton])

  // ACT-962 ГТ: Убрать живой поиск для исключения множества запросов
  const onSearchButtonClick = React.useCallback(async () => {
    if (!inputValue) {
      return
    }

    setOptionsListLoading(true)

    const paramShort = {
      keyword: inputValue,
      fetchTenders: true,
      tenderId: null,
      pageData: {
        page: 1,
        perPage: 10,
        categoryId: '',
      },
      deleted: Boolean(isArchived),
    }
    const generalParams: TAssignmentSearchRequestModel = _.assign(
      searchFilterParams,
      paramShort,
    )
    const [err, result] = await SearchService.getAssignmentSearch(generalParams)

    if (result.data.status === EAPIResponseStatus.SUCCESS && result.data.data) {
      const searchValue: Array<TSearchDataAssignments> | undefined =
        result.data.data.searchResults

      if (searchValue && searchValue.length > 0) {
        const arr: Array<IKeyValuePair> = []
        searchValue.map(d =>
          d.names.map(data => arr.push({ key: d.path, value: data })),
        )
      }
    }
    if (err) {
      //TODO handle error
      toastr.error('Ошибка', '')
    }

    setOptionsListLoading(false)
  }, [inputValue, isArchived, searchFilterParams])

  return (
    <Search>
      <StyledAutocomplete
        filterOptions={(option: Array<IKeyValuePair>): Array<IKeyValuePair> =>
          option
        }
        open={optionsListOpen}
        clearOnBlur={false}
        fullWidth
        disableClearable
        onOpen={(): void => {
          // ACT-962 ГТ: Убрать живой поиск для исключения множества запросов
          // if (searchValues.length) {
          //   setOptionsListOpen(true)
          // }
        }}
        onClose={(): void => {
          setOptionsListOpen(false)
        }}
        inputValue={inputValue}
        onInputChange={(e: React.ChangeEvent<{}>, val: string): void => {
          setInputValue(val)
        }}
        onChange={(
          event: React.ChangeEvent<{}>,
          newValue: IKeyValuePair | null,
        ): void => {
          if (newValue !== null) {
            setValue(newValue.value)
          }
        }}
        getOptionLabel={(option: IKeyValuePair): string => option.value}
        getOptionSelected={(
          option: IKeyValuePair,
          val: IKeyValuePair,
        ): boolean => option.key === val.key}
        renderOption={(
          option: IKeyValuePair,
          state: AutocompleteRenderOptionState,
        ): JSX.Element => (
          <p
            style={{ margin: '0', padding: '0', width: '435px' }}
            onClick={(): void => {
              setFlagSentRequestButton(true)
              setValue(state.inputValue)
            }}
          >
            {option.value}
          </p>
        )}
        groupBy={(option: IKeyValuePair): string => option.key}
        // ACT-962 ГТ: Убрать живой поиск для исключения множества запросов
        options={[]}
        loading={optionsListLoading}
        noOptionsText={'Ничего не найдено'}
        loadingText={'Загрузка...'}
        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
          <>
            <TextField
              {...params}
              variant='outlined'
              placeholder='Поиск...'
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>): void => {
                // ACT-962 ГТ: Убрать живой поиск для исключения множества запросов
                const currentValue = (e.target as HTMLInputElement).value
                if (e.key === 'Enter' && currentValue !== value) {
                  setValue(currentValue)
                  setFlagSentRequestButton(true)
                  onSearchButtonClick()
                }
              }}
              data-test-id='assignmentsPageSearchInput'
            />
            {inputValue && (
              <CloseIcon onClick={(): void => setFlagCleanButton(true)}>
                {closeIcon}
              </CloseIcon>
            )}
            <IconButton
              data-test-id='assignmentsPageSearchInputBtn'
              onClick={() => {
                // ACT-962 ГТ: Убрать живой поиск для исключения множества запросов
                if (inputValue !== value) {
                  setValue(inputValue)
                  setFlagSentRequestButton(true)
                  onSearchButtonClick()
                }
              }}
              style={{ borderRadius: '0px 4px 4px 0px', height: '44px' }}
              disableRipple
              size='medium'
            >
              <SearchIcon />
            </IconButton>
          </>
        )}
      />
    </Search>
  )
}
