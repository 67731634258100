import styled, { css } from 'styled-components'
import { SvgIcon } from '@material-ui/core'

export const Root = styled.div`
  & .MuiAvatar-root {
    border-radius: 0;
  }
  & .MuiBadge-badge {
    height: 14px;
    width: 14px;
    min-width: 14px;
    background-color: #e2e4e9;
    &:hover {
      background-color: #f0f1f4;
    }
  }
  & .MuiBadge-root {
    border: 1px solid #e2e4e9;
  }
  & .MuiBadge-anchorOriginTopRightRectangle {
    font-size: 14px;
    top: 0;
    right: 0;
    transform: scale(1);
    border-radius: 0;
    padding: 2px;
  }
  & .MuiGrid-container {
    align-items: flex-start;
  }
`
export const Input = styled.input`
  display: none;
`
export const ListItemContainer = styled.div`
  display: flex;
  margin: 0 -5px;
`

const ListItemStyles = css`
  margin: 0 5px;
  align-self: center;
`

export const ListItem = styled.span`
  ${ListItemStyles}
`

export const ListItemSvg = styled(SvgIcon)`
  ${ListItemStyles}
`
