import { Tab, Toolbar } from '@material-ui/core'
import { useQuery } from '@gmini/utils'

import React from 'react'
import { useHistory } from 'react-router-dom'

import { Authorities } from '../../../../services/auth-service/auth-constants'

import { EPageType } from '../../../../types'
import { EButtonIcon, EButtonType } from '../../../../constants'
import { ButtonComponent } from '../../../Button/Button'

import { AppHeaderSearchAndFilter } from '../AppHeaderSearchAndFilter/AppHeaderSearchAndFilter'
import * as I from '../IAppHeader'
import { usePermissions } from '../../../../hooks/usePermissions'

import { SearchBackBlock, StyledAppBar, StyledTabs } from '../Style'

import { AppHeaderAgreement } from './AppHeaderAgreement'

type AppHeaderAgreementContainerProps = I.StateProps &
  I.DispatchProps &
  I.OwnProps & {
    showDrawerHandler: (isShow: boolean) => void
    flagBackPageHandler: () => void
    refreshCategoryId: () => void
    a11yProps: (index: number) => Record<string, unknown>
    flagBackPage: boolean
    openDrawer: boolean
    setFlagBackPage: React.Dispatch<React.SetStateAction<boolean>>
    agreementOpen: boolean
    search: boolean
  }

export const AppHeaderAgreementContainer = ({
  ...props
}: AppHeaderAgreementContainerProps) => {
  const {
    showDrawerHandler,
    flagBackPageHandler,
    refreshCategoryId,
    a11yProps,
    flagBackPage,
    openDrawer,
    agreement,
    editAgreement,
    removeAgreement,
    editLotAgreement,
    getAgreementSectionData,
    shouldResetFilter,
    filterFields,
    activeTabIndex,
    changeActiveTab,
    availableColumns,
    getFilterFields,
    getSearchOtherPageFilterItems,
    setFlagBackPage,
    agreementOpen,
    search,
  } = props

  const query = useQuery()
  const categoryId = query.get('categoryId') || ''

  const checkPermissions = usePermissions()
  const history = useHistory()

  return (
    <StyledAppBar position='static'>
      <Toolbar>
        {search ? (
          <>
            <SearchBackBlock>
              <ButtonComponent
                data-test-id='agreementsSearchBackBtn'
                text={''}
                type={EButtonType.DEFAULT}
                typeIcon={EButtonIcon.ARROW_BACK}
                noMargin
                hidden={false}
                onClick={(): void => {
                  history.push({
                    pathname: `/agreements/${activeTabIndex.agreements}`,
                    search: query.toString(),
                  })
                  setFlagBackPage(true)
                }}
              />
              <h5 data-test-id='agreementsSearchHeading'>
                Поиск по соглашениям
              </h5>
            </SearchBackBlock>
            {checkPermissions(
              Authorities.AGREEMENTS_SEARCH_AND_FILTER_ITEMS,
            ) && (
              <AppHeaderSearchAndFilter
                openDrawer={openDrawer}
                shouldResetFilter={shouldResetFilter}
                filterFields={filterFields}
                flagBackPage={flagBackPage}
                flagBackPageHandler={flagBackPageHandler}
                getFilterFields={getFilterFields}
                showDrawerHandler={showDrawerHandler}
                getSearchOtherPageFilterItems={getSearchOtherPageFilterItems}
                refreshCategoryId={refreshCategoryId}
                categoryId={categoryId}
                availableColumns={availableColumns}
                currentPage={EPageType.AGREEMENT}
              />
            )}
          </>
        ) : agreementOpen ? (
          <>
            <AppHeaderAgreement
              agreement={agreement}
              editAgreement={editAgreement}
              removeAgreement={removeAgreement}
              editLotAgreement={editLotAgreement}
              getAgreementSectionData={getAgreementSectionData}
            />
            {checkPermissions(
              Authorities.AGREEMENTS_SEARCH_AND_FILTER_ITEMS,
            ) && (
              <AppHeaderSearchAndFilter
                openDrawer={openDrawer}
                shouldResetFilter={shouldResetFilter}
                filterFields={filterFields}
                flagBackPage={flagBackPage}
                flagBackPageHandler={flagBackPageHandler}
                getFilterFields={getFilterFields}
                showDrawerHandler={showDrawerHandler}
                getSearchOtherPageFilterItems={getSearchOtherPageFilterItems}
                refreshCategoryId={refreshCategoryId}
                categoryId={categoryId}
                availableColumns={availableColumns}
                currentPage={EPageType.AGREEMENT}
              />
            )}
          </>
        ) : (
          <>
            <StyledTabs
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              value={activeTabIndex.agreements}
              onChange={(_, value: number) =>
                changeActiveTab(value, refreshCategoryId)
              }
              aria-label='view-tabs'
            >
              {checkPermissions(Authorities.AGREEMENTS_SHOW_TAB_ACTIVE) && (
                <Tab
                  data-test-id='activeAgreementsTab'
                  label='Активные'
                  {...a11yProps(0)}
                />
              )}
              {checkPermissions(Authorities.AGREEMENTS_SHOW_TAB_COMPLETED) && (
                <Tab
                  data-test-id='completedAgreementsTab'
                  wrapped
                  label='Завершенные'
                  {...a11yProps(1)}
                />
              )}
            </StyledTabs>
            {checkPermissions(
              Authorities.AGREEMENTS_SEARCH_AND_FILTER_ITEMS,
            ) && (
              <AppHeaderSearchAndFilter
                openDrawer={openDrawer}
                shouldResetFilter={shouldResetFilter}
                filterFields={filterFields}
                flagBackPage={flagBackPage}
                flagBackPageHandler={flagBackPageHandler}
                getFilterFields={getFilterFields}
                showDrawerHandler={showDrawerHandler}
                getSearchOtherPageFilterItems={getSearchOtherPageFilterItems}
                refreshCategoryId={refreshCategoryId}
                categoryId={categoryId}
                availableColumns={availableColumns}
                currentPage={EPageType.AGREEMENT}
              />
            )}
          </>
        )}
      </Toolbar>
    </StyledAppBar>
  )
}
