import React from 'react'

import { ModalTitle } from '../BaseModalAddContextPositions/ModalTitle/ModalTitle'

import { Contains, Content, StyledModal } from './styles'

export type TModalComponent = {
  open: boolean
  onClose: () => void
  title?: string
  dataTestIdPrefix?: string
}

export const ModalComponent: React.FC<TModalComponent> = ({
  open,
  onClose,
  children,
  title,
  dataTestIdPrefix,
}) => {
  const rootRef = React.useRef<HTMLDivElement>(null)

  return (
    <div ref={rootRef}>
      <StyledModal
        onClose={onClose}
        open={open}
        container={(): HTMLDivElement | null => rootRef.current}
      >
        <Contains>
          {Boolean(title) && (
            <ModalTitle
              dataTestIdPrefix={dataTestIdPrefix}
              id='modal-title'
              onClose={onClose}
            >
              {title}
            </ModalTitle>
          )}
          <Content>{children}</Content>
        </Contains>
      </StyledModal>
    </div>
  )
}
