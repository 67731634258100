/* eslint-disable require-unicode-regexp */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  List,
  ListItemText,
  Collapse,
  Divider,
  IconButton,
} from '@material-ui/core'
import { ExpandLess, ArrowForwardIos } from '@material-ui/icons'

import * as _ from 'lodash'

import { ITreeListItem } from '../../types'
import { ETreeListItemType, collapseIcon } from '../../constants'

import { LocalStorageHelper } from '../../utils'

import * as I from './ICatalogSearch'
import {
  Catalog,
  DividedHeight,
  IconContainer,
  ListTitle,
  StyledList,
  StyledListItem,
} from './Style'

export const CatalogSearch: React.FC<I.OwnProps> = ({
  categoriesData,
  catalogStateArgeements,
  categoryId,
  onSelectNode,
  setCatalogStateArgeements,
}): React.ReactElement => {
  const [open, setOpen] = useState<{ [key: string]: boolean }>({})
  const [selectedNode, setSelectedNode] = useState<string>('')
  const location = useLocation()
  const { agreementId } = useParams<{ agreementId?: string }>()

  const handleTreeListItemClick = (
    nodeId: string,
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    if (event.isPropagationStopped()) {
      return
    }

    event.stopPropagation()
    const newState = _.cloneDeep(open)
    const value = newState[nodeId] || false

    newState[nodeId] = !value
    setOpen(newState)
  }

  const renderTreeListItem = (
    node: ITreeListItem,
    level = 0,
    postfix: string,
  ): JSX.Element => (
    <div key={node.id}>
      <StyledListItem
        data-test-id={`catalogSearchTreeItem_${postfix}`}
        button
        onClick={(event: React.MouseEvent<HTMLElement>): void => {
          if (node.type === ETreeListItemType.FOLDER) {
            handleTreeListItemClick(node.id, event)
            if (
              node.content &&
              node.content.some(data => data.type === ETreeListItemType.LIST) &&
              location.pathname.indexOf('assignments/') !== -1
            ) {
              onSelectNode(
                typeof node.id === 'number'
                  ? node.id
                  : node.id.replace(/[r,l,t]/g, ''),
              )
            }
          } else {
            const page = location.pathname.replace('/', '')
            if (page.includes('positions')) {
              LocalStorageHelper.set<string>(`/positions_categoryId`, node.id)
            }
            if (page.includes('suppliers')) {
              LocalStorageHelper.set<string>(`/suppliers_categoryId`, node.id)
            }
            if (page.includes('assignments')) {
              LocalStorageHelper.set<string>(`/assignments_categoryId`, node.id)
            }
            if (page.includes('agreements')) {
              LocalStorageHelper.set<string>(`/agreements_categoryId`, node.id)
            }
            if (agreementId) {
              LocalStorageHelper.set<string>(
                `/positions_agreements_categoryId`,
                node.id,
              )
            }
            handleTreeListItemClick(node.id, event)

            setSelectedNode(node.id)
            if (!location.pathname.includes('assignments/')) {
              onSelectNode(
                typeof node.id === 'number'
                  ? node.id
                  : node.id.replace(/[r,l,t]/g, ''),
              )
            } else if (node.parentId) {
              onSelectNode(
                typeof node.parentId === 'number'
                  ? node.parentId
                  : node.parentId.replace(/[r,l,t]/g, ''),
              )
            }
          }
        }}
        selected={node.id === selectedNode}
        disableGutters={false}
        style={{ paddingLeft: `${level === 0 ? 5 : level * 30}px` }}
      >
        {node.type === ETreeListItemType.LIST ? null : open[node.id] ? (
          <ExpandLess
            style={{
              fontSize: '20px',
              color: '#5162d0',
            }}
          />
        ) : (
          <ArrowForwardIos
            style={{
              fontSize: '12px',
              marginRight: '5px',
              color: '#5162d0',
            }}
          />
        )}
        <ListItemText primary={node.name} />
        <span>{node.count}</span>
      </StyledListItem>
      {(node.type === ETreeListItemType.FOLDER ||
        node.type === ETreeListItemType.GROUP) && (
        <Collapse in={open[node.id]} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {node.content.map((data: ITreeListItem, idx) =>
              renderTreeListItem(data, level + 1, `${postfix}_${idx}`),
            )}
          </List>
        </Collapse>
      )}
    </div>
  )

  if (catalogStateArgeements || location.pathname.includes('search')) {
    return (
      <div>
        <StyledList
          component='nav'
          aria-labelledby='nested-list-subheader'
          subheader={
            <ListTitle
              data-test-id='catalogSearchHeading'
              component='div'
              id='nested-list-subheader'
            >
              {location.pathname.includes('positions') || agreementId
                ? 'Позиции'
                : location.pathname.includes('assignments')
                ? 'Тендерные задания'
                : location.pathname.includes('suppliers')
                ? 'Поставщики'
                : 'Соглашения'}
              <IconContainer>
                {categoryId && setCatalogStateArgeements && (
                  <>
                    <Divider orientation='vertical' flexItem />
                    <IconButton
                      data-test-id='catalogSearchCollapseBtn'
                      onClick={() => setCatalogStateArgeements(false)}
                    >
                      {collapseIcon}
                    </IconButton>
                  </>
                )}
              </IconContainer>
            </ListTitle>
          }
        >
          <DividedHeight />
          <Catalog>
            {categoriesData.map((data, idx) =>
              renderTreeListItem(data, 0, `${idx}`),
            )}
          </Catalog>
        </StyledList>
      </div>
    )
  }
  return <div></div>
}
