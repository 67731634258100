import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Contains = styled.div`
  max-width: 1000px;
  position: relative;
  width: 100%;
  background-color: #fff;
  &:focus {
    outline: none;
  }
`
export const Content = styled.div`
  padding: 25px;
  padding-top: 0;

  .MuiListSubheader-root {
    line-height: 30px;
  }

  .MuiListItem-root {
    padding-top: 3px;
    padding-bottom: 3px;
    .MuiCheckbox-root {
      padding: 0;
    }
    .MuiListItemText-root {
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }
`
