import { FetchService } from '@gmini/utils'
import { removeAuthHeader, setAuthHeader } from '@gmini/common/lib/auth/auth'
import { tokenData$ } from '@gmini/common/lib/keycloakAuth'
import { refreshTokenFunc } from '@gmini/common/lib/keycloakAuth/auth/authData'
import * as smApi from '@gmini/sm-api-sdk'

import { omniManagementServerUrl } from '../config'
export const omniNotificationsAuthenticateTimeout = 5000

export const notificationService = smApi.OmniNotificationService.create({
  authenticateTimeout: omniNotificationsAuthenticateTimeout,
})

export const omniManagement = new FetchService({
  baseUrl: omniManagementServerUrl || '',
  refreshTokenFunc,
})

export const omniNotificationsApi = new FetchService({
  baseUrl: omniManagementServerUrl || '',
  refreshTokenFunc,
})

tokenData$.watch(tokenData => {
  if (tokenData?.accessToken) {
    setAuthHeader(
      [omniManagement, omniNotificationsApi],
      tokenData?.accessToken,
    )
  } else {
    removeAuthHeader([omniManagement, omniNotificationsApi])
  }
})

smApi.omniConnect(omniManagement)
smApi.omniNotificationsConnect(omniNotificationsApi)
