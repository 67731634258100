import { Dialog } from '@material-ui/core'
import styled from 'styled-components'

export const Root = styled(Dialog)`
  && {
    & .MuiDialog-paperWidthSm {
      max-width: calc(100vw - 200px);
      height: calc(100vh - 200px);
      & .MuiToolbar-root {
        padding: 0;
        margin: 0 0 20px 0;
      }
    }
  }
`
export const LoaderBackground = styled.div`
  width: calc(100% - 100px);
  height: calc(100% - 200px);
  position: absolute;
  background: #f0f8ff;
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  & .MuiCircularProgress-root {
    margin: 0 200px 0 0;
  }
`
