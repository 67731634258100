import React, { useState } from 'react'
import { useFormik } from 'formik'
import { FormControlLabel, PopoverOrigin, TextField } from '@material-ui/core'

import * as yup from 'yup'

import { EButtonType } from '../../constants'
import { ButtonComponent } from '../Button/Button'
import { PositionCatalogMini } from '../PositionCatalogMini/PositionCatalogMini'
import { ListEditorItem } from '../ListEditor/ListEditorItem/ListEditorItem'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'

import { IAssignmentLot, TTreeList } from '../../types'

import {
  ButtonContainer,
  CatalogSpec,
  Err,
  Form,
  Label,
  ListEditor,
  Root,
} from './styles'

const REQUIRED_FIELD = 'Обязательное поле'

export const validationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD),
  specialization: yup.array().min(1, REQUIRED_FIELD),
})

const validationSchemaNameOnly = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD),
})

type TProps = {
  lot?: IAssignmentLot
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave: (data: any) => void
  specialization: TTreeList
}

export const LotModal: React.FC<TProps> = ({
  lot,
  onClose,
  onSave,
  specialization,
}) => {
  const formik = useFormik({
    initialValues: {
      name: lot?.name || '',
      description: lot?.description || '',
      specialization: lot?.specialization || [],
    },
    validationSchema: lot?.hasAgreement
      ? validationSchemaNameOnly
      : validationSchema,
    onSubmit: values => onSave(values),
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)

  const openPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closePopup = () => setAnchorEl(null)

  const popupAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  }

  const popupTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
  }

  const handleSelectNode = (node: any) => {
    setAnchorEl(null)
    formik.setFieldValue('specialization', [
      ...formik.values.specialization,
      node,
    ])
  }

  return (
    <Root onClose={onClose} open={true} disableBackdropClick={true}>
      <Form onSubmit={formik.handleSubmit}>
        <ModalTitle
          dataTestIdPrefix='lotModal'
          id='lot-modal'
          onClose={onClose}
        >
          {lot ? 'Изменить лот' : 'Добавить лот'}
        </ModalTitle>
        <ModalContent>
          <div>
            <FormControlLabel
              key='name'
              className='fullWidth'
              control={
                <TextField
                  id='name'
                  name='name'
                  value={formik.values.name}
                  type='text'
                  multiline={false}
                  maxRows={4}
                  fullWidth
                  variant='filled'
                  onChange={value =>
                    formik.setFieldValue('name', value.target.value)
                  }
                  inputProps={{
                    'data-test-id': 'lotModalNameInput',
                  }}
                />
              }
              label='Название'
              labelPlacement='top'
            />
            {formik.touched.name && formik.errors.name && (
              <Err>{formik.errors.name}</Err>
            )}
          </div>
          <div>
            <FormControlLabel
              disabled={lot?.hasAgreement}
              key='description'
              className='fullWidth'
              control={
                <TextField
                  id='description'
                  name='description'
                  value={formik.values.description}
                  type='text'
                  rows={4}
                  multiline
                  fullWidth
                  variant='filled'
                  onChange={value =>
                    formik.setFieldValue('description', value.target.value)
                  }
                  inputProps={{
                    'data-test-id': 'lotModalDescriptionInput',
                  }}
                />
              }
              label='Описание'
              labelPlacement='top'
            />
            {formik.touched.description && formik.errors.description && (
              <Err>{formik.errors.description}</Err>
            )}
          </div>
          <div>
            <Label>Специализация</Label>
            <ButtonComponent
              data-test-id='lotModalAddSpecialisationBtn'
              text='Добавить'
              type={EButtonType.DEFAULT}
              onClick={openPopup}
              disabled={lot?.hasAgreement}
            />
            <CatalogSpec
              anchorEl={anchorEl}
              id='catalog-popover'
              anchorOrigin={popupAnchorOrigin}
              transformOrigin={popupTransformOrigin}
              open={Boolean(anchorEl)}
              onClose={closePopup}
            >
              <PositionCatalogMini
                anchorEl={anchorEl}
                catalog={specialization}
                onSelectFullNode={handleSelectNode}
                isFullNodeNeed
                dataTestIdPrefix='lotModalSpecialisation'
              />
            </CatalogSpec>
            <ListEditor dense={true}>
              {formik.values.specialization.map((node: any, idx) => (
                <ListEditorItem
                  dataTestIdPrefix={`lotModalSpecialisationSelectedItem_${idx}`}
                  key={node.id}
                  value={node.name}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onDelete={() => {}}
                />
              ))}
            </ListEditor>
            {formik.touched.specialization && formik.errors.specialization && (
              <Err>{formik.errors.specialization}</Err>
            )}
          </div>
        </ModalContent>
        <ModalActions>
          <ButtonContainer>
            <ButtonComponent
              data-test-id='lotModalCancelBtn'
              htmlType='reset'
              text='Отменить'
              type={EButtonType.DEFAULT}
              onClick={onClose}
            />
            <ButtonComponent
              data-test-id='lotModalSubmitBtn'
              text='Сохранить'
              type={EButtonType.PRIMARY}
              htmlType='submit'
            />
          </ButtonContainer>
        </ModalActions>
      </Form>
    </Root>
  )
}
