import React from 'react'
import {
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { OwnProps } from './IListEditorItem'
import { Root } from './Style'

export const ListEditorItem: React.FC<OwnProps> = ({
  value,
  onDelete,
  dataTestIdPrefix,
  ...other
}) => (
  <Root data-test-id={dataTestIdPrefix} {...other}>
    <ListItemText primary={value} />
    <ListItemSecondaryAction>
      <IconButton
        data-test-id={`${dataTestIdPrefix}DeleteBtn`}
        edge='end'
        aria-label='delete'
        onClick={onDelete}
      >
        <DeleteIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </Root>
)
