import React from 'react'
import { useLocation } from 'react-router-dom'

import { DFormKeysNames, DSideBarItemContent } from '../../../../constants'

import { SidebarIconWrapper } from '../SidebarIconWrapper/SidebarIconWrapperPL'

import * as I from './ILink'
import { LinkText, StyledLink } from './Style'

const LinkPL: React.FC<I.OwnProps> = ({
  formKey,
  resetFilters,
  currentTab,
  ...other
}): React.ReactElement => {
  const location = useLocation()

  const checkIfRouteSelected = (): boolean =>
    location.pathname.indexOf(formKey) !== -1

  return (
    <StyledLink
      active={checkIfRouteSelected()}
      replace
      to={`/${formKey}${
        typeof currentTab === 'number' ? `/${currentTab}` : ''
      }`}
      onClick={resetFilters}
      {...other}
    >
      <SidebarIconWrapper path={DSideBarItemContent[formKey]} />
      <LinkText>{DFormKeysNames[formKey]}</LinkText>
    </StyledLink>
  )
}

export default LinkPL
