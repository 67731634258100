import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'

export const Root = styled.div`
  display: flex;
  font-family: 'Proxima Nova Regular';
  margin: 0 -15px;
  height: 100%;
`

type FlexItemProps = {
  tabPanel?: boolean
}

export const FlexItem = styled.div`
  margin: 0 15px;
  ${({ tabPanel }: FlexItemProps) =>
    tabPanel
      ? css`
          flex: 1 1 auto;
          width: 100px;
        `
      : ''}
`

export const TenderName = styled(Typography)`
  && {
    font-size: 24px;
    line-height: 29px;
    margin: 10px 0;
    color: #1b2b4b;
    display: flex;
    flex-direction: row;
    align-items: center;
    & .MuiButtonBase-root {
      transform: rotate(180deg);
      margin: 0 0 0 15px;
    }
  }
` as typeof Typography

export const TenderInfo = styled.div`
  margin: 23px 0 0 0;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px;
  width: 100%;
`
export const InfoItem = styled.div`
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`
export const Title = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: #616284;
`
export const Content = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #1b2b4b;
`
