import React, { useMemo } from 'react'

import { Content, Header, Title } from './styles'

export type TitleComponentProps = {
  categoryName?: string
  path?: string
  className?: string
}

export const TitleComponent: React.FC<TitleComponentProps> = ({
  categoryName,
  path = '',
}) => {
  const { pathLastPiece, categoryPath } = useMemo(
    () => ({
      pathLastPiece: categoryName,
      categoryPath: path?.replaceAll('/', ' / '),
    }),
    [path, categoryName],
  )

  return (
    <Title>
      <Header
        variant='subtitle1'
        component='div'
        data-test-id='categoryHeading'
      >
        {pathLastPiece}
      </Header>
      <Content variant='subtitle2' component='div'>
        {categoryPath}
      </Content>
    </Title>
  )
}
