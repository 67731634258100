/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { FormControlLabel, TextField } from '@material-ui/core'

import { IKeyValuePair } from '../../types'
import { EButtonType } from '../../constants'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'
import { ButtonComponent } from '../Button/Button'

import * as I from './IAddKeyValuePairModal'
import { ButtonContainer, Root } from './Style'

export const AddKeyValuePairModal: React.FC<I.OwnProps> = ({
  open,
  model,
  title,
  label,
  saveBtnText,
  cancelBtnText,
  onClose,
  onSave,
  dataTestIdPrefix,
}): React.ReactElement => {
  const [state, setState] = useState<IKeyValuePair>(model)
  const [errors, setErrors] = useState<string>('')

  const handleInputValueChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const error = validateForm(event.target.value)

    setErrors(error)
    setState({ ...state, value: event.target.value })
  }

  const validateForm = (value: string): string => {
    let error = ''

    if (!value || !value.trim()) {
      error = 'Поле обязательно'
    }

    return error
  }

  const saveBtnHandler = (): void => {
    const error = validateForm(state.value)

    if (error.length) {
      setErrors(error)
      return
    }

    onSave({ ...state })
  }

  const onDialogClose = (): void => {
    setErrors('')
    onClose()
  }

  return (
    <div>
      {open && (
        <Root
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
        >
          <ModalTitle
            id='customized-dialog-title'
            onClose={onDialogClose}
            dataTestIdPrefix={dataTestIdPrefix}
          >
            {title}
          </ModalTitle>
          <ModalContent dividers>
            <FormControlLabel
              className='fullWidth'
              control={
                <TextField
                  type='text'
                  required={true}
                  fullWidth
                  variant='filled'
                  error={errors.length > 0}
                  helperText={errors}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleInputValueChanged(e)
                  }
                  defaultValue={model.value}
                  inputProps={{
                    maxLength: 64,
                    'data-test-id': dataTestIdPrefix
                      ? `${dataTestIdPrefix}`
                      : '',
                  }}
                />
              }
              label={label}
              labelPlacement='top'
            />
          </ModalContent>
          <ModalActions>
            <ButtonContainer>
              <ButtonComponent
                data-test-id={
                  dataTestIdPrefix ? `${dataTestIdPrefix}CancelBtn` : ''
                }
                text={cancelBtnText}
                type={EButtonType.DEFAULT}
                onClick={onDialogClose}
              />
              <ButtonComponent
                data-test-id={
                  dataTestIdPrefix ? `${dataTestIdPrefix}SubmitBtn` : ''
                }
                text={saveBtnText}
                type={EButtonType.PRIMARY}
                onClick={saveBtnHandler}
              />
            </ButtonContainer>
          </ModalActions>
        </Root>
      )}
    </div>
  )
}
