import styled from 'styled-components'
import { Dialog } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const Root = styled(Dialog)`
  && {
    margin: 0;
    padding: 8px;
    & .MuiDialog-paperWidthSm {
      max-width: 645px;
      width: 100%;
    }
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  & button {
    margin: 0 5px;
  }
`
export const DrawerAutocomplete = styled(Autocomplete)`
  && {
    & .MuiAutocomplete-root {
      transition: 0.3s width ease-in-out;
      height: 40px;
      color: inherit;
    }
    &.MuiAutocomplete-root {
      display: flex;
      align-items: center;
    }
    & .MuiInputBase-input {
      cursor: default;
    }
    & .MuiOutlinedInput-notchedOutline {
      border: none;
      outline: none;
    }
    & .MuiAutocomplete-listbox {
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888 !important;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
    }
    & .MuiAutocomplete-popupIndicator {
      display: none;
    }
  }
` as typeof Autocomplete
