import { ArrowDown as ArrowDownInit } from '@gmini/ui-kit'
import { Popper } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const StyledPopper = styled(Popper)`
  & .MuiTooltip-tooltip {
    background-color: #f5f5f9;
    color: rgba(0, 0, 0, 0.87);
    max-width: 220;
    max-height: 700;
    overflow: hidden;
    font-size: 12px;
    border: 1px solid #dadde9;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const ItemCss = css`
  display: flex;
  max-width: 250px;
  flex-direction: column;
  font-size: 14px;
  margin-right: 50px;
`

export const Item = styled.div`
  ${ItemCss}
`

type ProjectsItemProps = {
  expanded: boolean
}

export const ProjectsItem = styled.div`
  ${ItemCss}
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: unset;
  margin-right: 0;
  max-height: ${({ expanded }: ProjectsItemProps) =>
    expanded ? 'unset' : '80px'};
`

export const ProjectsItemWrapper = styled.div`
  position: relative;
  overflow-x: clip;
`

export const ItemTitle = styled.div`
  font-weight: bold;
`
const ItemContentCss = css`
  line-height: 1;
  max-height: 40px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
export const ItemContent = styled.div`
  ${ItemContentCss}
`
export const ProjectsItemContent = styled.div`
  ${ItemContentCss}
  display: block;
  white-space: nowrap;
`
export const Name = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353b60;
`

export const ProjectsAndObjects = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353b60;
  white-space: nowrap;
`
export const ShowAllButton = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  bottom: -26px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4c5ecf;

  &:hover {
    cursor: pointer;
  }
`
type ArrowDownProps = {
  expanded: boolean
}

export const ArrowDown = styled(ArrowDownInit)`
  transition: all ease-in 0.3s;
  ${({ expanded }: ArrowDownProps) =>
    expanded
      ? css`
          transform: rotateX(180deg);
        `
      : ''}
`
