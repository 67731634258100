import styled, { css } from 'styled-components'
import { Paper, Table, TableCell, Popper } from '@material-ui/core'
export const Root = styled(Paper)`
  && {
    width: 100%;
    box-shadow: none;
  }
  & .MuiTableCell-head {
    padding: 8px;
    font-size: 12px;
    font-weight: bold;
    color: #616284;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #e2e4e9;
    background-color: #f9f9fb;
  }
`
export const StyledTable = styled(Table)`
  table-layout: fixed;
`

export const StickyCell = css`
  position: sticky;
  left: 0;
  z-index: 3;
  box-shadow: 2px 0 3px 0 rgb(0, 0, 0, 0.05);
`

type CellProps = {
  sticky: boolean
}

export const Cell = styled(TableCell)`
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #fff;
  &:nth-child(even) {
    background: #fafafa;
  }
  & a {
    color: #000;
    text-decoration: none;
  }
  ${({ sticky }: CellProps) => (sticky ? StickyCell : '')}
`
export const ActionsCell = styled(TableCell)`
  padding: 0 8px;
  background: #fff;
  width: 100px;
`
export const CheckBoxCell = styled(TableCell)`
  width: 100px;
  padding: 0 8px;
`
export const EmptyData = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 12px 0;
`
export const ResizeHandle = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 12px;
  height: 100%;
  cursor: col-resize;
`
export const ActionHeadCell = styled.col`
  width: 100px;
`
export const StyledTooltip = styled(Popper)`
  & .MuiTooltip-tooltip {
    background-color: #f5f5f9;
    color: rgba(0, 0, 0, 0.87);
    max-width: 220px;
    max-height: 700px;
    overflow: hidden;
    font-size: 12px;
    border: 1px solid #dadde9;
  }
`
