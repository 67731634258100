import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useQuery } from '@gmini/utils'
import { useHistory } from 'react-router-dom'

// import { FormControl, Select, MenuItem } from '@material-ui/core'
import {
  GridColumnResizeParams,
  GridStateColDef,
  GridColumnOrderChangeParams,
} from '@material-ui/x-grid'

import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'

import { XGridTable, CollapsedCatalog } from '@ifellow/ui-library'

import { debounce } from 'lodash'

import { ListItem } from '@ifellow/ui-library/dist/components/list/recursive-list'

import { Setting } from '@gmini/ui-kit'

import { ApiResponse } from '../../../services/base-api-service/base-api-service'
import { Authorities } from '../../../services/auth-service/auth-constants'
import { AgreementService } from '../../../services/agreements-service'

import { columnsMapper, rowsMapper } from '../../../utils/xgrid'

import { ImprovedTableHeadDisplayColumns } from '../../../components/ImprovedTable/ImprovedTableHead/ImprovedTableHeadDisplayColumns/ImprovedTableHeadDisplayColumns'
import { ButtonComponent } from '../../../components/Button/Button'
import { modalService } from '../../../components/Modal/ModalService'
import { DialogModal } from '../../../components/DialogModal/DialogModal'
import { CatalogSkeleton } from '../../../components/Skeleton/CatalogSkeleton'
import { TableSkeleton } from '../../../components/Skeleton/TableSkeleton'
import { PositionExportModal } from '../../../components/PositionExportModal/PositionExportModal'
import { PositionCatalog } from '../../../components/PositionCatalog/PositionCatalog'

import {
  IColumn,
  IAPIResult,
  TTreeModifyDict,
  IKeyValuePair,
  TRowData,
  TFilterItems,
  TAgreementRequest,
  AgreementTab,
  IXGridColumn,
  ColumnSettings,
} from '../../../types'

import { EButtonType, EButtonIcon, EColumnType } from '../../../constants'

import {
  getUserCategoryColumnSettingsFromStorage,
  setColumnsToStorage,
} from '../../../common/storageHelper'

import { catalogMapper, LocalStorageHelper } from '../../../utils'
import { usePermissions } from '../../../hooks/usePermissions'
import { TitleComponent } from '../../../components/TitleComponent/TitleComponent'

import { PropsFromRedux, connector } from './agreement-container'

import {
  CatalogWrapper,
  Root,
  TabPanelWrapper,
  TabPanel,
  PathComponent,
  Path,
  Actions,
  Selected,
  SettingsButton,
} from './agreement.styled'
import * as I from './agreement-types'

const Agreement: React.FC<PropsFromRedux & I.OwnProps> = ({
  tabIndex,
  agreements,
  categories,
  getAgreements,
  deleteAgreements,
  addProperty,
  editProperty,
  removeProperty,
  exportAgreementsByIds,
  addSection,
  editSection,
  removeSection,
  getCategories,
  getCategoriesFinished,
  saveCategoriesChanges,
}): React.ReactElement | null => {
  const checkPermissions = usePermissions()
  const history = useHistory()
  const query = useQuery()
  const initCategoryId =
    query.get('categoryId') ||
    localStorage.getItem(`agreements_${tabIndex}_lastVisitedCategory`)
  const [categoryId, setCategoryId] = useState(initCategoryId || '')
  const localStorageTableId = `agreement-list-${categoryId}`
  const [initColumns, setInitColumns] = useState<(IColumn & ColumnSettings)[]>(
    getUserCategoryColumnSettingsFromStorage(
      localStorageTableId,
      agreements.columns,
    ),
  )
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false)
  const [isCatalogExpanded, setIsCatalogExpanded] = useState(true)
  const [isCategoriesPending, setIsCategoriesPending] = useState(false)
  const [isAgreementsPending, setIsAgreementsPending] = useState(false)
  const [hideTable, setHideTable] = useState(false)

  const [tableParams, setTableParams] = useState<TableParams>({
    size: 10,
    page: 0,
    sort: {
      field: '',
      sort: 'asc',
    },
    filters: [],
  })
  const [xgridRows, xgridColumns] = useMemo(
    () => [
      rowsMapper(agreements.data),
      columnsMapper({
        columns: initColumns,
        tableType: 'agreements',
      }),
    ],
    [agreements.data, initColumns],
  )
  const [checkedRows, setCheckedRows] = useState<Array<string | number>>([])

  const handleAgreementsPending = (data: boolean) =>
    setIsAgreementsPending(data)

  const getCurrentRequestParams = useCallback(() => {
    const params: TAgreementRequest = {
      pageData: {
        page: tableParams.page + 1,
        perPage: tableParams.size,
        categoryId,
        ascending: tableParams.sort?.sort === 'asc',
        fieldKey: tableParams.sort?.field,
      },
      userContract: {
        endDate: '',
        object: '',
        project: '',
        startDate: '',
      },
      agreementConditions: tableParams.filters as TFilterItems[],
      fetchCategories: true,
      table: 'agreements',
      isActive: true,
    }
    return params
  }, [categoryId, tableParams])

  const getInitRequestParams = useCallback(
    (localStorageId?: string) => {
      let storageSortColumn: ColumnSettings | undefined
      const storageData = localStorage.getItem(localStorageId || '')
      if (typeof storageData === 'string') {
        try {
          storageSortColumn = (JSON.parse(
            storageData,
          ) as ColumnSettings[]).find(s => s.sortOrder !== null)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err)
        }
      }
      const params: TAgreementRequest = {
        pageData: {
          page: 1,
          perPage: tableParams.size,
          categoryId,
          fieldKey: storageSortColumn
            ? storageSortColumn.key.toString()
            : undefined,
          ascending:
            storageSortColumn?.sortOrder !== null
              ? storageSortColumn?.sortOrder
              : undefined,
        },
        userContract: {
          endDate: '',
          object: '',
          project: '',
          startDate: '',
        },
        fetchCategories: true,
        table: 'agreements',
        isActive: true,
      }
      return params
    },
    [categoryId, tableParams.size],
  )

  const fetchCategories = useCallback(() => {
    const setLoading = (data: boolean) => setIsCategoriesPending(data)
    const params = {
      endDate: '',
      object: '',
      project: '',
      startDate: '',
    }

    tabIndex === AgreementTab.Active
      ? getCategories({ setLoading, params })
      : getCategoriesFinished({ setLoading, params })
  }, [getCategories, getCategoriesFinished, tabIndex])

  useEffect(() => {
    setCategoryId('')
  }, [tabIndex])

  useEffect(() => {
    fetchCategories()
    if (initCategoryId) {
      handleSelectNode(initCategoryId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategories])

  const handleSelectNode = useCallback(
    (nodeId?: string): void => {
      setCategoryId(nodeId ?? '')
      const storageKey = `agreement-list-${nodeId}`
      const initParams = getInitRequestParams(storageKey)

      setTableParams({
        size: initParams.pageData.perPage,
        page: 0,
        filters: [],
        sort: {
          field: initParams.pageData.fieldKey?.toString() || '',
          sort: initParams.pageData.ascending ? 'asc' : 'desc',
        },
      })
      query.set('categoryId', nodeId || '')
      history.replace({ search: query.toString() })
      getAgreements({
        params: {
          ...initParams,
          pageData: {
            ...initParams.pageData,
            categoryId: nodeId ?? '',
          },
        },
        tab: tabIndex,
        setLoading: val => {
          handleAgreementsPending(val)
          setHideTable(val)
        },
      })
        .unwrap()
        .then(t => {
          setInitColumns(
            getUserCategoryColumnSettingsFromStorage(storageKey, t.columns),
          )
        })
    },
    [
      getAgreements,
      getInitRequestParams,
      setCategoryId,
      tabIndex,
      query,
      history,
    ],
  )

  const handleRemoveChecked = useCallback(() => {
    modalService.openModal(
      <DialogModal
        open={true}
        handleDiscardChanges={() => modalService.closeModal()}
        handleChanges={() => {
          modalService.closeModal()
          deleteAgreements({
            ids: checkedRows as string[],
            callback: () => {
              getAgreements({
                params: getCurrentRequestParams(),
                tab: tabIndex,
                setLoading: handleAgreementsPending,
              })
              fetchCategories()
              setCheckedRows([])
            },
          })
        }}
        modalTitle='Удаление соглашений'
        modalContent={`Вы действительно хотите удалить соглашения (${checkedRows.length})?`}
        modalButtonRightText={'Удалить соглашения'}
        modalButtonRightType={EButtonType.WARNING}
        modalButtonLeftText='Отменить'
        modalButtonLeftType={EButtonType.DEFAULT}
      />,
    )
  }, [
    checkedRows,
    deleteAgreements,
    fetchCategories,
    getAgreements,
    getCurrentRequestParams,
    tabIndex,
  ])

  const handleExport = useCallback(
    (isExportAllPosition: boolean) => {
      const exportPosition = isExportAllPosition
        ? xgridRows.map(position => position.id)
        : checkedRows

      exportAgreementsByIds(exportPosition as string[])
      modalService.closeModal()
    },
    [checkedRows, exportAgreementsByIds, xgridRows],
  )

  const handleExportSelectedPositionClick = useCallback(() => {
    modalService.openModal(
      <PositionExportModal
        open={true}
        onClose={() => modalService.closeModal()}
        onSubmitExportPosition={handleExport}
        exportBySelectedPositionOption
      />,
    )
  }, [handleExport])

  const handleAddProperty = useCallback(
    (model: IColumn): void => {
      addProperty({
        model,
        categoryId,
      }).then(() =>
        getAgreements({
          params: getCurrentRequestParams(),
          tab: tabIndex,
          setLoading: handleAgreementsPending,
        })
          .unwrap()
          .then(({ columns }) =>
            setInitColumns(
              getUserCategoryColumnSettingsFromStorage(
                localStorageTableId,
                columns,
              ),
            ),
          ),
      )
    },
    [
      addProperty,
      categoryId,
      getAgreements,
      getCurrentRequestParams,
      tabIndex,
      localStorageTableId,
    ],
  )

  const handleEditProperty = useCallback(
    (model: IColumn): void => {
      model.type = model.type.split('_')[0] as EColumnType
      model.key = model.id
      editProperty({
        model,
      }).then(() =>
        getAgreements({
          params: getCurrentRequestParams(),
          tab: tabIndex,
          setLoading: handleAgreementsPending,
        })
          .unwrap()
          .then(({ columns }) => {
            const cols = getUserCategoryColumnSettingsFromStorage(
              localStorageTableId,
              columns,
            )
            const newCols = cols.map(c => ({
              ...c,
              title: columns.find(col => col.key === c.key)?.title || c.title,
            }))
            setColumnsToStorage(localStorageTableId, newCols)
            setInitColumns(newCols)
          }),
      )
    },
    [
      editProperty,
      getAgreements,
      getCurrentRequestParams,
      tabIndex,
      localStorageTableId,
    ],
  )

  const handleRemoveProperty = useCallback(
    (key: number): void => {
      removeProperty({
        key: String(key),
      }).then(() => {
        const columnSettings: Array<
          IXGridColumn
        > | null = LocalStorageHelper.get(localStorageTableId)

        if (columnSettings) {
          const withoutColumn = columnSettings.filter(
            el => el.field !== String(key),
          )
          LocalStorageHelper.set(localStorageTableId, withoutColumn)
        }

        getAgreements({
          params: getCurrentRequestParams(),
          tab: tabIndex,
          setLoading: handleAgreementsPending,
        })
          .unwrap()
          .then(({ columns }) =>
            setInitColumns(
              getUserCategoryColumnSettingsFromStorage(
                localStorageTableId,
                columns,
              ),
            ),
          )
      })
    },
    [
      getAgreements,
      getCurrentRequestParams,
      localStorageTableId,
      removeProperty,
      tabIndex,
    ],
  )

  const handleAddSection = useCallback(
    (name: string, callback: (model: IKeyValuePair) => void): void => {
      addSection({ categoryId, name, callback })
    },
    [addSection, categoryId],
  )

  const handleEditSection = useCallback(
    (value: IKeyValuePair, callback: (model: IKeyValuePair) => void): void => {
      editSection({ value, callback })
    },
    [editSection],
  )

  const handleRemoveSection = useCallback(
    (key: string, callback: () => void): void => {
      removeSection({ key, callback })
    },
    [removeSection],
  )

  const handleGetSectionsDataSource = useCallback(
    (query: string): Promise<ApiResponse<IAPIResult<TRowData>>> =>
      AgreementService.searchSections(categoryId, query),
    [categoryId],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleParametersChange = useCallback(
    debounce((params: TableParams) => {
      const curParams = getCurrentRequestParams()
      getAgreements({
        params: {
          ...curParams,
          pageData: {
            ...curParams.pageData,
            page: params.page + 1,
            perPage: params.size,
            categoryId,
            ascending: params.sort?.sort === 'asc',
            fieldKey: params.sort?.field,
          },
          agreementConditions: params.filters as TFilterItems[],
        },
        tab: tabIndex,
        setLoading: handleAgreementsPending,
      })
        .unwrap()
        .then(t => {
          const cols = getUserCategoryColumnSettingsFromStorage(
            localStorageTableId,
            t.columns,
          )
          const newCols = cols.map(c =>
            c.key?.toString() === params.sort?.field
              ? { ...c, sortOrder: params.sort?.sort === 'asc' ? true : false }
              : { ...c, sortOrder: null },
          )
          setColumnsToStorage(localStorageTableId, newCols)
          setInitColumns(newCols)
        })
      setTableParams(params)
    }, 500),
    [categoryId, tabIndex, localStorageTableId],
  )

  const areAgreementsAvailable = useCallback(
    () =>
      checkPermissions(Authorities.AGREEMENTS_SHOW_TAB_ACTIVE) ||
      checkPermissions(Authorities.AGREEMENTS_SHOW_TAB_COMPLETED),
    [checkPermissions],
  )

  const handleColumnOrderChange = useCallback(
    ({ targetIndex, oldIndex }: GridColumnOrderChangeParams) => {
      const movingCol = initColumns[oldIndex - 1]
      const newCols = initColumns.filter((_, i) => i !== oldIndex - 1)
      newCols.splice(targetIndex - 1, 0, movingCol)
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId],
  )

  const handleColumnResize = useCallback(
    ({
      colDef,
      width,
    }: GridColumnResizeParams & {
      colDef: GridStateColDef & { id?: string }
    }) => {
      const newCols = initColumns.map(col =>
        col.key.toString?.() === colDef.id ? { ...col, width } : col,
      )
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId],
  )

  const handleColumnVisibilityChanged = useCallback(
    (id: number) => {
      const newCols = initColumns.map(c =>
        c.key === id ? { ...c, hide: !c.hide } : c,
      )
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId],
  )

  const handleSaveCategories = useCallback(
    (modifyDict: TTreeModifyDict) => {
      saveCategoriesChanges({ modifyDict, callback: fetchCategories })
    },
    [fetchCategories, saveCategoriesChanges],
  )

  if (!areAgreementsAvailable()) {
    return null
  }

  return (
    <Root>
      {isCatalogExpanded &&
        (isCategoriesPending ? (
          <CatalogSkeleton />
        ) : (
          <CatalogWrapper>
            <PositionCatalog
              editable={
                tabIndex === AgreementTab.Active &&
                checkPermissions(Authorities.AGREEMENTS_EDIT_CATALOG)
              }
              onSave={handleSaveCategories}
              onSelectNode={node => handleSelectNode(node?.id)}
              categories={categories}
              setIsCatalogExpanded={setIsCatalogExpanded}
              categoryId={categoryId}
            />
          </CatalogWrapper>
        ))}
      {isAgreementsPending && <TableSkeleton />}
      <TabPanelWrapper hidden={isAgreementsPending}>
        <TabPanel index={tabIndex}>
          {categoryId && (
            <>
              <PathComponent>
                <Path>
                  <TitleComponent
                    categoryName={agreements.categoryName}
                    path={agreements.categoryPath}
                  />
                </Path>
              </PathComponent>
              <Actions>
                {!isCatalogExpanded && (
                  <CollapsedCatalog
                    title='Каталог'
                    items={catalogMapper(categories) as ListItem[]}
                    onItemClick={id => handleSelectNode(id as string)}
                    onSwitch={() => setIsCatalogExpanded(true)}
                    needSwitchBtn
                    dataTestIdPrefix='agreements_'
                  />
                )}
                {checkedRows.length > 0 && (
                  <>
                    <Selected variant='subtitle1' component='div'>
                      {checkedRows.length} Выбрано
                    </Selected>
                    <ButtonComponent
                      data-test-id='agreementsTableExportBtn'
                      text='Экспорт'
                      type={EButtonType.DEFAULT}
                      typeIcon={EButtonIcon.DOWNLOAD}
                      hidden={!checkPermissions(Authorities.AGREEMENTS_EXPORT)}
                      onClick={handleExportSelectedPositionClick}
                    />
                    <ButtonComponent
                      data-test-id='agreementsTableDeleteBtn'
                      text='Удалить'
                      type={EButtonType.WARNING}
                      typeIcon={EButtonIcon.DELETE}
                      hidden={
                        !(
                          checkPermissions(
                            Authorities.AGREEMENTS_REMOVE_ITEM,
                          ) && tabIndex === AgreementTab.Active
                        )
                      }
                      onClick={handleRemoveChecked}
                    />
                  </>
                )}
                <SettingsButton
                  data-test-id='agreementsTableColumnSettingsBtn'
                  size='regular'
                  onClick={() => setIsSettingsMenuOpen(true)}
                  aria-label='Settings'
                >
                  <Setting />
                </SettingsButton>
              </Actions>
              {initColumns.length && !hideTable ? (
                <XGridTable
                  loading={isAgreementsPending}
                  renderCustomColumn={(props: {
                    onVisibilityChange: (key: string) => void
                    columns: Array<IXGridColumn>
                  }) => {
                    const { onVisibilityChange, columns } = props
                    const mappedColumns = agreements.columns.filter(
                      column => !column.hidden,
                    )
                    const visibleColumns = mappedColumns.filter(column =>
                      columns.some(
                        col => col.field === String(column.key) && !col.hide,
                      ),
                    )
                    return (
                      <ImprovedTableHeadDisplayColumns
                        columns={mappedColumns}
                        onColumnVisibilityChanged={(_, { key }) => {
                          onVisibilityChange(String(key))
                          handleColumnVisibilityChanged(key)
                        }}
                        visibleColumns={visibleColumns}
                        showEditPropertyButtons={
                          tabIndex === AgreementTab.Active
                        }
                        showSections
                        addPropertyHandler={handleAddProperty}
                        editPropertyHandler={handleEditProperty}
                        removePropertyHandler={handleRemoveProperty}
                        onAddSection={handleAddSection}
                        onEditSection={handleEditSection}
                        onRemoveSection={handleRemoveSection}
                        getSectionsDataSource={handleGetSectionsDataSource}
                        isRowChecked={true}
                        offMenu={true}
                      />
                    )
                  }}
                  selectionModel={checkedRows}
                  rowsPerPageOptions={[10, 20, 50]}
                  pageSize={agreements.pagination.rowsPerPage}
                  pageStart={agreements.pagination.page - 1}
                  mode='server'
                  onColumnResize={debounce(handleColumnResize, 500)}
                  onColumnOrderChange={handleColumnOrderChange}
                  initSortModel={
                    tableParams.sort?.field ? [tableParams.sort] : []
                  }
                  rows={xgridRows}
                  columns={xgridColumns}
                  rowCount={agreements.pagination.total}
                  disableFiltering={false}
                  onRowDoubleClick={({ id }) =>
                    history.push({
                      pathname: `/agreements/${tabIndex}/${id}`,
                      search: query.toString(),
                    })
                  }
                  onParametersChange={handleParametersChange}
                  onSelectionModelChange={selectionModel => {
                    setCheckedRows(selectionModel)
                  }}
                  isSettingsMenuOpen={isSettingsMenuOpen}
                  setIsSettingsMenuOpen={setIsSettingsMenuOpen}
                  dataTestIdPrefix='agreementsTable'
                />
              ) : null}
            </>
          )}
        </TabPanel>
      </TabPanelWrapper>
    </Root>
  )
}

export default connector(Agreement)
