import styled from 'styled-components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
export const StyledDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 0;
    border: 0;
  }
  & .MuiPaper-elevation24 {
    box-shadow: -20px 0px 40px rgba(72, 89, 122, 0.12);
  }
  & .MuiPaper-root {
    width: 645px;
  }
  & .MuiDivider-root {
    margin: 0 0 0 34px;
    border: 0.5px solid #e2e4e9;
  }
`
export const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 10px 10px 34px;
    padding: 0;
  }
  & .MuiSvgIcon-root {
    font-size: 20px;
  }
  & .MuiTypography-root {
    font-size: 22px;
  }
`
export const StyledDialogContent = styled(DialogContent)`
  && {
    margin: 25px 0 35px 0;
    padding: 0 0 0 34px;
  }
  & .MuiTypography-root {
    color: #1b2b4b;
    font-size: 18px;
  }
`
export const StyledDialogActions = styled(DialogActions)`
  && {
    box-shadow: -20px 0px 40px rgba(72, 89, 122, 0.12);
    margin: 8px 0 0 0;
    padding-right: 20px;
  }
`
