/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { cloneDeep, debounce } from 'lodash'

import { CollapsedCatalog, XGridTable } from '@ifellow/ui-library'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'

import {
  GridCellParams,
  GridColumnResizeParams,
  GridColumnOrderChangeParams,
  GridStateColDef,
} from '@material-ui/x-grid'

import { Setting } from '@gmini/ui-kit'

import {
  allSpecialisationsItem,
  EButtonIcon,
  EButtonType,
  EColumnType,
  EEditableModalType,
  specialisationItem,
} from '../../constants'
import {
  ColumnSettings,
  IAPIResult,
  IColumn,
  IKeyValuePair,
  IRow,
  ITreeListItem,
  IXGridColumn,
  SuppliersPageColumnSettings,
  SupplierTab,
  TEditModalInitialState,
  TFilterItems,
  TRowData,
  TTreeList,
  TTreeModifyDict,
} from '../../types'

import { ButtonComponent } from '../../components/Button/Button'
import { CatalogSkeleton } from '../../components/Skeleton/CatalogSkeleton'
import { EditableModal } from '../../components/EditableModal/EditableModal'
import { TableSkeleton } from '../../components/Skeleton/TableSkeleton'
import { TableUpload } from '../../components/ImprovedTable/TableUpload/TableUpload'
import { ImprovedTableHeadDisplayColumns } from '../../components/ImprovedTable/ImprovedTableHead/ImprovedTableHeadDisplayColumns/ImprovedTableHeadDisplayColumns'

import { Authorities } from '../../services/auth-service/auth-constants'
import { SearchService } from '../../services/search-service/search-service'
import { ApiResponse } from '../../services/base-api-service/base-api-service'
import { SuppliersService } from '../../services/supplier-service'

import { columnsMapper, rowsMapper } from '../../utils/xgrid'

import {
  flatten,
  isConclusionStatus,
  mapConclusionToRus,
  LocalStorageHelper,
  catalogMapper,
  searchOptionsMapper,
} from '../../utils'

import {
  getUserCategoryColumnSettingsFromStorageSuppliersPage,
  setColumnsToStorageSuppliersPage,
} from '../../common/storageHelper'

import { TitleComponent } from '../../components/TitleComponent/TitleComponent'
import { TabPanel } from '../../components/TabPanel/TabPanel'
import { PositionCatalog } from '../../components/PositionCatalog/PositionCatalog'

import { SupplierDetailModal } from '../../components/SupplierDetailModal/SupplierDetailModal'
import { usePermissions } from '../../hooks/usePermissions'

import { useAppSelector } from '../../store'

import { PropsFromRedux, connector } from './supplier-container'
import { editSupplierModalValue, getModalValue } from './utils'
import { EDIT_MODAL_TITLE, SUPPLIERS_TABS } from './constants'

import {
  Actions,
  Content,
  FlexItem,
  FloatedActions,
  Head,
  Search,
  SettingsButton,
  StyledTabs,
  TabWrapper,
} from './style'

const localStorageCache = 'suppliers-table'

const Suppliers: React.FC<PropsFromRedux> = ({
  suppliers,
  categoryId,
  dataIsLoading,
  saveCategoriesChanges,
  setCategory,
  checkSupplierExistence,
  addSupplier,
  editSupplier,
  getSuppliersNew,
  importSuppliers,
  setIsLoading,
  deleteSuppliers,
  exportSuppliers,
  exportAllSuppliers,
  removeSection,
  editSection,
  addSection,
  removeProperty,
  editProperty,
  addProperty,
  resetSuppliers,
  selectedIds,
  setSelectedSuppliers,
  exportTemplate,
  getCategories,
  getCategoriesActive,
  suppliersFilters,
}) => {
  const checkPermissions = usePermissions()
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false)
  const [isCatalogExpanded, setIsCatalogExpanded] = useState(true)
  const [categories, setCategories] = useState<TTreeList>([])
  const [isCategoriesPending, setIsCategoriesPending] = useState(false)
  const [isSuppliersPending, setIsSuppliersPending] = useState(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false)
  const [tabIndex, setTabIndex] = useState<SupplierTab>(SupplierTab.All)
  const [currentRow, setCurrentRow] = useState<Partial<IRow>>()
  const [modalType, setModalType] = useState<EEditableModalType>(
    EEditableModalType.INSERT,
  )
  const localStorageTableId = `suppliers-list-${categoryId || '0'}`
  const [initColumns, setInitColumns] = useState<(IColumn & ColumnSettings)[]>(
    getUserCategoryColumnSettingsFromStorageSuppliersPage(
      localStorageTableId,
      suppliers.columns,
    ).columns,
  )
  const [supplierDetails, setSupplierDetails] = useState({})
  const [tableParams, setTableParams] = useState<TableParams>({
    size: 20,
    page: 0,
    sort: {
      field: '',
      sort: 'asc',
    },
    filters: [],
  })
  const [hideTable, setHideTable] = useState(false)
  const keyword = useRef<string | undefined>(undefined)
  const specialisations = useAppSelector(state => state.specialisations.list)
  const [rows, columns] = useMemo(
    () => [
      rowsMapper(suppliers.data),
      columnsMapper({
        columns: initColumns,
      }).map(column => ({
        ...column,
        renderCell: (params: GridCellParams) => (
          <div>
            {isConclusionStatus(params.value as string)
              ? mapConclusionToRus(params.value as string)
              : params.value}
          </div>
        ),
      })),
    ],
    [suppliers.data, initColumns],
  )

  const modalInitialState = useRef<TEditModalInitialState>({
    settings: [],
    row: {
      rowId: '',
      data: [],
    },
  })

  // FETCHERS
  const fetchSupplierDetail = useCallback(async id => {
    const [err, details] = await SuppliersService.getSupplier(id)
    if (err) {
      //TODO handle error
      toastr.error('Ошибка', '')
    }
    setIsLoading(true)
    setSupplierDetails(details?.data?.data || {})
    setIsLoading(false)
  }, [])

  const getOptions = useCallback(async value => {
    const [err, result] = await SearchService.getOtherSearch({
      keyword: value,
      table: 'providers',
    })

    if (err) {
      //TODO handle error
      toastr.error('Ошибка', '')
    }

    return searchOptionsMapper(result.data.data?.searchResultList || [])
  }, [])

  const handleSuppliersPending = (data: boolean) => setIsSuppliersPending(data)

  const getSuppliersByDefault = useCallback(() => {
    const storageKey = `suppliers-list-${categoryId || '0'}`

    let storageSortColumn: ColumnSettings | undefined
    let parsedData: SuppliersPageColumnSettings | undefined
    const storageData = localStorage.getItem(storageKey || '')
    if (typeof storageData === 'string') {
      try {
        parsedData = JSON.parse(storageData) as SuppliersPageColumnSettings
        storageSortColumn = parsedData.columns.find(s => s.sortOrder !== null)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }

    setTableParams({
      size: suppliers.pagination.rowsPerPage,
      page: 0,
      filters: [],
      sort: {
        field: storageSortColumn?.key.toString() || '',
        sort: storageSortColumn?.sortOrder === false ? 'desc' : 'asc',
      },
    })
    getSuppliersNew({
      params: {
        keyword: keyword.current,
        pageData: {
          page: 1,
          ascending:
            typeof storageSortColumn?.sortOrder === 'boolean'
              ? storageSortColumn?.sortOrder
              : true,
          fieldKey: storageSortColumn?.key,
          perPage: suppliers.pagination.rowsPerPage,
          categoryId,
        },
        active: tabIndex === SupplierTab.Active,
      },
      suppliersFilters: {
        agreedSuppliers: parsedData?.agreedSuppliers === false ? false : true,
        notAgreedSuppliers:
          parsedData?.notAgreedSuppliers === false ? false : true,
        expiredSuppliers: parsedData?.expiredSuppliers === false ? false : true,
      },
      setLoading: val => {
        handleSuppliersPending(val)
        setHideTable(val)
      },
    })
      .unwrap()
      .then(s =>
        setInitColumns(
          getUserCategoryColumnSettingsFromStorageSuppliersPage(
            storageKey,
            s.columns.filter(column => !column.hidden),
          ).columns,
        ),
      )
  }, [
    categoryId,
    getSuppliersNew,
    suppliers.pagination.page,
    suppliers.pagination.rowsPerPage,
    tabIndex,
  ])

  const getSuppliersByStatus = useCallback(
    ({
      isAgreed,
      isNotAgreed,
      isExpired,
    }: {
      isAgreed: boolean
      isNotAgreed: boolean
      isExpired: boolean
    }) => {
      const storageKey = `suppliers-list-${categoryId || '0'}`
      getSuppliersNew({
        params: {
          keyword: keyword.current,
          pageData: {
            page: 1,
            perPage: suppliers.pagination.rowsPerPage,
            categoryId,
          },
          active: tabIndex === SupplierTab.Active,
        },
        suppliersFilters: {
          agreedSuppliers: isAgreed,
          notAgreedSuppliers: isNotAgreed,
          expiredSuppliers: isExpired,
        },
        setLoading: handleSuppliersPending,
      })
        .unwrap()
        .then(t => {
          const cols = getUserCategoryColumnSettingsFromStorageSuppliersPage(
            localStorageTableId,
            t.columns.filter(column => !column.hidden),
          ).columns
          setInitColumns(cols)
          setColumnsToStorageSuppliersPage(storageKey, {
            columns: cols,
            agreedSuppliers: isAgreed,
            notAgreedSuppliers: isNotAgreed,
            expiredSuppliers: isExpired,
          })
        })
    },
    [
      categoryId,
      getSuppliersNew,
      suppliers.pagination.page,
      suppliers.pagination.rowsPerPage,
      tabIndex,
      initColumns,
    ],
  )

  const onParametersChangeHandler = useCallback(
    debounce((params: TableParams) => {
      setTableParams(params)
      new Promise(resolve => {
        getSuppliersNew({
          params: {
            keyword: keyword.current,
            pageData: {
              page: params.page + 1,
              perPage: params.size,
              categoryId,
              ascending: params.sort?.sort === 'asc',
              fieldKey: params.sort?.field,
            },
            providerConditions: params.filters as TFilterItems[],
            active: tabIndex === SupplierTab.Active,
          },
          suppliersFilters: {
            agreedSuppliers: suppliersFilters.agreedSuppliers,
            notAgreedSuppliers: suppliersFilters.notAgreedSuppliers,
            expiredSuppliers: suppliersFilters.expiredSuppliers,
          },
          setLoading: handleSuppliersPending,
          callback: resolve as any,
        })
          .unwrap()
          .then(t => {
            const cols = getUserCategoryColumnSettingsFromStorageSuppliersPage(
              localStorageTableId,
              t.columns.filter(column => !column.hidden),
            ).columns
            const newCols = cols.map(c =>
              c.key?.toString() === params.sort?.field
                ? {
                    ...c,
                    sortOrder: params.sort?.sort === 'asc' ? true : false,
                  }
                : { ...c, sortOrder: null },
            )

            setColumnsToStorageSuppliersPage(localStorageTableId, {
              columns: newCols,
              agreedSuppliers: suppliersFilters.agreedSuppliers,
              notAgreedSuppliers: suppliersFilters.notAgreedSuppliers,
              expiredSuppliers: suppliersFilters.expiredSuppliers,
            })
            setInitColumns(newCols)
          })
      }).then(() => {
        setSelectedSuppliers(selectedIds)
      })
    }, 150),
    [tabIndex, categoryId, selectedIds, suppliersFilters],
  )

  const getSectionsDataSource = React.useCallback(
    (query: string): Promise<ApiResponse<IAPIResult<TRowData>>> =>
      SuppliersService.searchSections(query),
    [],
  )

  const searchSuppliers = useCallback(
    (value?: string | null) => {
      keyword.current = value || undefined
      getSuppliersByDefault()
    },
    [getSuppliersByDefault],
  )

  const handleGetCategories = useCallback((): void => {
    const callback = (data: TTreeList): void => {
      const specs = [
        cloneDeep(allSpecialisationsItem),
        cloneDeep(specialisationItem),
      ]

      specs[1].content = cloneDeep(data)
      specs[1].content = specs[1].content.map(item => ({
        ...item,
        parentId: specialisationItem.id,
      }))

      setCategories(specs)
    }
    const setLoading = (data: boolean) => setIsCategoriesPending(data)
    switch (tabIndex) {
      case SupplierTab.All:
        getCategories({ setLoading, callback })
        break
      case SupplierTab.Active:
        getCategoriesActive({ setLoading, callback })
        break
    }
  }, [tabIndex])

  useEffect(handleGetCategories, [tabIndex])

  // HANDLERS
  const onRemoveHandler = useCallback(
    (ids: Array<string>) => {
      setIsLoading(true)

      deleteSuppliers({
        ids,
        callback: () => {
          setIsLoading(false)
          setSelectedSuppliers([])
          getSuppliersByDefault()
          handleGetCategories()
        },
      })
    },
    [deleteSuppliers, handleGetCategories],
  )

  const onExportHandler = useCallback(
    (ids: Array<string>) => {
      exportSuppliers(ids)
    },
    [exportSuppliers],
  )

  const onExportAllHandler = () => {
    exportAllSuppliers({
      keyword: keyword.current,
      pageData: {
        categoryId,
      },
      providerConditions: (tableParams?.filters as TFilterItems[]) || [],
      table: 'providers',
      active: tabIndex === SupplierTab.Active,
      isAgreed: suppliersFilters.agreedSuppliers,
      isNotAgreed: suppliersFilters.notAgreedSuppliers,
      isExpired: suppliersFilters.expiredSuppliers,
    })
  }

  const onAddHandler = useCallback(() => {
    const data = suppliers.columns.map(column => {
      let value = ''

      if (column.type === EColumnType.BOOLEAN) {
        value = 'false'
      } else if (column.type === EColumnType.LIST && Number(categoryId) !== 0) {
        const flatSpecialisations = flatten(specialisations || [])
        const category = flatSpecialisations.find(
          item => item.id === categoryId,
        )
        value = category?.name ?? ''
      }

      return {
        key: column.key,
        value,
      }
    })

    modalInitialState.current = {
      row: {
        rowId: '',
        data,
      },
      settings: suppliers.columns,
    }

    setModalType(EEditableModalType.INSERT)
    setOpenEditModal(!openEditModal)
  }, [categoryId, openEditModal, suppliers.columns, specialisations])

  const onImportHandler = useCallback(
    (file: File) => {
      importSuppliers({
        file,
        callback: () => {
          getSuppliersByDefault()
          handleGetCategories()
        },
      })
    },
    [getSuppliersByDefault, importSuppliers, handleGetCategories],
  )

  const onEditHandler = useCallback(() => {
    const row = (currentRow || {}) as IRow
    const data = suppliers.columns.map(d => ({
      key: d.key,
      value: editSupplierModalValue(d.type, row.data, d.key),
    }))

    modalInitialState.current = {
      row: {
        ...row,
        data,
      },
      settings: suppliers.columns,
    }

    setModalType(EEditableModalType.EDIT)
    setOpenEditModal(!openEditModal)
  }, [openEditModal, suppliers.columns, currentRow])

  const saveSupplierHandler = useCallback((): void => {
    const item: TRowData = [...modalInitialState.current.row.data]

    new Promise(resolve => {
      const inn = getModalValue(modalInitialState.current, 'ИНН') || ''
      const kpp = getModalValue(modalInitialState.current, 'КПП') || ''
      const excludeId = modalInitialState.current.row.rowId
      checkSupplierExistence({
        inn: String(inn),
        kpp: String(kpp),
        excludeId: String(excludeId),
        callback: resolve,
      })
    }).then(isExisted => {
      if (isExisted) {
        toastr.error('', 'Поставщик с такими ИНН и КПП уже существует', {
          progressBar: false,
          showCloseButton: false,
        })
      } else {
        if (modalType === EEditableModalType.INSERT) {
          addSupplier({
            supplier: item,
            callback: () => {
              setOpenEditModal(false)
              handleGetCategories()
            },
          })
        } else {
          editSupplier({
            supplier: {
              rowId: modalInitialState.current.row.rowId,
              data: item,
            },
            callback: () => {
              setOpenEditModal(false)
              handleGetCategories()
            },
          })
        }
      }
    })
  }, [
    addSupplier,
    checkSupplierExistence,
    editSupplier,
    modalType,
    handleGetCategories,
  ])

  const removeSectionHandler = React.useCallback(
    (key: string, callback: () => void): void => {
      const cb = (): void => {
        callback()
      }
      removeSection({ key, callback: cb })
    },
    [removeSection],
  )

  const editSectionHandler = React.useCallback(
    (value: IKeyValuePair, callback: (model: IKeyValuePair) => void): void => {
      editSection({ value, callback })
    },
    [editSection],
  )

  const addSectionHandler = React.useCallback(
    (name: string, callback: (model: IKeyValuePair) => void): void => {
      addSection({ name, callback })
    },
    [addSection],
  )

  const removePropertyHandler = React.useCallback(
    (key: number, callback: () => void): void => {
      const cb = (): void => {
        callback()

        const columnSettings: Array<
          IXGridColumn
        > | null = LocalStorageHelper.get(localStorageCache)

        if (columnSettings) {
          const withoutColumn = columnSettings.filter(
            el => el.field !== String(key),
          )
          LocalStorageHelper.set(localStorageCache, withoutColumn)
        }

        getSuppliersByDefault()
      }
      removeProperty({ key: String(key), callback: cb })
    },
    [removeProperty],
  )

  const editPropertyHandler = React.useCallback(
    (model: IColumn): void => {
      model.type = model.type.split('_')[0] as EColumnType
      model.key = model.id
      editProperty({ model, callback: () => getSuppliersByDefault() })
    },
    [editProperty],
  )

  const addPropertyHandler = React.useCallback(
    (model: IColumn): void => {
      addProperty({
        model,
        categoryId,
        callback: () => getSuppliersByDefault(),
      })
    },
    [addProperty, categoryId],
  )

  useEffect(() => {
    getSuppliersByDefault()
  }, [categoryId, tabIndex])

  const handleColumnResize = useCallback(
    ({
      colDef,
      width,
    }: GridColumnResizeParams & {
      colDef: GridStateColDef & { id?: string }
    }) => {
      const newCols = initColumns.map(col =>
        col.key.toString?.() === colDef.id ? { ...col, width } : col,
      )
      setColumnsToStorageSuppliersPage(localStorageTableId, {
        columns: newCols,
        agreedSuppliers: suppliersFilters.agreedSuppliers,
        notAgreedSuppliers: suppliersFilters.notAgreedSuppliers,
        expiredSuppliers: suppliersFilters.expiredSuppliers,
      })
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId, suppliersFilters],
  )

  const handleColumnOrderChange = useCallback(
    ({ targetIndex, oldIndex }: GridColumnOrderChangeParams) => {
      const mappedColumns = initColumns.filter(column => !column.hidden)
      const movingCol = mappedColumns[oldIndex - 1]
      const newCols = mappedColumns.filter((_, i) => i !== oldIndex - 1)
      newCols.splice(targetIndex - 1, 0, movingCol)
      setColumnsToStorageSuppliersPage(localStorageTableId, {
        columns: newCols,
        agreedSuppliers: suppliersFilters.agreedSuppliers,
        notAgreedSuppliers: suppliersFilters.notAgreedSuppliers,
        expiredSuppliers: suppliersFilters.expiredSuppliers,
      })
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId, suppliersFilters],
  )

  const handleColumnVisibilityChanged = useCallback(
    (id: number) => {
      const newCols = initColumns.map(c =>
        c.key === id ? { ...c, hide: !c.hide } : c,
      )
      setColumnsToStorageSuppliersPage(localStorageTableId, {
        columns: newCols,
        agreedSuppliers: suppliersFilters.agreedSuppliers,
        notAgreedSuppliers: suppliersFilters.notAgreedSuppliers,
        expiredSuppliers: suppliersFilters.expiredSuppliers,
      })
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId, suppliersFilters],
  )

  const handleSelectNode = useCallback((nodeId?: string): void => {
    setCategory(nodeId ?? '')
  }, [])

  const saveSupplierCatalogChanges = (modifyDict: TTreeModifyDict) => {
    saveCategoriesChanges({ dict: modifyDict, callback: handleGetCategories })
  }

  return (
    <>
      <Head>
        <StyledTabs
          tabs={SUPPLIERS_TABS}
          onChange={tab => {
            setTabIndex(tab)
            resetSuppliers()
          }}
          dataTestIdPrefix='suppliersPageTab'
        />
        {checkPermissions(Authorities.SUPPLIERS_SEARCH_AND_FILTER_ITEMS) && (
          <Search
            getOptions={getOptions}
            onSelect={searchSuppliers}
            dataTestId='mainSearchInput'
          />
        )}
      </Head>
      <Content>
        {isCatalogExpanded &&
          (isCategoriesPending ? (
            <CatalogSkeleton />
          ) : (
            <FlexItem>
              <PositionCatalog
                editable={checkPermissions(Authorities.SUPPLIERS_EDIT_CATALOG)}
                disableRootNodeAdding
                disableNodeMovingConcat
                maxLevel={4}
                onSave={saveSupplierCatalogChanges}
                onSelectNode={(node?: ITreeListItem) =>
                  handleSelectNode(node?.id)
                }
                setIsCatalogExpanded={setIsCatalogExpanded}
                categories={categories}
                categoryId={categoryId}
              />
            </FlexItem>
          ))}
        {isSuppliersPending && <TableSkeleton />}
        <FlexItem tabPanel hidden={isSuppliersPending}>
          <TabPanel index={tabIndex}>
            <TabWrapper>
              <TitleComponent
                categoryName={suppliers.categoryName}
                path={suppliers.categoryPath}
              />
              <Actions>
                {!isCatalogExpanded && (
                  <CollapsedCatalog
                    title='Каталог'
                    items={catalogMapper(categories) as any}
                    onItemClick={id => setCategory(id as string)}
                    onSwitch={() => setIsCatalogExpanded(true)}
                    needSwitchBtn
                    dataTestIdPrefix='suppliers_'
                  />
                )}
                <ButtonComponent
                  data-test-id='suppliersTableAddSupplierBtn'
                  text='Добавить поставщика'
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.ADD}
                  hidden={!checkPermissions(Authorities.SUPPLIERS_CREATE_ITEM)}
                  onClick={onAddHandler}
                />
                <TableUpload
                  hidden={!checkPermissions(Authorities.SUPPLIERS_IMPORT)}
                  uploadHandler={onImportHandler}
                  dataTestIdPrefix='suppliersTable'
                />
                <ButtonComponent
                  data-test-id='suppliersTableExportTemplateBtn'
                  text='Экспорт шаблона'
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.DOWNLOAD}
                  hidden={
                    !checkPermissions(Authorities.SUPPLIERS_EXPORT) ||
                    !!selectedIds.length
                  }
                  onClick={() => exportTemplate()}
                />
                <ButtonComponent
                  data-test-id='suppliersTableExportAllBtn'
                  text={`Экспорт всех поставщиков (${suppliers.pagination.total})`}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.DOWNLOAD}
                  // РАЗОБРАТЬСЯ В РАМКАХ ПЕРМИШЕНОВ
                  hidden={
                    !checkPermissions(
                      Authorities.ITEMS_SHOW_AGREEMENT_PROVIDER_ON_SIDEBAR_VIEW,
                    )
                  }
                  onClick={() => onExportAllHandler()}
                />
                <FloatedActions>
                  {!!selectedIds.length && (
                    <>
                      <ButtonComponent
                        data-test-id='suppliersTableExportBtn'
                        text='Экспорт'
                        type={EButtonType.DEFAULT}
                        typeIcon={EButtonIcon.DOWNLOAD}
                        hidden={!checkPermissions(Authorities.SUPPLIERS_EXPORT)}
                        onClick={() => onExportHandler(selectedIds)}
                      />
                      <ButtonComponent
                        data-test-id='suppliersTableDeleteBtn'
                        text='Удалить'
                        type={EButtonType.WARNING}
                        typeIcon={EButtonIcon.DELETE}
                        hidden={
                          !checkPermissions(Authorities.SUPPLIERS_REMOVE_ITEM)
                        }
                        onClick={() => onRemoveHandler(selectedIds)}
                      />
                    </>
                  )}
                  <SettingsButton
                    data-test-id='suppliersTableSettingsBtn'
                    size='regular'
                    onClick={() => setIsSettingsMenuOpen(true)}
                    aria-label='Settings'
                  >
                    <Setting />
                  </SettingsButton>
                </FloatedActions>
              </Actions>
              {tabIndex === SupplierTab.All && (
                <div>
                  <FormControlLabel
                    label='Согласованные'
                    control={
                      <Checkbox
                        data-test-id='suppliersTableAgreedCheckbox'
                        color='primary'
                        checked={suppliersFilters.agreedSuppliers}
                        onChange={() =>
                          getSuppliersByStatus({
                            isAgreed: !suppliersFilters.agreedSuppliers,
                            isExpired: suppliersFilters.expiredSuppliers,
                            isNotAgreed: suppliersFilters.notAgreedSuppliers,
                          })
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label='Не согласованные'
                    control={
                      <Checkbox
                        data-test-id='suppliersTableNotAgreedCheckbox'
                        color='primary'
                        checked={suppliersFilters.notAgreedSuppliers}
                        onChange={() =>
                          getSuppliersByStatus({
                            isAgreed: suppliersFilters.agreedSuppliers,
                            isNotAgreed: !suppliersFilters.notAgreedSuppliers,
                            isExpired: suppliersFilters.expiredSuppliers,
                          })
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label='Срок проверки истек'
                    control={
                      <Checkbox
                        data-test-id='suppliersTableExpiredCheckbox'
                        color='primary'
                        checked={suppliersFilters.expiredSuppliers}
                        onChange={() =>
                          getSuppliersByStatus({
                            isAgreed: suppliersFilters.agreedSuppliers,
                            isNotAgreed: suppliersFilters.notAgreedSuppliers,
                            isExpired: !suppliersFilters.expiredSuppliers,
                          })
                        }
                      />
                    }
                  />
                </div>
              )}
              {initColumns.length && !hideTable ? (
                <XGridTable
                  loading={isSuppliersPending}
                  renderCustomColumn={(props: {
                    onVisibilityChange: (key: string) => void
                    columns: Array<IXGridColumn>
                  }) => {
                    const { onVisibilityChange, columns } = props
                    const mappedColumns = initColumns.filter(
                      column => !column.hidden,
                    )
                    const visibleColumns = mappedColumns.filter(column =>
                      columns.some(
                        col => col.field === String(column.key) && !col.hide,
                      ),
                    )
                    return (
                      <ImprovedTableHeadDisplayColumns
                        columns={mappedColumns}
                        onColumnVisibilityChanged={(_, { key, id }) => {
                          onVisibilityChange(String(key))
                          handleColumnVisibilityChanged(id)
                        }}
                        visibleColumns={visibleColumns}
                        showEditPropertyButtons={true}
                        showSections
                        addPropertyHandler={addPropertyHandler}
                        editPropertyHandler={editPropertyHandler}
                        removePropertyHandler={removePropertyHandler}
                        isRowChecked={true}
                        onAddSection={addSectionHandler}
                        onEditSection={editSectionHandler}
                        onRemoveSection={removeSectionHandler}
                        getSectionsDataSource={getSectionsDataSource}
                        offMenu={true}
                      />
                    )
                  }}
                  columns={columns}
                  rows={rows}
                  mode='server'
                  onColumnResize={debounce(handleColumnResize, 500)}
                  onColumnOrderChange={handleColumnOrderChange}
                  initSortModel={
                    tableParams.sort?.field ? [tableParams.sort] : []
                  }
                  pageSize={suppliers.pagination.rowsPerPage}
                  pageStart={suppliers.pagination.page - 1}
                  rowsPerPageOptions={[20, 50, 100]}
                  selectionModel={selectedIds}
                  onSelectionModelChange={selectionModel => {
                    setSelectedSuppliers(selectionModel)
                  }}
                  onParametersChange={onParametersChangeHandler}
                  rowCount={suppliers.pagination.total}
                  isSettingsMenuOpen={isSettingsMenuOpen}
                  setIsSettingsMenuOpen={setIsSettingsMenuOpen}
                  disableFiltering={false}
                  onRowDoubleClick={async ({ id }) => {
                    await fetchSupplierDetail(id)
                    setCurrentRow(
                      suppliers?.data?.find(({ rowId }) => id === rowId),
                    )
                    setOpenDetailsModal(true)
                  }}
                  dataTestIdPrefix='suppliersTable'
                />
              ) : null}
            </TabWrapper>
          </TabPanel>
        </FlexItem>
        <EditableModal
          open={openEditModal}
          initialState={modalInitialState}
          type={modalType}
          title={EDIT_MODAL_TITLE}
          dataIsLoading={dataIsLoading}
          specialisations={specialisations}
          onClose={(): void => setOpenEditModal(false)}
          onSave={saveSupplierHandler}
          path='suppliers'
        />
        <SupplierDetailModal
          columns={suppliers.columns}
          open={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          data={supplierDetails}
          onRemove={onRemoveHandler}
          onEdit={onEditHandler}
        />
      </Content>
    </>
  )
}

export default connector(Suppliers)
