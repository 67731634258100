import {
  Drawer,
  List,
  Menu,
  ListItem,
  ListSubheader,
  IconButton,
  Divider,
} from '@material-ui/core'
import styled from 'styled-components'

export const Root = styled(List)`
  && {
    width: 100%;
    margin: 22px 0 0 0;
    min-width: 270;
    background: #f9f9fb;
    min-height: 180px;
    border-radius: 5px;
    & .MuiListSubheader-gutters {
      padding-right: 0;
    }
  }
` as typeof List
export const ListTitle = styled(ListSubheader)`
  && {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    color: #616284;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    width: inherit;
    max-width: inherit;
  }
` as typeof ListSubheader
export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .MuiButtonBase-root {
    padding: 0;
    margin: 0 15px;
  }
  & .MuiSvgIcon-root {
    cursor: pointer;
    color: white;
    font-size: 15px;
  }
  & .MuiDivider-root {
    height: 44px;
  }
`
export const DividedHeight = styled(Divider)`
  && {
    height: 1.5px;
    margin: 0 0 20px 0;
  }
`
export const Catalog = styled.div`
  padding: 0 15px;
  margin-top: -10px;
  max-height: calc(100vh - 230px);
  overflow-y: auto;
  & .MuiTypography-root {
    word-break: break-word;
  }
  &::-webkit-scrollbar {
    width: 8px !important;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  & .Mui-selected {
    border-radius: 5px !important;
    background-color: #f2f0f8 !important;
  }
`
export const StyledListItem = styled(ListItem)`
  && {
    display: flex;
    height: auto;
    min-height: 45px;
    width: 270px;
    &:hover,
    &:active,
    &:focus {
      background-color: #f2f0f8;
      border-radius: 5px;
      height: auto;
      color: #4c5ecf;
    }
    & .MuiListItemText-primary {
      font-size: 14px;
      color: #05082e;
      max-width: 200px;
    }
  }
`
export const SettingsMenu = styled(Menu)`
  && {
    & li {
      font-family: 'Proxima Nova Regular';
    }
  }
`
export const MoveItemTree = styled(List)`
  && {
    margin: 0 0 0 10px;
    min-width: 270;
    border-radius: 5px;
    max-height: 625px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }
    & .MuiListItem-root {
      padding-right: 10px !important;
    }
  }
`
export const DrawerTitle = styled(List)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 24px;
    & h5 {
      font-size: 24px;
      color: #000;
      font-weight: normal;
      margin: 0;
      line-height: 20px;
      height: 20px;
    }
    & .MuiSvgIcon-root {
      cursor: pointer;
    }
  }
`
export const DrawerList = styled(Drawer)`
  && {
    & .MuiDrawer-paper {
      min-width: 405px;
    }

    & .MuiDrawer-paperAnchorLeft {
      margin-left: 120px;
    }
    & .MuiButton-label {
      font-weight: normal;
    }
    & .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0);
    }
    & hr {
      margin: 20px 24px;
    }
    & .MuiList-padding {
      max-width: 625px;
      padding: 0;
    }
  }
`
export const DrawerFooter = styled(List)`
  && {
    margin: 0 24px;
  }
`
export const MenuButton = styled(IconButton)`
  && {
    padding: 0;
    border-radius: 5px;
    width: 27px;
    height: 27px;
    &.active {
      background: #4c5ecf;
      color: #ffffff;
    }
  }
`
export const SecondPartFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -5px;
  & button {
    margin: 0 5px;
  }
  height: 59px;
  box-shadow: 0px -5px 30px 0px rgba(50, 50, 50, 0.05);
  min-width: 335px;
`
export const CloseButton = styled(IconButton)`
  && {
    & .MuiSvgIcon-root {
      cursor: pointer;
    }
  }
`
