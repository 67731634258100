import styled from 'styled-components'
import { Popper } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { ButtonComponent } from '../Button/Button'

export const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  & .MuiFormHelperText-root {
    color: red;
  }
` as typeof Autocomplete

export const AutocompletePaper = styled(Popper)`
  & .MuiAutocomplete-listbox {
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }
  }
`
export const EditBtn = styled(ButtonComponent)`
  height: 62px !important;
  width: 62px !important;
  display: inline-flex !important;
`
