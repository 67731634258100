import styled, { css } from 'styled-components'
import { XGridTable } from '@ifellow/ui-library'

import { HashCodeIcon, RevitLinkIcon } from '../../icons'

type XGridTableStyledProps = {
  priceColKey?: number
}

export const XGridTableStyled = styled(XGridTable)`
  ${({ priceColKey }: XGridTableStyledProps) =>
    priceColKey
      ? css`
          & .MuiDataGrid-cell[data-field='${priceColKey}'] {
            position: relative;
          }
        `
      : ''}
`

export const LoaderWrap = styled.div`
  color: '#4C5ECF';
  z-index: 300;
  height: 100%;
  width: 100%;
`

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`
export const ProjectTooltip = styled.div`
  max-width: 338px;
`

export const RevitLinkIconButton = styled(RevitLinkIcon)`
  &:hover {
    cursor: pointer;
    & path {
      fill: rgba(53, 59, 96, 1);
    }
  }
`

export const HashCodeIconButton = styled(HashCodeIcon)`
  &:hover {
    cursor: pointer;
    background: rgba(53, 59, 96, 0.1);
    border-radius: 4px;
    & path {
      fill: rgba(53, 59, 96, 1);
    }
  }
`
export const HashHeader = styled.div`
  display: flex;
`

type NameCellProps = {
  expanded: boolean
}

export const NameCell = styled.div`
  ${({ expanded }: NameCellProps) =>
    expanded
      ? css`
          white-space: normal;
          line-height: 21px;
        `
      : ''}
`

type HiddenNameHeightEstimationBlockProps = {
  width: number
}

export const HiddenNameHeightEstimationBlock = styled.div`
  white-space: normal;
  line-height: 21px;
  padding: 10px 16px;
  justify-content: flex-start;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #00003d;
  visibility: hidden;
  position: absolute;
  width: ${({ width }: HiddenNameHeightEstimationBlockProps) => `${width}px`};
`
