import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TextField } from '@material-ui/core'

import { debounceTime } from 'rxjs/operators'

import { Subject } from 'rxjs'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'

import _ from 'lodash'
import { toastr } from 'react-redux-toastr'

import { SearchService } from '../../services/search-service/search-service'

import {
  IKeyValuePair,
  TSearchRequest,
  TSearchDataOtherPage,
} from '../../types'
import { EAPIResponseStatus } from '../../constants'
import {
  Search,
  StyledAutocomplete,
} from '../AppHeaderSearchBarAssignment/Style'

import { OwnProps } from './IAppHeaderSearchBarOtherPage'

export const AppHeaderSearchBarOtherPage: FC<OwnProps> = ({
  searchFilterParams,
  categoryId,
  isArchived,
  searchInputValue,
  onSearchInputChange,
  onSearch,
  tableValue,
}) => {
  const history = useHistory()
  const location = useLocation()

  const [searchValues, setSearchValues] = useState<IKeyValuePair[]>([])
  const [optionsListOpen, setOptionsListOpen] = useState(false)
  const [onSearch$] = useState(() => new Subject<TSearchRequest>())
  const [optionsListLoading, setOptionsListLoading] = useState(false)

  useEffect(() => {
    const subscription = onSearch$
      .pipe(debounceTime(1000))
      .subscribe(async debounced => {
        setOptionsListLoading(true)
        if (debounced && debounced.keyword && debounced.keyword?.length >= 2) {
          const [err, result] = await SearchService.getOtherSearch(debounced)

          if (
            result.data.status === EAPIResponseStatus.SUCCESS &&
            result.data.data
          ) {
            const sv: TSearchDataOtherPage[] =
              result.data.data.searchResultList || []

            const arr: IKeyValuePair[] = []

            sv.forEach(d =>
              d.itemNames.forEach(data =>
                arr.push({ key: d.categoryPath, value: data }),
              ),
            )

            if (err) {
              //TODO handle error
              toastr.error('Ошибка', '')
            }
            setSearchValues(arr)
          }
          setOptionsListLoading(false)
        } else {
          setOptionsListLoading(false)
          setSearchValues([])
        }
      })

    return () => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [onSearch$])

  //  TODO изменить логику роута /search
  useEffect(() => {
    if (location.pathname.indexOf('search') !== -1) {
      history.push(
        `${location.pathname.replace('/search', '')}${location.search}`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSearchButtonClick = (value: string) => {
    setOptionsListOpen(false)

    onSearch(value)
  }

  return (
    <Search>
      <StyledAutocomplete
        inputValue={searchInputValue}
        open={optionsListOpen}
        filterOptions={option => option}
        onOpen={() => {
          setOptionsListOpen(true)
        }}
        onClose={() => {
          setOptionsListOpen(false)
        }}
        options={searchValues}
        onInputChange={(e, value) => {
          onSearchInputChange(value)

          setSearchValues([])
          const paramShort = {
            keyword: value,
            table: tableValue,
            fetchCategories: true,
            pageData: {
              page: 1,
              perPage: 10,
              categoryId,
            },
            deleted: Boolean(isArchived),
          }

          const generalParams: TSearchRequest = _.assign(
            searchFilterParams,
            paramShort,
          )

          onSearch$.next(generalParams)
        }}
        fullWidth
        freeSolo
        loading={optionsListLoading}
        noOptionsText={'Ничего не найдено'}
        loadingText={'Загрузка...'}
        onChange={(e, data) => {
          if (data) {
            const value = typeof data === 'string' ? data : data.value

            onSearchButtonClick(value)
          }
        }}
        renderInput={params => (
          <>
            <TextField
              {...params}
              variant='outlined'
              placeholder='Поиск...'
              onKeyDown={e => {
                if (e.key === 'Enter' && (e.target as HTMLInputElement).value) {
                  onSearchButtonClick((e.target as HTMLInputElement).value)
                  e.stopPropagation()
                }
              }}
              data-test-id='mainSearchInput'
            />
            <IconButton
              data-test-id='mainSearchInputBtn'
              onClick={() => onSearchButtonClick(searchInputValue)}
              style={{ borderRadius: '0px 4px 4px 0px', height: '44px' }}
              disableRipple
              size='medium'
            >
              <SearchIcon />
            </IconButton>
          </>
        )}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.value
        }
        groupBy={option => option.key}
      />
    </Search>
  )
}
