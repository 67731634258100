import React, { Key, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import {
  Attachments,
  TSetSendFileRequest,
  UploadDocumentParams,
} from '../../types'
import { Accordion } from '../Accordion/Accordion'
import { DumbTable } from '../DumbTable/DumbTable'
import { DumbModal } from '../DumbModal/DumbModal'
import { ButtonComponent as Button } from '../Button/Button'
import { DocumentsFieldLoader } from '../DocumentsFieldLoader/DocumentsFieldLoader'
import { EButtonType } from '../../constants'

import { documentsMapper } from './utils'
import { DocumentAddForm } from './DocumentsAddForm'
import { ADD_DOCUMENT_FORM_ID, COLUMNS } from './constants'
import { ActionButton, ContentContainer } from './Style'

export type AddDocumentData = {
  type?: string
  file?: File
  sendFile?: boolean
}

type DocumentsFieldProps = {
  services: {
    add: (params: UploadDocumentParams) => Promise<unknown>
    delete?: (id: Key) => Promise<unknown>
    setSendFile?: (params: TSetSendFileRequest) => Promise<unknown>
  }
  afterFetch?: () => void
  id: string
  documents: Attachments[]
  showCheckBox?: boolean
  checkBoxTitle?: string
}

export const DocumentsField: React.FC<DocumentsFieldProps> = ({
  id,
  documents,
  services,
  showCheckBox,
  checkBoxTitle,
  afterFetch,
}) => {
  const [docs, setDocs] = useState(documents)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const closeEditModal = () => setIsEditModalOpen(false)
  const openEditModal = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsEditModalOpen(true)
  }

  const handleAddDocument = async (params: AddDocumentData) => {
    setIsLoading(true)

    const reqData = { id, ...params } as UploadDocumentParams

    try {
      const res = (await services.add(reqData)) as Attachments
      setDocs(prevDocs => [...prevDocs, res])
    } catch (error) {
      toastr.error('', 'При добавлении документа произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })
    } finally {
      setIsLoading(false)
      setIsEditModalOpen(false)

      if (afterFetch) {
        afterFetch()
      }
    }
  }

  const handleDeleteDocument = async (
    id: string | number,
    fileId: string | number,
  ) => {
    setIsLoading(true)

    try {
      if (services.delete) {
        await services.delete(fileId)
        setDocs(docs.filter(item => item.id !== fileId))
      }
    } catch (error) {
      toastr.error('', 'При удалении документа произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })
    } finally {
      setIsLoading(false)
      setIsEditModalOpen(false)

      if (afterFetch) {
        afterFetch()
      }
    }
  }

  const handleCheckBoxChange = async (docId: Key) => {
    const currentValue =
      documents.find(doc => doc.id === docId)?.sendFile ?? false

    setIsLoading(true)

    const reqData = { id: docId, value: !currentValue }

    try {
      if (services.setSendFile) {
        await services.setSendFile(reqData)
      }
    } catch (error) {
      toastr.error('', 'Произошла ошибка', {
        progressBar: false,
        showCloseButton: false,
      })
    } finally {
      setIsLoading(false)
      setIsEditModalOpen(false)

      if (afterFetch) {
        afterFetch()
      }
    }
  }

  return (
    <>
      <Accordion
        title='Документы'
        dataTestIdPrefix='documentsField'
        actions={
          <Tooltip title='Добавить документ'>
            <ActionButton
              data-test-id='documentsFieldAddBtn'
              onClick={openEditModal}
            >
              <ControlPointIcon />
            </ActionButton>
          </Tooltip>
        }
      >
        <DumbTable
          dataTestIdPrefix='documentsField'
          columns={COLUMNS}
          rows={documentsMapper(docs)}
          actions={({ id: rowId }) => (
            <ActionButton
              data-test-id={`documentsFieldDeleteBtn_${rowId}`}
              onClick={() => {
                toastr.confirm('Вы действительно хотите удалить этот файл?', {
                  onOk: () => handleDeleteDocument(id, rowId),
                })
              }}
            >
              <DeleteIcon />
            </ActionButton>
          )}
          showCheckBox={showCheckBox}
          checkBoxTitle={checkBoxTitle}
          onChangeCheckBox={handleCheckBoxChange}
        />
      </Accordion>
      <DumbModal
        dataTestIdPrefix='documentsField'
        title='Добавить документ'
        visible={isEditModalOpen}
        onClose={closeEditModal}
        actions={
          <>
            <Button
              type={EButtonType.DEFAULT}
              data-test-id='documentsFieldCancelBtn'
              text='Отменить'
              onClick={closeEditModal}
            />
            <Button
              type={EButtonType.PRIMARY}
              data-test-id='documentsFieldSubmitBtn'
              text='Сохранить'
              htmlType='submit'
              formId={ADD_DOCUMENT_FORM_ID}
              disabled={isLoading}
            />
          </>
        }
      >
        <ContentContainer>
          <DocumentsFieldLoader visible={isLoading} />
          <DocumentAddForm
            onSubmit={params => handleAddDocument(params)}
            showCheckBox={showCheckBox}
          />
        </ContentContainer>
      </DumbModal>
    </>
  )
}
