import { ListItem } from '@material-ui/core'
import styled from 'styled-components'

export const Root = styled(ListItem)`
  padding-right: 90px;
  & .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
` as typeof ListItem
