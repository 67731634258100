/* eslint-disable no-undefined */
import React from 'react'
import { Tooltip } from '@material-ui/core'

import {
  EButtonType,
  EButtonIcon,
  addDarkIcon,
  addWhiteIcon,
  deleteDarkIcon,
  deleteWhiteIcon,
  filterDarkIcon,
  filterWhiteIcon,
  saveIcon,
  searchIcon,
  settingIcon,
  uploadIcon,
  editIcon,
  photoIcon,
  menuIcon,
  arrowBackIcon,
  downloadWhiteIcon,
  downloadDarkIcon,
  refreshIcon,
  excelIcon,
  checkIcon,
  circleCloseIcon,
  arrowRightIcon,
  eyeIcon,
  extendIcon,
  arrowUpIcon,
  arrowDownIcon,
  detailsIcon,
} from '../../constants'
import { IButton } from '../../types'

import { StyledButton } from './Styles'

/*
 * @param typeIcon - Тип иконки
 * @param type - Тип кнопки
 * @param onClick - Функция для выполнения при нажатии на кнопку
 */

export const ButtonComponent: React.FC<IButton> = ({
  text,
  type,
  typeIcon,
  hidden = false,
  children,
  noMargin = false,
  disabled = false,
  onClick,
  flagCatalog = false,
  tooltip = '',
  formId,
  htmlType,
  ...other
}) => {
  const handleIconButton = (): JSX.Element | '' => {
    switch (typeIcon) {
      case EButtonIcon.ADD:
        if ([EButtonType.PRIMARY, EButtonType.WARNING].indexOf(type) !== -1) {
          return addWhiteIcon
        }
        return addDarkIcon

      case EButtonIcon.DELETE:
        if ([EButtonType.PRIMARY, EButtonType.WARNING].indexOf(type) !== -1) {
          return deleteWhiteIcon
        }
        return deleteDarkIcon

      case EButtonIcon.FILTER:
        if ([EButtonType.PRIMARY, EButtonType.WARNING].indexOf(type) !== -1) {
          return filterWhiteIcon
        }
        return filterDarkIcon

      case EButtonIcon.SETTINGS:
        return settingIcon
      case EButtonIcon.UPLOAD:
        return uploadIcon
      case EButtonIcon.DOWNLOAD:
        if ([EButtonType.PRIMARY, EButtonType.WARNING].indexOf(type) !== -1) {
          return downloadWhiteIcon
        }
        return downloadDarkIcon
      case EButtonIcon.SEARCH:
        return searchIcon
      case EButtonIcon.SAVE:
        return saveIcon
      case EButtonIcon.EDIT:
        return editIcon
      case EButtonIcon.PHOTO:
        return photoIcon
      case EButtonIcon.MENU:
        return menuIcon
      case EButtonIcon.ARROW_BACK:
        return arrowBackIcon
      case EButtonIcon.ARROW_RIGHT:
        return arrowRightIcon
      case EButtonIcon.REFRESH:
        return refreshIcon
      case EButtonIcon.EXCEL:
        return excelIcon
      case EButtonIcon.CHECK:
        return checkIcon
      case EButtonIcon.CIRCLE_CLOSE:
        return circleCloseIcon
      case EButtonIcon.EYE:
        return eyeIcon
      case EButtonIcon.EXTEND:
        return extendIcon
      case EButtonIcon.ARROW_UP:
        return arrowUpIcon
      case EButtonIcon.ARROW_DOWN:
        return arrowDownIcon
      case EButtonIcon.DETAILS:
        return detailsIcon
      default:
        return ''
    }
  }

  /**
   * wrapped in span for triggering tooltip when button is disabled
   * https://bit.ly/3cgl0iF
   */
  return !hidden ? (
    <Tooltip title={tooltip}>
      <StyledButton
        type={htmlType}
        hidden={hidden}
        colorType={type}
        noMargin={noMargin}
        onClick={onClick}
        disabled={disabled}
        aria-describedby={flagCatalog ? 'simple-popover' : undefined}
        form={formId}
        {...other}
      >
        {typeIcon && !flagCatalog ? handleIconButton() : undefined}
        <p>{text}</p>
        {children}
        {flagCatalog ? handleIconButton() : undefined}
      </StyledButton>
    </Tooltip>
  ) : null
}
