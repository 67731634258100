import styled, { css } from 'styled-components'
import { Button } from '@material-ui/core'

import { EButtonType } from '../../constants'

type StyledButtonProps = {
  colorType: EButtonType
  noMargin: boolean
}

export const StyledButton = styled(Button)`
  && {
    min-height: 44px;
    font-size: 14px;
    padding: 0 15px;
    opacity: 0.85;
    &.MuiButton-root {
      height: 44px;
      min-width: 40px;
      font-weight: 600;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      font-family: 'Proxima Nova Regular';
      &:hover {
        opacity: 1;
      }
    }
    & .MuiSvgIcon-root {
      font-size: 16px;
      margin-right: 10px;
      color: rgba(0, 0, 0, 0);
    }

    ${({ noMargin }: StyledButtonProps) =>
      noMargin
        ? css`
            & .MuiSvgIcon-root {
              margin: 0;
            }
          `
        : ''}
    & .MuiButton-label {
      text-transform: none;
    }
    ${({ colorType }: StyledButtonProps) => {
      switch (colorType) {
        case EButtonType.PRIMARY:
          return css`
            &.MuiButton-root {
              color: #fff;
              background-color: #1b55c5;
            }
            &.MuiButton-root.Mui-disabled {
              color: rgba(0, 0, 0, 0.26);
              background-color: #d3d3d3;
              & .MuiSvgIcon-root {
                opacity: 0.3;
              }
            }
          `
        case EButtonType.WARNING:
          return css`
            &.MuiButton-root {
              color: #fff;
              background-color: #c93a3a;
            }
          `
        case EButtonType.DEFAULT:
          return css`
            &.MuiButton-root {
              color: #616284;
              background-color: #f9f9fb;
              opacity: 1;
              &:hover {
                background-color: #eaeaf1;
              }
            }
            &.MuiButton-root.Mui-disabled {
              color: rgba(0, 0, 0, 0.26);
              & .MuiSvgIcon-root {
                opacity: 0.3;
              }
            }
          `
      }
    }}
  }
`
