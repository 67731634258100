import React, { useState, useMemo } from 'react'
import { isArray, uniqBy } from 'lodash'

import { TextField, IconButton, Typography } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import { SuppliersService } from '../../services/supplier-service'

import { ButtonComponent } from '../Button/Button'
import { EButtonIcon, EButtonType } from '../../constants'
import { DialogModal } from '../DialogModal/DialogModal'

import { mapConclusionToRus, formatValue } from '../../utils'
import { createDumbTableRows, getDateValue } from '../ImprovedTable/utils'
import { DocumentsField } from '../DocumentsField/DocumentsField'

import { SupplierHistoryField } from '../SupplierHistoryField'
import { DumbTable } from '../DumbTable/DumbTable'
import { Accordion } from '../Accordion/Accordion'
import { AGREEMENT_TABLE_COLUMNS } from '../ImprovedTable/constants'
import { IColumn, IKeyValuePair, TSupplierModel } from '../../types'

import {
  AccordionBody,
  CardRowBody,
  CardRowContainer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  Root,
  SecondPartFooter,
} from './Style'

export type SupplierDetailModalProps = {
  open: boolean
  columns: IColumn[]
  data: Partial<TSupplierModel>
  showEditButton?: boolean
  disableEditButton?: boolean
  showRemoveButton?: boolean
  disableRemoveButton?: boolean
  onClose?: () => void
  updateDrawer?: () => void
  onRemove?: (ids: string[]) => void
  onEdit?: () => void
}

export const SupplierDetailModal: React.FC<SupplierDetailModalProps> = ({
  open,
  columns,
  data,
  showEditButton = true,
  disableEditButton = false,
  showRemoveButton = true,
  disableRemoveButton = false,
  onClose,
  updateDrawer,
  onRemove,
  onEdit,
}): React.ReactElement => {
  const [openDialog, setOpenDialog] = useState(false)
  const sections = useMemo(
    () =>
      uniqBy(
        columns
          .filter(column => column.section)
          .map(column => column.section) as IKeyValuePair[],
        'key',
      ),
    [columns],
  )
  const agreements = useMemo(() => createDumbTableRows(data.agreements || []), [
    data.agreements,
  ])

  return (
    <>
      <Root anchor={'right'} open={open} onClose={onClose}>
        <div role='presentation'>
          <DrawerHeader>
            <DrawerTitle>
              <b data-test-id='supplierDetailModalHeading'>
                {data && data.name}
              </b>
              <IconButton
                data-test-id='supplierDetailModalCloseBtn'
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </DrawerTitle>
          </DrawerHeader>
          <DrawerContent>
            <CardRowContainer>
              <CardRowBody>
                <div title='Контактные данные'>
                  <Typography variant='h6' style={{ padding: '0 10px' }}>
                    Контактные данные
                  </Typography>
                  <AccordionBody>
                    <TextField
                      label='ФИО контактного лица'
                      value={data.representative || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='Телефон'
                      value={data.phone || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='Email'
                      value={data.email || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth={true}
                      style={{ maxWidth: '100%' }}
                      label='Юридический адрес'
                      value={data.address || '-'}
                      maxRows={5}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth={true}
                      style={{ maxWidth: '100%' }}
                      label='Фактический адрес'
                      value={data.actualAddress || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='ИНН'
                      value={data.inn || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='КПП'
                      value={data.kpp || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='Заключение СБ'
                      value={mapConclusionToRus(data.conclusionSB || '')}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='Дата заключения СБ'
                      value={getDateValue(data?.sbdate || '') || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                  </AccordionBody>
                </div>
              </CardRowBody>
            </CardRowContainer>
            <CardRowContainer>
              <CardRowBody>
                {sections.map((section, ind) => (
                  <Accordion
                    title={section.value}
                    // eslint-disable-next-line react/no-array-index-key
                    key={ind}
                    dataTestIdPrefix='supplierDetailModalSections'
                  >
                    <AccordionBody>
                      {columns
                        .filter(col => col.section?.key === section.key)
                        .map((col, index) => {
                          const colData = data.data?.find(
                            field => field.key === col.key,
                          )

                          return (
                            <TextField
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              fullWidth={true}
                              label={col.title}
                              value={
                                colData?.value
                                  ? formatValue(
                                      col.type,
                                      colData?.value,
                                      '-',
                                      col.unit,
                                    )
                                  : '-'
                              }
                              maxRows={3}
                              multiline={true}
                              InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                              }}
                            />
                          )
                        })}
                    </AccordionBody>
                  </Accordion>
                ))}
              </CardRowBody>
            </CardRowContainer>
            <CardRowContainer>
              <CardRowBody>
                <DocumentsField
                  services={{
                    add: SuppliersService.uploadDocument,
                    delete: SuppliersService.deleteDocument,
                  }}
                  afterFetch={updateDrawer}
                  id={data.id || ''}
                  documents={data.attachments || []}
                />
              </CardRowBody>
            </CardRowContainer>
            <CardRowContainer>
              <CardRowBody>
                <SupplierHistoryField data={data.tenderTasks || []} />
              </CardRowBody>
            </CardRowContainer>
            {isArray(agreements) && (
              <CardRowContainer>
                <Accordion
                  title='Соглашения'
                  dataTestIdPrefix='supplierDetailModalAgreements'
                >
                  <DumbTable
                    columns={AGREEMENT_TABLE_COLUMNS}
                    rows={agreements}
                  />
                </Accordion>
              </CardRowContainer>
            )}
          </DrawerContent>
          <DrawerFooter>
            {showRemoveButton && (
              <div>
                <ButtonComponent
                  data-test-id='supplierDetailModalDeleteBtn'
                  disabled={disableRemoveButton}
                  text={''}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.DELETE}
                  noMargin={true}
                  onClick={() => setOpenDialog(true)}
                />
              </div>
            )}
            {showEditButton && (
              <SecondPartFooter>
                <ButtonComponent
                  data-test-id='supplierDetailModalEditBtn'
                  disabled={disableEditButton}
                  text={'Редактировать'}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.EDIT}
                  onClick={(): void => {
                    if (onEdit) {
                      onEdit()
                    }
                    if (onClose) {
                      onClose()
                    }
                  }}
                />
              </SecondPartFooter>
            )}
          </DrawerFooter>
        </div>
      </Root>
      <DialogModal
        open={openDialog}
        handleDiscardChanges={() => setOpenDialog(false)}
        handleChanges={() => {
          if (onRemove) {
            onRemove([data.id as string])
          }
          if (onClose) {
            onClose()
          }
          setOpenDialog(false)
        }}
        modalTitle='Удаление поставщика'
        modalContent='Вы действительно хотите удалить поставщика?'
        modalButtonRightText='Удалить поставщика'
        modalButtonRightType={EButtonType.WARNING}
        modalButtonLeftText='Отменить'
        modalButtonLeftType={EButtonType.DEFAULT}
        dataTestIdPrefix='supplierDetailModalDeleteSupplierModal'
      />
    </>
  )
}
