import styled from 'styled-components'
import {
  Popover,
  TableCell,
  TableContainer,
  IconButton,
} from '@material-ui/core'

export const StyledTable = styled(TableContainer)`
  && {
    width: 270px;
    & .MuiTableCell-root {
      padding: 0;
    }
    & .MuiIconButton-label:hover,
    .MuiIconButton-label:focus,
    .MuiIconButton-label:active {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`
export const ColumnIconBtn = styled(TableCell)`
  && {
    width: 64px;
    & button:hover,
    & button:focus,
    & button:active {
      background-color: rgba(0, 0, 0, 0);
    }
    & .MuiButton-root {
      min-width: 160px;
    }
  }
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 3px 5px 13px;
  & p {
    font-size: 12px;
    color: #535f77;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  & p.section {
    color: #4c5ecf;
    font-size: 14px;
    font-weight: 600;
  }
`
export const ActionButton = styled(IconButton)`
  && {
    flex: 1 1 20px;
    padding: 14px;
    max-width: 20px;
    max-height: 20px;
    margin-right: 3px;
    & .MuiSvgIcon-root {
      color: rgba(0, 0, 0, 0);
      font-size: 18px;
    }
  }
`
export const StyledPopover = styled(Popover)`
  && {
    & .MuiPaper-root {
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`
