import styled from 'styled-components'
import { IconButton, Button } from '@material-ui/core'

export const ExpandedTableWrapper = styled.div`
  width: 100%;
`
export const ExpandedTableNameCell = styled.div`
  display: flex;
`

export const ExpandedTableEditButton = styled(Button)`
  background: #fff;
  font-size: 14px;
  text-transform: none;
`
export const ExpandedTableRemoveButton = styled(IconButton)`
  padding: 4px;
`
