import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormControl, TextField } from '@material-ui/core'

export const DrawerCondition = styled.div`
  background: #f9f9fb;
  border-radius: 5px;
  min-width: 286px;
  min-height: 222px;
  margin: 15px 24px;
  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
  }
`
export const DrawerConditionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & h5 {
    font-size: 14px;
    font-weight: bold;
    padding: 15px 15px 12px 15px;
    margin: 0;
    color: #616284;
  }
  & p {
    margin: 0;
    padding: 0;
    display: flex;
    & .MuiSvgIcon-root {
      color: rgba(0, 0, 0, 0);
      font-size: 16px;
      margin: 0 15px 0 -35px;
      cursor: pointer;
    }
  }
  & .MuiDivider-root {
    background-color: #e2e4e9;
    margin: 0 15px 0 115px;
  }
`
export const DrawerConditionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 0;
  font-size: 14px;
`
export const DrawerAutocomplete = styled(Autocomplete)`
  min-width: 266px;
  margin: 10px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  & .MuiAutocomplete-root {
    transition: 0.3s all ease-in-out;
    color: inherit;
  }
  & .MuiInputBase-root {
    display: flex;
    align-content: center;
    font-size: 14px;
    max-height: 44px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  }
  & .MuiFormHelperText-root {
    margin: 0 15px;
    background: #f9f9fb;
  }
  &.Mui-focused {
    min-width: 266px;
    cursor: text;
  }
  & .MuiSvgIcon-root {
    color: #4c5ecf;
  }
  & .MuiAutocomplete-listbox {
    & .MuiListSubheader-root {
      font-size: 14px;
    }
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }
  }
` as typeof Autocomplete
export const DrawerTextField = styled(TextField)`
  margin: 10px 0;
  & .MuiInputBase-root {
    min-width: 266px;
    max-height: 44px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 14px;
  }
  & .MuiFormHelperText-root {
    margin: 5px 15px;
  }
`
export const DrawerTextFieldIntervalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  & p {
    margin: 10px 4px;
  }
`
export const DrawerTextFieldInterval = styled(TextField)`
  margin: 10px 0;
  display: flex;
  & .MuiInputBase-root {
    max-width: 123px;
    max-height: 44px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 14px;
  }
  & .MuiFormHelperText-root {
    max-width: 115px;
    margin: 5px 0;
  }
`
export const DrawerSelectCondition = styled(FormControl)`
  display: flex;
  align-content: center;
  & .MuiInputBase-root {
    max-width: 120px;
    max-height: 30px;
    font-size: 12px;
    background: #f2f0f8;
    color: #1b55c5;
    font-weight: 600;
    margin: 0 0 10px 0;
  }
  & .MuiSvgIcon-root {
    color: #4c5ecf;
  }
  & .Mui-error {
    margin: 20px 0;
    & p {
      margin: 10px 0 0 0;
    }
  }
`
export const OptionConditionInput = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: 620px;
  word-break: break-all;
`
export const OptionConditionType = styled.div`
  margin-left: 20px;
  font-style: italic;
`
