import React from 'react'
import {
  DialogActions,
  DialogContent,
  Dialog,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { StyledTitle } from './Style'

type Props = {
  visible: boolean
  title: string
  actions: React.ReactElement
  onClose?: () => void
  dataTestIdPrefix?: string
}

export const DumbModal: React.FC<Props> = ({
  visible,
  onClose,
  title,
  actions,
  children,
  dataTestIdPrefix,
}) => (
  <Dialog onClose={onClose} open={visible}>
    <StyledTitle data-test-id={`${dataTestIdPrefix}Heading`}>
      {title}
      <IconButton
        data-test-id={`${dataTestIdPrefix}CloseBtn`}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </StyledTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>{actions}</DialogActions>
  </Dialog>
)
