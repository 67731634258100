import styled from 'styled-components'
import { Dialog, List, Popover } from '@material-ui/core'

export const Root = styled(Dialog)`
  && {
    margin: 0;
    padding: 2px;
    &.MuiDialog-paperWidthSm {
      max-width: 645px;
    }
  }
`

export const Form = styled.form`
  width: 600px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  & button {
    margin: 0 5px;
  }
`

export const CatalogSpec = styled(Popover)`
  && {
    & MuiPaper-root {
      max-height: 350px;
      height: 350px;
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      &::-webkit-scrollbar-track {
        background: '#f1f1f1';
      }
      &::-webkit-scrollbar-thumb {
        background: '#888';
      }
      &::-webkit-scrollbar-thumb:hover {
        background: '#555';
      }
    }
  }
`

export const Err = styled.div`
  color: rgb(228, 37, 37);
`

export const Label = styled.div`
  color: rgb(27, 43, 75);
  line-height: 17px;
  font-family: 'Proxima Nova Light';
`

export const ListEditor = styled(List)`
  && {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`
