/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from 'react'
import { toastr } from 'react-redux-toastr'
import debounce from 'lodash/debounce'
import {
  FormControlLabel,
  TextField,
  CircularProgress,
  FormControl,
  Checkbox,
} from '@material-ui/core'
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'

import { IKeyValuePair, IFileResult, TSuppliersFilters } from '../../types'
import { SuppliersService } from '../../services/supplier-service'

import {
  EButtonType,
  EAPIResponseStatus,
  EButtonIcon,
  EFileUploadListType,
  EEditableModalType,
} from '../../constants'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'

import { ButtonComponent } from '../Button/Button'

import { FileUpload } from '../FileUpload/FileUpload'

import * as I from './IAddAssignmentSupplierModal'
import {
  AutocompletePopper,
  ButtonContainer,
  Root,
  StyledAutocomplete,
  StyledModalContent,
} from './Style'

export const AddAssignmentSupplierModal: React.FC<I.OwnProps> = ({
  open,
  type,
  supplier,
  onClose,
  onSave,
}): React.ReactElement => {
  const [state, setState] = useState<string>(
    type === EEditableModalType.EDIT ? supplier?.providerId || '' : '',
  )
  const [errors, setErrors] = useState<string>('')
  const [supplierValues, setSupplierValues] = useState<Array<IKeyValuePair>>([])
  const [optionsListOpen, setOptionsListOpen] = React.useState(false)
  const [optionsListLoading, setOptionsListLoading] = useState<boolean>(false)
  const files = useRef<IFileResult>({
    current: [],
    add: [],
    remove: [],
  })
  const [suppliersFilters, setSuppliersFilters] = useState<TSuppliersFilters>({
    agreedSuppliers: true,
    notAgreedSuppliers: true,
    expiredSuppliers: true,
  })

  const fetchSuppliers = useCallback(
    debounce(
      async (value: string, currentSuppliersFilters: TSuppliersFilters) => {
        setOptionsListLoading(true)
        try {
          const [, result] = await SuppliersService.searchSuppliers(
            value,
            currentSuppliersFilters,
          )
          if (
            result.data.status === EAPIResponseStatus.SUCCESS &&
            result.data.data
          ) {
            const suppliers: Array<IKeyValuePair> | undefined =
              result.data.data.map(({ providerId, name }) => ({
                key: providerId,
                value: name,
              }))

            if (suppliers?.length) {
              setSupplierValues(
                suppliers.map(supplier => ({
                  key: supplier.key,
                  value: supplier.value || 'Без названия',
                })),
              )
            }
            setSuppliersFilters(currentSuppliersFilters)
          }
        } finally {
          setOptionsListLoading(false)
        }
      },
      500,
    ),
    [],
  )

  const handleSelectedValueChanged = (
    _: unknown,
    value: IKeyValuePair | null,
  ): void => {
    if (value) {
      const error = validateForm(value.key)
      setErrors(error)
      setState(value.key)
    } else {
      setErrors('Обязательное поле')
      setState('')
    }
  }

  const validateForm = (value: string): string => {
    let error = ''
    if (!value) {
      error = 'Значение не выбрано'
    }

    return error
  }

  const saveBtnHandler = (): void => {
    const error = validateForm(state)

    if (error.length) {
      setErrors(error)
      return
    } else if (!files.current.add.length) {
      toastr.error('', 'Прайс не прикреплён!')
      return
    }

    onSave(state, files.current.add[0].file)
  }

  const onDialogClose = (): void => {
    setErrors('')
    onClose()
  }

  return (
    <div>
      {open && (
        <Root
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
        >
          <ModalTitle
            dataTestIdPrefix='addAssignmentSupplierModal'
            id='customized-dialog-title'
            onClose={onDialogClose}
          >
            {type === EEditableModalType.INSERT
              ? 'Добавить поставщика'
              : 'Обновить прайс'}
          </ModalTitle>
          <StyledModalContent dividers>
            <div>Поставщик:</div>
            <div>
              <div>
                <FormControlLabel
                  label='Согласованные'
                  control={
                    <Checkbox
                      data-test-id='addAssignmentSupplierModalAgreedSuppliersCheckbox'
                      color='primary'
                      checked={suppliersFilters.agreedSuppliers}
                      onClick={async () => {
                        await setSuppliersFilters({
                          agreedSuppliers: !suppliersFilters.agreedSuppliers,
                          notAgreedSuppliers:
                            suppliersFilters.notAgreedSuppliers,
                          expiredSuppliers: suppliersFilters.expiredSuppliers,
                        })
                        setSupplierValues([])
                        fetchSuppliers('', {
                          agreedSuppliers: !suppliersFilters.agreedSuppliers,
                          notAgreedSuppliers:
                            suppliersFilters.notAgreedSuppliers,
                          expiredSuppliers: suppliersFilters.expiredSuppliers,
                        })
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label='Не согласованные'
                  control={
                    <Checkbox
                      data-test-id='addAssignmentSupplierModalNotAgreedSuppliersCheckbox'
                      color='primary'
                      checked={suppliersFilters.notAgreedSuppliers}
                      onChange={async () => {
                        await setSuppliersFilters({
                          agreedSuppliers: suppliersFilters.agreedSuppliers,
                          notAgreedSuppliers:
                            !suppliersFilters.notAgreedSuppliers,
                          expiredSuppliers: suppliersFilters.expiredSuppliers,
                        })
                        setSupplierValues([])
                        fetchSuppliers('', {
                          agreedSuppliers: suppliersFilters.agreedSuppliers,
                          notAgreedSuppliers:
                            !suppliersFilters.notAgreedSuppliers,
                          expiredSuppliers: suppliersFilters.expiredSuppliers,
                        })
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label='Срок проверки истек'
                  control={
                    <Checkbox
                      data-test-id='addAssignmentSupplierModalExpiredSuppliersCheckbox'
                      color='primary'
                      checked={suppliersFilters.expiredSuppliers}
                      onChange={async () => {
                        await setSuppliersFilters({
                          agreedSuppliers: suppliersFilters.agreedSuppliers,
                          notAgreedSuppliers:
                            suppliersFilters.notAgreedSuppliers,
                          expiredSuppliers: !suppliersFilters.expiredSuppliers,
                        })
                        setSupplierValues([])
                        fetchSuppliers('', {
                          agreedSuppliers: suppliersFilters.agreedSuppliers,
                          notAgreedSuppliers:
                            suppliersFilters.notAgreedSuppliers,
                          expiredSuppliers: !suppliersFilters.expiredSuppliers,
                        })
                      }}
                    />
                  }
                />
              </div>
              <StyledAutocomplete
                filterOptions={(
                  options: Array<IKeyValuePair>,
                ): Array<IKeyValuePair> => options}
                PopperComponent={AutocompletePopper}
                fullWidth
                disabled={type === EEditableModalType.EDIT}
                open={optionsListOpen && type === EEditableModalType.INSERT}
                onOpen={(): void => {
                  setOptionsListOpen(true)
                }}
                onClose={(): void => {
                  setOptionsListOpen(false)
                }}
                onChange={handleSelectedValueChanged}
                onFocus={() => {
                  fetchSuppliers('', suppliersFilters)
                }}
                onInputChange={(_: unknown, value: string): void => {
                  fetchSuppliers(value, suppliersFilters)
                }}
                defaultValue={
                  // eslint-disable-next-line no-undefined
                  type === EEditableModalType.EDIT && supplier
                    ? { key: supplier.providerId, value: supplier.name }
                    : undefined
                }
                noOptionsText='Ничего не найдено'
                loadingText='Загрузка данных'
                getOptionSelected={(
                  option: IKeyValuePair,
                  value: IKeyValuePair,
                ): boolean => option.key === value.key}
                getOptionLabel={(option: IKeyValuePair): string => option.value}
                options={supplierValues}
                loading={optionsListLoading}
                closeText='Закрыть'
                openText='Открыть'
                renderInput={(
                  params: AutocompleteRenderInputParams,
                ): JSX.Element => (
                  <TextField
                    {...params}
                    data-test-id='addAssignmentSupplierModalSupplierInput'
                    variant='filled'
                    helperText={errors}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {optionsListLoading ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <FormControl>
              {files !== null && files.current !== null && (
                <FileUpload
                  initialState={files as React.MutableRefObject<IFileResult>}
                  fileTypes={'xls,xlsx'}
                  maxNumberOfFiles={1}
                  fileSize={5}
                  addBtnText={'Добавить прайс (xls)'}
                  addBtnIcon={EButtonIcon.EXCEL}
                  allowMultiplySelect={false}
                  listType={EFileUploadListType.FILE}
                  showAddBtn
                  showDeleteBtn
                  dataTestIdPrefix='addAssignmentSupplierModal'
                />
              )}
            </FormControl>
          </StyledModalContent>
          <ModalActions>
            <ButtonContainer>
              <ButtonComponent
                data-test-id='addAssignmentSupplierModalCancelBtn'
                text='Отменить'
                type={EButtonType.DEFAULT}
                onClick={onDialogClose}
              />
              <ButtonComponent
                data-test-id='addAssignmentSupplierModalSubmitBtn'
                text={
                  type === EEditableModalType.INSERT
                    ? 'Добавить поставщика'
                    : 'Обновить прайс'
                }
                type={EButtonType.PRIMARY}
                onClick={saveBtnHandler}
              />
            </ButtonContainer>
          </ModalActions>
        </Root>
      )}
    </div>
  )
}
