// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O0F6PKVKwtcMQkWW6BH0tw\\=\\={padding:0 8px !important}.XkoVdgBDnlVqq9-t\\+3oapQ\\=\\={justify-content:center !important}.XRor7uhka65N6V\\+rljRYyw\\=\\={padding:10px 9px !important}._8czCbCH2DSbDt4EDEj1UhA\\=\\={padding:0 2px !important}", "",{"version":3,"sources":["webpack://./pages/position/positionColumns.module.scss"],"names":[],"mappings":"AAAA,4BACE,wBAAA,CAEF,6BACE,iCAAA,CAEF,6BACE,2BAAA,CAEF,6BACE,wBAAA","sourcesContent":[".hashCell {\n  padding: 0 8px !important;\n}\n.linkCell {\n  justify-content: center !important;\n}\n.headNameCell {\n  padding: 10px 9px !important;\n}\n.headHashHeader {\n  padding: 0 2px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hashCell": "O0F6PKVKwtcMQkWW6BH0tw==",
	"linkCell": "XkoVdgBDnlVqq9-t+3oapQ==",
	"headNameCell": "XRor7uhka65N6V+rljRYyw==",
	"headHashHeader": "_8czCbCH2DSbDt4EDEj1UhA=="
};
export default ___CSS_LOADER_EXPORT___;
