import { FormControl, FormLabel } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'

export const Root = styled(FormControl)`
  width: 100%;
`
export const Label = styled(FormLabel)`
  && {
    margin-bottom: 16px;
    color: #1b2b4b;
    height: 17px;
    font-size: 14px;
    align-self: flex-start;
    font-family: 'Proxima Nova Light';
    line-height: 17px;
  }
  &.Mui-focused {
    color: #1b2b4b;
  }
`
export const Zone = styled.div`
  border: 2px dashed #dedede;
  cursor: pointer;
  outline: none;
  padding: 4px;
  .Mui-error ~ & {
    border-color: #f44336;
  }
`
export const InfoText = styled.p`
  text-align: center;
  font-size: 14px;
  color: #1b2b4b;
  font-family: 'Proxima Nova Light';
`
export const Files = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`
export const StyledFileItem = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  overflow: hidden;
`
export const FileName = styled.a`
  font-size: 12px;
  color: #1b2b4b;
  font-family: 'Proxima Nova Regular';
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
export const DeleteIcon = styled(CloseIcon)`
  && {
    color: #1b55c5;
    font-size: 14px;
    margin-left: 5px;
    cursor: pointer;
  }
`
