import { createAuthClient } from '@gmini/auth'

import { keycloakUrl, realm } from './config'

const authClient = createAuthClient({
  clientId: 'Grand-Tender',
  realm: realm || 'gmini',
  url: keycloakUrl || 'https://auth.dev.gmini.tech/auth/',
})

export { authClient }
