/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useState, useEffect, useMemo } from 'react'
import {
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core'
import * as _ from 'lodash'

import { useParams } from 'react-router'

import {
  getCommonPositionAttributes,
  getPositionAttributesMeasureUnits,
} from '../../../../../actions'

import { useAppDispatch } from '../../../../../store'

import {
  IColumn,
  IKeyValuePair,
  PositionAttribute,
  PositionAttributeMeasureUnit,
  PositionTab,
} from '../../../../../types'
import {
  EButtonType,
  EColumnType,
  EEditableModalType,
} from '../../../../../constants'
import { ModalTitle } from '../../../../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../../../../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../../../../BaseModal/ModalActions/ModalActions'

import { ButtonComponent } from '../../../../Button/Button'

import * as I from './IEditablePropertyModal'
import { SectionsList } from './Sections/Sections'
import { ButtonContainer, Root } from './Style'

const mapEnumToSelect: { [key in EColumnType]: string } = {
  [EColumnType.BOOLEAN]: 'Логический',
  [EColumnType.CURRENCY]: 'Денежный',
  [EColumnType.DATE]: 'Дата',
  [EColumnType.DOUBLE]: 'Числовой',
  [EColumnType.PHONE]: 'Телефон',
  [EColumnType.STRING]: 'Строковый',
  [EColumnType.LIST]: 'Список',
  [EColumnType.TREE_LIST]: 'Древовидный список',
}

export const EditablePropertyModal: React.FC<I.OwnProps> = ({
  open,
  type,
  title,
  model,
  addExistingPropertyHandler,
  useSections = false,
  onAddSection,
  onEditSection,
  onRemoveSection,
  getSectionsDataSource,
  onClose,
  onSave,
}): React.ReactElement => {
  const { tab } = useParams<{ tab: string }>()
  const dispatch = useAppDispatch()
  const [measureUnits, setMeasureUnits] = useState<
    PositionAttributeMeasureUnit[]
  >([])
  const [commonPositionAttributes, setCommonPositionAttributes] = useState<
    PositionAttribute[]
  >([])
  const [isAddExistingAttribute, setIsAddExistingAttribute] = useState<
    boolean
  >()
  const [state, setState] = useState<IColumn>(model)

  const isAddingNewProperty = type === EEditableModalType.INSERT

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    title: '',
    type: '',
    measureUnitId: '',
    section: '',
  })
  const measureUnitsMap = useMemo(
    () =>
      measureUnits.reduce(
        (acc: { [x: number]: PositionAttributeMeasureUnit }, u) => ({
          ...acc,
          [u.id]: u,
        }),
        {},
      ),
    [measureUnits],
  )
  const [hideModal, setHideModal] = useState(false)

  useEffect(() => {
    const getMeasureUnits = async () => {
      setMeasureUnits(
        await dispatch(getPositionAttributesMeasureUnits({})).unwrap(),
      )
    }
    getMeasureUnits()
  }, [])

  const validate = (required: boolean, value: string, key: string): string => {
    const msg =
      required && validateRequiredProp(value) ? 'Обязательное поле' : ''

    setErrors({ ...errors, [key]: msg })
    return msg
  }

  const validateRequiredProp = (value: string): boolean =>
    !value || !value.trim()

  const validateForm = (): { [key: string]: string } => {
    const error: { [key: string]: string } = {}
    const keysToValidate = ['title', 'type', 'measureUnitId']
    const pairs = _.toPairs(state)

    if (useSections && state.section) {
      keysToValidate.push('section')
    }

    pairs.forEach(pair => {
      if (keysToValidate.indexOf(pair[0]) !== -1) {
        const msg = validate(
          ['measureUnitId'].indexOf(pair[0]) === -1,
          `${pair[1]}`,
          pair[0],
        )
        if (msg.length) {
          error[pair[0]] = msg
        }
      }
    })

    return error
  }

  const saveBtnHandler = (): void => {
    if (
      addExistingPropertyHandler &&
      isAddExistingAttribute &&
      isAddingNewProperty
    ) {
      addExistingPropertyHandler(state)
      onDialogClose()
      return
    }
    const error = validateForm()

    if (_.keys(error).length) {
      setErrors({ ...errors, ...error })
      return
    }
    if (addExistingPropertyHandler) {
      state.id = state.key
      onSave(state, state.section?.key)
    } else {
      onSave(state, state.section?.key)
    }
  }

  const onDialogClose = (): void => {
    setErrors({
      title: '',
      type: '',
      measureUnitId: '',
      section: '',
    })
    onClose()
  }

  const updateSectionState = (value: IKeyValuePair | null): void =>
    setState({ ...state, section: value })

  const updateSectionError = (message: string): void =>
    setErrors({ ...errors, section: message })

  const toggleParentCtrl = (hide: boolean): void => setHideModal(hide)

  const fetchAttributesByName = _.debounce(
    async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const name = e.target.value
      setCommonPositionAttributes(
        await dispatch(
          getCommonPositionAttributes({
            name,
            passed: tab === String(PositionTab.Passed) ? true : false,
          }),
        ).unwrap(),
      )
    },
    500,
  )

  return (
    <div>
      {open && (
        <Root
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          hide={hideModal}
        >
          <ModalTitle
            dataTestIdPrefix='editPropertyModal'
            id='customized-dialog-title'
            onClose={onDialogClose}
          >
            {isAddingNewProperty ? title.insert : title.edit}
          </ModalTitle>
          <ModalContent dividers>
            {addExistingPropertyHandler && isAddingNewProperty
              ? isAddExistingAttribute
                ? 'В категорию будет добавлен выбранный из списка, существующий атрибут. Чтобы изменить это, измените один из параметров или введите и выберите своё название.'
                : 'Будет создан и добавлен в категорию новый атрибут. Чтобы изменить это, введите часть названия существующего атрибута и выберите его из списка.'
              : null}
            <FormControlLabel
              className='fullWidth'
              control={
                addExistingPropertyHandler && isAddingNewProperty ? (
                  <Autocomplete
                    options={commonPositionAttributes}
                    filterOptions={(options, state) =>
                      !options.length && state.inputValue
                        ? [
                            {
                              id: 0,
                              measureUnitSymbol: '',
                              measureUnitId: null,
                              name: state.inputValue,
                              required: false,
                              type: EColumnType.STRING,
                            },
                          ]
                        : options
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant='outlined'
                        onChange={fetchAttributesByName}
                        placeholder='Начните вводить для поиска результатов'
                        fullWidth={true}
                        data-test-id='editPropertyModalNameInput'
                      />
                    )}
                    fullWidth={true}
                    onChange={(event, value) => {
                      setState(s => ({
                        ...s,
                        title: value?.name || '',
                        id: value?.id,
                        required: value?.required || false,
                        type: value?.type || EColumnType.STRING,
                        measureUnitId: value?.measureUnitId || null,
                      }))
                      if (value?.id === 0) {
                        setIsAddExistingAttribute(false)
                      } else {
                        setIsAddExistingAttribute(true)
                      }
                    }}
                    noOptionsText='Нет совпадений'
                    getOptionLabel={option => option.name}
                    renderOption={(option, idx) =>
                      option.id === 0 ? (
                        <div
                          data-test-id={`editPropertyModalNameInputOption_${idx}`}
                        >
                          {`${option.name} `}
                          <span>(Создать атрибут)</span>
                        </div>
                      ) : (
                        <div
                          data-test-id={`editPropertyModalNameInputOption_${idx}`}
                        >{`${option.name} (${mapEnumToSelect[option.type]}, ${
                          option.required ? 'обязательный' : 'необязательный'
                        }${
                          option.measureUnitSymbol
                            ? `, ${option.measureUnitSymbol}`
                            : ''
                        })`}</div>
                      )
                    }
                  />
                ) : (
                  <TextField
                    required={true}
                    error={errors.title.length > 0}
                    helperText={errors.title}
                    fullWidth
                    variant='filled'
                    value={state.title}
                    placeholder='Введите название'
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      validate(true, e.target.value, 'title')
                      setState(s => ({ ...s, title: e.target.value }))
                    }}
                    inputProps={{
                      maxLength: 64,
                      'data-test-id': 'editPropertyModalNameInput',
                    }}
                  />
                )
              }
              label='Название'
              labelPlacement='top'
            />
            <div className='propContainer'>
              <FormControlLabel
                className='prop'
                control={
                  <TextField
                    type='text'
                    select
                    disabled={type === EEditableModalType.EDIT}
                    required={false}
                    error={errors.type.length > 0}
                    helperText={errors.type}
                    variant='filled'
                    value={state.type}
                    inputProps={{
                      'data-test-id': 'editPropertyModalTypeInput',
                    }}
                  >
                    {Object.values(EColumnType)
                      .filter(
                        v =>
                          v !== EColumnType.LIST && v !== EColumnType.TREE_LIST,
                      )
                      .map((v, i) => (
                        <MenuItem
                          data-test-id={`editPropertyModalTypeInputOption_${i}`}
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${v}${i}`}
                          value={v}
                          onClick={() => {
                            validate(true, v, 'type')
                            if (
                              [
                                EColumnType.BOOLEAN,
                                EColumnType.PHONE,
                                EColumnType.DATE,
                                EColumnType.CURRENCY,
                              ].includes(v)
                            ) {
                              setState(s => ({
                                ...s,
                                type: v,
                                measureUnitId: null,
                              }))
                              setIsAddExistingAttribute(false)
                              return
                            }
                            setState(s => ({ ...s, type: v }))
                            setIsAddExistingAttribute(false)
                          }}
                        >
                          {mapEnumToSelect[v]}
                        </MenuItem>
                      ))}
                  </TextField>
                }
                label={'Тип'}
                labelPlacement='top'
              />
              {addExistingPropertyHandler ? (
                <FormControlLabel
                  className='prop'
                  control={
                    <Autocomplete
                      options={measureUnits}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant='outlined'
                          fullWidth={true}
                          data-test-id='editPropertyModalMeasureUnitInput'
                        />
                      )}
                      fullWidth={true}
                      onChange={(event, value) => {
                        setState(s => ({
                          ...s,
                          measureUnitId: value?.id || null,
                        }))
                        setIsAddExistingAttribute(false)
                      }}
                      noOptionsText='Нет совпадений'
                      getOptionLabel={option =>
                        `${option.title} (${option.symbol})`
                      }
                      value={measureUnitsMap[state.measureUnitId || 0] || null}
                    />
                  }
                  label={'Единица измерения'}
                  labelPlacement='top'
                />
              ) : null}
            </div>
            <FormControlLabel
              className='prop'
              control={
                <Switch
                  checked={state.required || false}
                  onChange={(): void => {
                    setState(s => ({ ...s, required: !s.required }))
                    setIsAddExistingAttribute(false)
                  }}
                  name={'required'}
                  data-test-id='editPropertyModalRequirementSwitch'
                />
              }
              label={'Обязательное'}
              labelPlacement='top'
            />
            {useSections && onAddSection && getSectionsDataSource && (
              <SectionsList
                initValue={state.section}
                disabled={false}
                helperText={errors.section}
                onAddSection={onAddSection}
                onEditSection={onEditSection}
                onRemoveSection={onRemoveSection}
                getSectionsDataSource={getSectionsDataSource}
                updateModel={updateSectionState}
                updateError={updateSectionError}
                toggleParentCtrl={toggleParentCtrl}
              />
            )}
          </ModalContent>
          <ModalActions>
            <ButtonContainer>
              <ButtonComponent
                data-test-id='editPropertyModalCancelBtn'
                text='Отменить'
                type={EButtonType.DEFAULT}
                onClick={onDialogClose}
              />
              <ButtonComponent
                data-test-id='editPropertyModalSubmitBtn'
                text={isAddingNewProperty ? 'Добавить свойство' : 'Сохранить'}
                type={EButtonType.PRIMARY}
                onClick={saveBtnHandler}
              />
            </ButtonContainer>
          </ModalActions>
        </Root>
      )}
    </div>
  )
}
