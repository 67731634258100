import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormHelperText } from '@material-ui/core'

import { FileItem } from './FileItem'
import { Files, InfoText, Label, Root, Zone } from './Style'

type Props = {
  disabled?: boolean
  multiple?: boolean
  label?: string
  infoText?: string
  error?: boolean | string
  value?: File[]
  accept?: string[]
  onChange?: (files: File[]) => void
}

export const DropZone: React.FC<Props> = ({
  disabled,
  label = 'Файлы',
  error,
  infoText = 'Кликните по полю или перетащите сюда файл',
  value = [],
  multiple = false,
  accept,
  onChange,
}) => {
  const onChangeHandler = useCallback(
    acceptedFiles => {
      if (onChange) {
        onChange(acceptedFiles)
      }
    },
    [onChange],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onChangeHandler,
    disabled,
    multiple,
    accept,
  })
  const onDelete = useCallback(
    (file: File) => {
      onChangeHandler(value.filter(one => one !== file))
    },
    [onChangeHandler, value],
  )

  return (
    <Root error={!!error}>
      <Label>{label}</Label>
      <Files>
        {value?.map((item, idx) => (
          <FileItem
            key={`${item.name}${item.lastModified}${item.size}`}
            onDelete={onDelete}
            file={item}
            dataTestIdPostfix={`${idx}`}
          />
        ))}
      </Files>
      {!value.length && !multiple && (
        <Zone {...getRootProps()}>
          <input
            {...getInputProps()}
            multiple={false}
            data-test-id='dropZoneInput'
          />

          <InfoText>{infoText}</InfoText>
        </Zone>
      )}
      <FormHelperText>{error}</FormHelperText>
    </Root>
  )
}
