import styled from 'styled-components'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@material-ui/core'

export const Lot = styled.div`
  display: flex;
  width: 100%;
`
export const DrawerEditBtn = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
    & .MuiSvgIcon-root {
      font-size: 16px;
    }
  }
`
export const CardRowBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  margin: 0 -10px;
  & .MuiFormControl-root {
    margin: 5px 10px;
    max-width: 280px;
  }
  & div.MuiInput-formControl {
    margin-top: 0 !important;
  }
  & .MuiInputBase-input {
    line-height: 25px;
  }
  & label {
    margin-left: 0;
    position: relative;
  }
`

type AccordionRootProps = {
  expanded: boolean
}

export const AccordionRoot = styled(Accordion)`
  && {
    border-top: 1px solid rgb(224, 224, 224);
    box-shadow: none;
    &:not(:last-child) {
      border-bottom: 0;
    }
    &:before {
      display: none;
    }
    ${({ expanded }: AccordionRootProps) => (expanded ? 'margin: auto;' : '')}

    &.MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
    & .MuiCollapse-container {
      max-width: calc(100vw - 205px);
    }
    & .MuiAccordionSummary-root:hover:not(.Mui-disabled) {
      cursor: default;
    }
    & .MuiTypography-root {
      margin-left: 12px;
      color: #4c5ecf;
      font-size: 16px;
      font-weight: 600;
      font-family: 'Proxima Nova Regular';
    }
  }
`
export const AccordionSummaryRoot = styled(AccordionSummary)`
  && {
    background-color: #f9f9fb;
    border-bottom: 1px solid rgb(224, 224, 224);
    margin-bottom: -1;
    min-height: 50px !important;
    max-height: 50px !important;
    padding: 0 12px;
  }
`

export const AccordionDetailsRoot = styled(AccordionDetails)`
  && {
    padding: 0;
  }
`
export const AccordionSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  & .accordionSummaryHeaderContentLeft {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    & .MuiTypography-root {
      max-width: 1000px;
      flex-wrap: wrap;
    }
  }
  & .accordionSummaryHeaderContentRight {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
  }
`
