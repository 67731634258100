import styled from 'styled-components'
import { AppBar, Tabs } from '@material-ui/core'

export const StyledAppBar = styled(AppBar)`
  && {
    background-color: white;
    box-shadow: none;
    color: #616284;
    flex: 0 0 60px;
    z-index: 1;
  }
`
export const StyledTabs = styled(Tabs)`
  & .MuiTab-root {
    max-width: none;
    min-width: none;
    padding: 25px 0;
    margin: 0 10px;
  }
  & .MuiTab-textColorPrimary {
    color: #535f77;
    &.Mui-selected {
      color: #4c5ecf;
    }
  }
  & .MuiTab-wrapper {
    font-family: Proxima Nova Light;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-transform: none;
  }
  & .PrivateTabIndicator-colorPrimary-13 {
    background-color: #4c5ecf;
    height: 3px;
  }
`
export const Toolbar = styled.div``
export const SearchFilterContainer = styled.div`
  display: flex;
`
export const DrawerList = styled.div`
  background: rgba(44, 52, 98, 0.22);
  & .MuiList-root:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  & .MuiButton-label {
    font-weight: normal;
  }
  & .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0);
  }
  & .MuiPaper-root {
    width: 334px;
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`
export const DrawerContentDivider = styled.div`
  margin: 0 0 0 24px;
  background-color: #e2e4e9;
`
export const DrawerListHeader = styled.div`
  margin: 30px 10px 20px 24px;
  padding: 0;
  & h5 {
    font-size: 24px;
    color: #000;
    font-weight: normal;
    margin: 0;
    cursor: default;
  }
  & .MuiList-padding {
    min-width: 334px;
  }
`
export const DrawerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px -5px 30px 0px rgba(50, 50, 50, 0.05);
  margin: 0 15px 0 0;
  height: 59px;
  width: 318px;
  background: #ffffff;
  right: 0;
  bottom: 0;
  & button {
    margin: 0 0 0 10px;
  }
`
export const DrawerContent = styled.div`
  & .MuiButton-root {
    min-width: 286px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 64px 24px;
  }
`
export const FilterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  & .makeStyles-primary-27 {
    border-radius: 5px 0 0 5px;
  }
  & div:last-child {
    border-radius: 0 5px 5px 0;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: #4c5ecf;
    min-height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiSvgIcon-root {
      margin-right: 0;
    }
  }
`
export const SearchBackBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  & h5 {
    font-size: 24px;
    font-weight: 500;
    color: #1b2b4b;
    margin: 26px;
  }
`
