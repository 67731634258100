import React, { useState } from 'react'
import { TableCell, TableRow } from '@material-ui/core'

import { useLocation } from 'react-router-dom'

import { DialogModal } from '../../../../../DialogModal/DialogModal'

import {
  pencilCreateIcon,
  circleXIcon,
  EButtonType,
} from '../../../../../../constants'

import * as I from './IImprovedTableHeadDisplayColumnsRow'
import {
  ActionButton,
  ActionsContainer,
  CheckedRow,
  Empty,
  FlexContainer,
} from './Style'

const titleMap: { [x: string]: string } = {
  '#': 'Хэш-код позиции',
  Ссылка: 'Ссылка на Revit-семейство',
}

export const ImprovedTableHeadDisplayColumnsRow: React.FC<I.OwnProps> = ({
  index,
  column,
  isVisible,
  showEditPropertyButtons,
  showSections,
  onColumnVisibilityChanged,
  onEditPropertyBtnClick = () => (): number => 0,
  removePropertyHandler,
}): React.ReactElement => {
  const [openRemoveDialogModal, setOpenRemoveDialogModal] = useState(false)
  const location = useLocation()

  const isDisabled =
    column.title === 'Автор' ||
    column.title === 'Дата создания' ||
    column.title === '#'
      ? false
      : column.base || column.required

  return (
    <TableRow data-test-id={`columnSettingsRow_${index}`} key={index}>
      <TableCell align='left'>
        <FlexContainer>
          {!column.section || showSections ? (
            <CheckedRow
              data-test-id={`columnSettingsPopupCheckbox_${index}`}
              color='primary'
              onClick={(event: React.MouseEvent<HTMLButtonElement>): void =>
                onColumnVisibilityChanged(event, column)
              }
              checked={isVisible}
              disabled={isDisabled}
              disableRipple
            />
          ) : (
            <Empty />
          )}
          {titleMap[column.title] || column.title}
        </FlexContainer>
      </TableCell>
      <TableCell align='right'>
        {location.pathname.indexOf('assignments/') === -1 && (
          <>
            <ActionsContainer>
              {!column.base &&
                showEditPropertyButtons &&
                column.section?.value !== 'Колонки поставщика' && (
                  <ActionButton
                    data-test-id={`columnSettingsPopupEditPropertyBtn_${index}`}
                    onClick={onEditPropertyBtnClick(column)}
                  >
                    {pencilCreateIcon}
                  </ActionButton>
                )}
              {!column.base && showEditPropertyButtons && (
                <ActionButton
                  data-test-id={`columnSettingsPopupDeletePropertyBtn_${index}`}
                  onClick={(): void => setOpenRemoveDialogModal(true)}
                >
                  {circleXIcon}
                </ActionButton>
              )}
            </ActionsContainer>
            {openRemoveDialogModal && (
              <DialogModal
                open={openRemoveDialogModal}
                modalTitle={'Удалить'}
                modalContent={'Вы действительно хотите удалить свойство?'}
                modalButtonLeftText={'Отменить'}
                modalButtonRightText={'Удалить'}
                modalButtonLeftType={EButtonType.DEFAULT}
                modalButtonRightType={EButtonType.WARNING}
                handleDiscardChanges={(): void =>
                  setOpenRemoveDialogModal(false)
                }
                handleChanges={(): void => {
                  removePropertyHandler?.(column.key, () =>
                    setOpenRemoveDialogModal(false),
                  )
                }}
                dataTestIdPrefix='columnSettingsPopupDeletePropertyModal'
              />
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  )
}
