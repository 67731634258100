import { DialogTitle } from '@material-ui/core'
import styled from 'styled-components'

export const StyledTitle = styled(DialogTitle)`
  & .MuiTypography-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
