import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  AccordionDetailsStyled,
  AccordionSummaryStyled,
  MiuiAccordionStyled,
  Text,
} from './Style'

type Props = {
  title: string
  actions?: React.ReactElement
  dataTestIdPrefix?: string
}

export const Accordion: React.FC<Props> = ({
  title,
  children,
  actions,
  dataTestIdPrefix,
}) => (
  <MiuiAccordionStyled>
    <AccordionSummaryStyled
      data-test-id={`${dataTestIdPrefix}AccordionHeadBtn`}
      expandIcon={<ExpandMoreIcon />}
    >
      <Text>{title}</Text>
      <Text>{actions}</Text>
    </AccordionSummaryStyled>
    <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
  </MiuiAccordionStyled>
)
