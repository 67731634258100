import { useHistory } from 'react-router-dom'
import { useQuery } from '@gmini/utils'

import { Tab, Toolbar } from '@material-ui/core'

import React, { useEffect } from 'react'

import { Authorities } from '../../../services/auth-service/auth-constants'

import { EButtonIcon, EButtonType } from '../../../constants'
import { EPageType, PositionTab, TSearchRequest } from '../../../types'

import { ButtonComponent } from '../../Button/Button'

import { usePermissions } from '../../../hooks/usePermissions'

import * as I from './IAppHeader'

import { AppHeaderSearchAndFilter } from './AppHeaderSearchAndFilter/AppHeaderSearchAndFilter'
import { SearchBackBlock, StyledAppBar, StyledTabs } from './Style'

type AppHeaderPositionsContainerProps = I.StateProps &
  I.DispatchProps &
  I.OwnProps & {
    showDrawerHandler: (isShow: boolean) => void
    flagBackPageHandler: () => void
    refreshCategoryId: () => void
    a11yProps: (index: number) => Record<string, unknown>
    flagBackPage: boolean
    openDrawer: boolean
    setFlagBackPage: React.Dispatch<React.SetStateAction<boolean>>
    search: boolean
  }

export const AppHeaderPositionsContainer = ({
  ...props
}: AppHeaderPositionsContainerProps) => {
  const {
    showDrawerHandler,
    flagBackPageHandler,
    refreshCategoryId,
    a11yProps,
    flagBackPage,
    openDrawer,
    setFlagBackPage,
    shouldResetFilter,
    filterFields,
    getFilterFields,
    getSearchOtherPageFilterItems,
    activeTabIndex,
    availableColumns,
    changeActiveTab,
    search,
  } = props

  const query = useQuery()
  const categoryId = query.get('categoryId') || ''
  const checkPermissions = usePermissions()
  const history = useHistory()

  //TODO Стоит обратить внимание
  useEffect(() => {
    let availableTab = 0
    if (checkPermissions(Authorities.ITEMS_SHOW_TAB_ALL)) {
      return
    } else if (checkPermissions(Authorities.ITEMS_SHOW_TAB_PASSED_GT)) {
      availableTab = 1
    } else if (checkPermissions(Authorities.ITEMS_SHOW_TAB_NOT_PASSED_GT)) {
      availableTab = 2
    } else if (checkPermissions(Authorities.ITEMS_SHOW_TAB_ARCHIVE)) {
      availableTab = 3
    }
    changeActiveTab(availableTab, refreshCategoryId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledAppBar position='static'>
      <Toolbar>
        {search ? (
          <SearchBackBlock>
            <ButtonComponent
              data-test-id='positionsSearchBackBtn'
              text={''}
              type={EButtonType.DEFAULT}
              typeIcon={EButtonIcon.ARROW_BACK}
              noMargin
              hidden={false}
              onClick={(): void => {
                history.push({
                  pathname: `/positions/${activeTabIndex.positions}`,
                  search: query.toString(),
                })
                setFlagBackPage(true)
              }}
            />
            <h5 data-test-id='positionsSearchHeading'>Поиск по позициям</h5>
          </SearchBackBlock>
        ) : (
          <StyledTabs
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            value={activeTabIndex.positions}
            onChange={(_, value: number) =>
              changeActiveTab(value, refreshCategoryId)
            }
            aria-label='view-tabs'
          >
            {checkPermissions(Authorities.ITEMS_SHOW_TAB_ALL) && (
              <Tab
                data-test-id='allPositionsTab'
                label='Все'
                {...a11yProps(0)}
              />
            )}
            {checkPermissions(Authorities.ITEMS_SHOW_TAB_PASSED_GT) && (
              <Tab
                data-test-id='passedPositionsTab'
                wrapped
                label='Прошедшие гранд-тендер'
                {...a11yProps(1)}
              />
            )}
            {checkPermissions(Authorities.ITEMS_SHOW_TAB_NOT_PASSED_GT) && (
              <Tab
                data-test-id='notPassedPositionsTab'
                wrapped
                label='Не прошедшие гранд-тендер'
                {...a11yProps(2)}
              />
            )}
            {checkPermissions(Authorities.ITEMS_SHOW_TAB_ARCHIVE) && (
              <Tab
                data-test-id='archivePositionsTab'
                wrapped
                label='Архив'
                {...a11yProps(3)}
              />
            )}
          </StyledTabs>
        )}
        {checkPermissions(Authorities.ITEMS_SEARCH_AND_FILTER_ITEMS) && (
          <AppHeaderSearchAndFilter
            openDrawer={openDrawer}
            shouldResetFilter={shouldResetFilter}
            filterFields={filterFields}
            flagBackPage={flagBackPage}
            flagBackPageHandler={flagBackPageHandler}
            getFilterFields={(): void =>
              getFilterFields(activeTabIndex.positions === 1)
            }
            showDrawerHandler={showDrawerHandler}
            getSearchOtherPageFilterItems={(
              params: TSearchRequest,
              callback: () => void,
            ): void => {
              if (activeTabIndex.positions === 1) {
                params.passed = true
              }
              getSearchOtherPageFilterItems(params, callback)
            }}
            refreshCategoryId={refreshCategoryId}
            categoryId={categoryId}
            availableColumns={availableColumns}
            currentPage={EPageType.ITEMS}
            isArchived={activeTabIndex.positions === PositionTab.Archived}
          />
        )}
      </Toolbar>
    </StyledAppBar>
  )
}
