import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import styled from 'styled-components'

export const AccordionSummaryStyled = styled(AccordionSummary)`
  &&& {
    flex-direction: row-reverse;
    background: #f9f9fb;
    min-height: 0;
    padding: 0;
    & .MuiIconButton-edgeEnd {
      margin: 0;
      transform: rotate(-90deg);
    }
    & .Mui-expanded {
      transform: rotate(0);
    }
    & .MuiAccordionSummary-content {
      justify-content: space-between;
      align-items: center;
      padding-right: 12px;
      margin: 0;
    }
  }
`

export const MiuiAccordionStyled = styled(Accordion)`
  &&& {
    box-shadow: none;
    & .MuiIconButton-label {
      color: #4c5ecf;
    }
  }
`
export const Text = styled.div`
  color: #4c5ecf;
  font-size: 16px;
  font-weight: bold;
`
export const AccordionDetailsStyled = styled(AccordionDetails)`
  &&& {
    padding: 0;
    & .MuiPaper-root {
      border-radius: 0;
      box-shadow: none;
    }
  }
`
