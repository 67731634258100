import React from 'react'
import {
  DialogContentText,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { ButtonComponent } from '../Button/Button'

import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from './Style'
import * as I from './IDialogModal'

export const DialogModal: React.FC<I.OwnProps> = ({
  open,
  modalTitle,
  modalContent,
  modalButtonRightType,
  modalButtonRightText,
  modalButtonLeftType,
  modalButtonLeftText,
  handleDiscardChanges,
  handleChanges,
  handleClose = handleDiscardChanges,
  dataTestIdPrefix,
}): React.ReactElement => (
  <>
    {open && (
      <div>
        <StyledDialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <StyledDialogTitle disableTypography>
            <Typography
              data-test-id={
                dataTestIdPrefix ? `${dataTestIdPrefix}Heading` : ''
              }
              variant='h6'
            >
              {modalTitle}
            </Typography>
            <IconButton
              data-test-id={
                dataTestIdPrefix ? `${dataTestIdPrefix}CloseBtn` : ''
              }
              aria-label='close'
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <Divider />
          <StyledDialogContent>
            <DialogContentText id='alert-dialog-description'>
              {modalContent}
            </DialogContentText>
          </StyledDialogContent>
          <StyledDialogActions>
            {modalButtonLeftType && modalButtonLeftText && (
              <ButtonComponent
                data-test-id={
                  dataTestIdPrefix ? `${dataTestIdPrefix}CancelBtn` : ''
                }
                text={modalButtonLeftText}
                type={modalButtonLeftType}
                onClick={handleDiscardChanges}
              />
            )}
            {modalButtonRightText && modalButtonRightType && (
              <ButtonComponent
                data-test-id={
                  dataTestIdPrefix ? `${dataTestIdPrefix}SubmitBtn` : ''
                }
                text={modalButtonRightText}
                type={modalButtonRightType}
                onClick={handleChanges}
              />
            )}
          </StyledDialogActions>
        </StyledDialog>
      </div>
    )}
  </>
)
