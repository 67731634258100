import { Dialog, Popper } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'

export const Root = styled(Dialog)`
  margin: 0;
  padding: 8px;
  & .MuiDialog-paperWidthSm {
    max-width: 645px;
  }
`
export const Form = styled.div`
  width: 600px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  & button {
    margin: 0 5px;
  }
`
export const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  & .MuiFormHelperText-root {
    color: red;
  }
` as typeof Autocomplete
export const AutocompletePopper = styled(Popper)`
  & .MuiAutocomplete-listbox {
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }
  }
`
