import styled from 'styled-components'
import { List, ListItem, ListSubheader, Divider } from '@material-ui/core'

export const StyledList = styled(List)`
  && {
    width: 100%;
    margin: 22px 0 0 0;
    min-width: 270;
    background: #f9f9fb;
    min-height: 180px;
    border-radius: 5px;
    & .MuiListSubheader-gutters {
      padding-right: 0;
    }
  }
` as typeof List
export const ListTitle = styled(ListSubheader)`
  && {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    color: #616284;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    width: inherit;
    max-width: inherit;
  }
` as typeof ListSubheader
export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .MuiButtonBase-root {
    padding: 0;
    margin: 0 15px;
  }
  & .MuiSvgIcon-root {
    cursor: pointer;
    color: white;
    font-size: 15px;
  }
  & .MuiDivider-root {
    height: 44px;
  }
`
export const DividedHeight = styled(Divider)`
  && {
    height: 1.5px;
    margin: 0 0 20px 0;
  }
`
export const Catalog = styled.div`
  padding: 0 15px;
  margin-top: -10px;
  max-height: calc(100vh - 185px);
  overflow-y: auto;
  & .MuiTypography-root {
    word-break: break-word;
    width: 240px;
    padding: 0 5px 0 0;
  }
  &::-webkit-scrollbar {
    width: 8px !important;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  & .Mui-selected {
    border-radius: 5px !important;
    background-color: #f2f0f8 !important;
  }
`
export const StyledListItem = styled(ListItem)`
  display: flex;
  height: auto;
  min-height: 45px;
  &:hover,
  &:active,
  &:focus {
    background-color: #f2f0f8;
    border-radius: 5px;
    height: auto;
    color: #4c5ecf;
  }
  & .MuiListItemText-primary {
    font-size: 14px;
    color: #05082e;
    max-width: 270px;
  }
`
export const ListItemInCollapse = styled.div`
  padding-left: theme.spacing(4.5);
`
