import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const Title = styled.div`
  margin: 0 5px 10px;
  width: 100%;
`
export const Header = styled(Typography)`
  && {
    font-size: 24px;
    line-height: 29px;
    font-family: 'Proxima Nova Regular';
    color: #1b2b4b;
    word-break: break-all;
  }
` as typeof Typography

export const Content = styled(Typography)`
  && {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    font-family: 'Proxima Nova Regular';
    color: #535f77;
    margin-top: 5px;
    word-break: break-all;
  }
` as typeof Typography
