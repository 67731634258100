import { Dialog } from '@material-ui/core'
import styled from 'styled-components'

export const Root = styled(Dialog)`
  margin: 0;
  & .MuiDialog-paperWidthSm {
    max-width: 645px;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  & button {
    margin: 0 5px;
  }
`
