import { Button as ButtonInit } from '@gmini/ui-kit'
import styled from 'styled-components'

type ButtonProps = {
  hide?: boolean
}
export const Button = styled(ButtonInit)`
  ${(props: ButtonProps) => (props.hide ? 'display: none !important;' : '')}
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const DeleteButton = styled(ButtonInit)`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  height: 24px !important;
  background: #4c5ecf !important;
`

export const CrossIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;

  & svg path {
    stroke: #fff;
    opacity: 0.5;
  }
  &:hover {
    & svg path {
      stroke: #fff;
      opacity: 1;
    }
  }
`
