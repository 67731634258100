import styled from 'styled-components'
import { Drawer, List, Divider } from '@material-ui/core'

export const SearchFilterContainer = styled.div`
  display: flex;
  align-items: center;
`
export const DrawerList = styled(Drawer)`
  background: rgba(44, 52, 98, 0.22);
  & .MuiList-root:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  & .MuiButton-label {
    font-weight: normal;
  }
  & .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0);
  }
  & .MuiPaper-root {
    width: 634px;
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`
export const DrawerContentDivider = styled(Divider)`
  margin: 0 0 0 24px;
  padding-top: 0;
  background-color: #e2e4e9;
`
export const DrawerListHeader = styled(List)`
  padding: 0;
  & h5 {
    font-size: 24px;
    color: #000;
    font-weight: normal;
    margin: 0;
    cursor: default;
  }
  & .MuiList-padding {
    min-width: 334px;
  }
  & .MuiList-root {
    position: fixed;
  }
`
export const DrawerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  box-shadow: 0px -5px 30px 0px rgba(50, 50, 50, 0.05);
  margin: 0 15px 0 0;
  height: 68px;
  position: sticky;
  right: 0;
  bottom: 0;
  & button {
    margin: 0 0 0 10px;
  }
`
export const FilterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5px;
  & .MuiButtonBase-root:first-child {
    margin: 0;
    border-radius: 5px 0 0 5px;
    display: flex;
    align-items: center;
    & .MuiSvgIcon-root {
      margin: 0 5px 0 0;
    }
  }
  & .MuiButtonBase-root:last-child {
    margin: 0;
    border-radius: 0 5px 5px 0;
    border: 1px solid #4c5ecf;
    & .MuiSvgIcon-root {
      margin: 0;
    }
  }
`
