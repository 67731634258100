import styled from 'styled-components'
import { Checkbox, TableCell, IconButton } from '@material-ui/core'

export const CheckedRow = styled(Checkbox)`
  && {
    margin: 0 0 0 1px;
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0);
    }
    & .MuiTableCell-root {
      border: 0;
    }
  }
`
export const Empty = styled.div`
  width: 15px;
  height: 42px;
`
export const ActionsContainer = styled.div`
  display: flex;
  float: right;
  justify-content: center;
  margin-right: 6px;
`
export const ActionButton = styled(IconButton)`
  && {
    flex: 1 1 20px;
    padding: 14px;
    width: 20px;
    height: 20px;
    margin: 0 -3px;
    & .MuiSvgIcon-root {
      font-size: 18px;
    }
  }
`
export const FlexContainer = styled(TableCell)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: none;
  }
`
