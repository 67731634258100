import { Popper, TableCell } from '@material-ui/core'
import styled from 'styled-components'

export const StyledPopper = styled(Popper)`
  && {
    pointer-events: auto;
    & .MuiTooltip-tooltip {
      background-color: white;
      border: 1px solid gray;
    }
  }
`

export const StyledTableCell = styled(TableCell)`
  && {
    padding: 7px;
  }
`
