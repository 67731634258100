import styled from 'styled-components'
import { List, Popover } from '@material-ui/core'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
`
export const Catalog = styled(Popover)`
  & .MuiPaper-root {
    max-height: 350px;
    height: 350px;
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`
