import { Paper, TableContainer } from '@material-ui/core'
import styled from 'styled-components'

export const Root = styled(Paper)`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    & .MuiTableCell-sizeSmall {
      padding: 8px;
      &:nth-child(even):not(:last-child) {
        background: #fafafa;
      }
    }
    & .MuiTableRow-root.error {
      & .MuiTableCell-sizeSmall:nth-child(even):not(:last-child) {
        background: #f3dddd;
      }
    }
  }
`
export const Container = styled(TableContainer)`
  min-width: 600px;
  flex: 1 1 auto;
  height: 100px;
  & .MuiTable-root {
    table-layout: fixed;
  }
`
