/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useRef, useEffect, useState } from 'react'

import { toastr } from 'react-redux-toastr'

import { Authorities } from '../../services/auth-service/auth-constants'
import { PositionService } from '../../services/position-service'
import {
  IImprovedTableToolbar,
  TEditModalInitialState,
  TRowData,
  TSort,
} from '../../types'

import {
  EAPIResponseStatus,
  EColumnType,
  EEditableModalType,
} from '../../constants'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalTitle } from '../BaseModalAddContextPositions/ModalTitle/ModalTitle'
import { EditableModal } from '../EditableModal/EditableModal'
import { ImprovedTable } from '../ImprovedTable/ImprovedTable'
import { Spinner } from '../Spinner/Spinner'

import * as I from './IAddPositionsContextModal'
import { LoaderBackground, Root } from './Style'

export const AddPositionsContextModal: React.FC<I.OwnProps> = ({
  positions,
  openModal,
  lotId,
  author,
  onClose,
  addPosition,
  getPositions,
  addToAssignment,
  callbackAssignmentView,
  onGetCheckedCategories,
  authRoleChecker,
}): React.ReactElement => {
  const title = {
    insert: 'Добавить позицию',
    edit: 'Редактировать позицию',
  }

  const [categoryId, setCategory] = useState('')
  const [shouldGetData, setShouldGetData] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)

  const modalInitialState = useRef<TEditModalInitialState>({
    settings: [],
    files: {
      current: [],
      add: [],
      remove: [],
    },
    row: {
      rowId: '',
      photo: '',
      data: [],
    },
  })

  const onSelectNodeHandler = (nodeId: string): void => {
    setShouldGetData(true)
    setCategory(nodeId)
  }

  const toolbarProps: IImprovedTableToolbar = {
    showAddBtn:
      authRoleChecker &&
      authRoleChecker(Authorities.ITEMS_CREATE_ITEM) &&
      !!categoryId,
    showMoveToLotsBtn:
      authRoleChecker &&
      authRoleChecker(Authorities.ASSIGNMENT_DETAILS_ADD_SUPPLIER_TO_LOT),
    addBtnText: 'Добавить позицию',
    onAddBtnClick: () => {
      const data = positions.columns.map(d => ({
        key: d.key,
        value: d.type === EColumnType.BOOLEAN ? 'false' : '',
      }))

      modalInitialState.current = {
        files: {
          current: [],
          add: [],
          remove: [],
        },
        row: {
          rowId: '',
          photo: '',
          data,
        },
        settings: positions.columns,
      }
      setOpen(!open)
    },
    onMoveToLotsBtnClick: (selectedRows: Array<string>): void => {
      addToAssignment(selectedRows, lotId, callbackAssignmentView)
    },
    onSelectNode: (nodeId: string) => {
      onSelectNodeHandler(nodeId)
    },
    onGetCheckedCategories,
    authRoleChecker,
  }

  useEffect(() => {
    if (shouldGetData && categoryId) {
      getPositions(
        categoryId,
        1,
        positions.pagination.rowsPerPage || 10,
        0,
        'asc',
        () => setShouldGetData(false),
      )
    }
  }, [categoryId, shouldGetData])

  const handleChangePage = (
    event: unknown,
    newPage: number,
    sortColumnKey: number,
    sortColumnDirection: TSort,
  ): void => {
    getPositions(
      categoryId,
      newPage + 1,
      positions.pagination.rowsPerPage || 10,
      sortColumnKey,
      sortColumnDirection,
      () => setShouldGetData(false),
    )
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    getPositions(
      categoryId,
      1,
      Number(event.target.value || 10),
      0,
      'asc',
      () => setShouldGetData(false),
    )
  }

  const savePositionHandler = (): void => {
    const item: TRowData = [...modalInitialState.current.row.data]
    addPosition(
      item,
      categoryId,
      modalInitialState.current.files?.add.map(i => i.file) || [],
      () => setOpen(false),
    )
  }

  const getParamsSelectAllRequest = async (
    callback: (ids: Array<string>) => void,
  ): Promise<void> => {
    const [err, result] = await PositionService.getPositionsSelectAll(
      categoryId,
    )

    if (err) {
      toastr.error('', 'При отправке запроса произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })
    }

    if (result.data.status === EAPIResponseStatus.SUCCESS && result.data.data) {
      callback(result.data.data)
    }
  }

  const drawerSideBarProps = {
    onEditBtnClick: (): void => {},
  }

  return (
    <div>
      <Root
        onClose={onClose}
        aria-labelledby='customized-dialog-title'
        open={openModal}
        disableBackdropClick={true}
        style={
          open
            ? { opacity: '0', transition: '0.5s' }
            : { opacity: '1', transition: '0.5s' }
        }
      >
        <ModalTitle
          dataTestIdPrefix='addPositionsContextModal'
          id='customized-dialog-title'
          onClose={onClose}
        >
          Добавление позиций
        </ModalTitle>
        <ModalContent width={'80vw'} dividers>
          {open && (
            <EditableModal
              open={open}
              author={author}
              initialState={modalInitialState}
              type={EEditableModalType.INSERT}
              title={title}
              dataIsLoading={false}
              onClose={(): void => setOpen(false)}
              onSave={savePositionHandler}
              path='positions'
            />
          )}
          {(categoryId || openModal) && (
            <ImprovedTable
              data={positions.data}
              columns={positions.columns.map(column => ({
                ...column,
                title:
                  column.title.lastIndexOf('[') === -1
                    ? column.title
                    : column.title.substring(0, column.title.lastIndexOf('[')),
              }))}
              pagination={positions.pagination}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              getParamsSelectAllRequest={getParamsSelectAllRequest}
              localStorageTableId='add-position-modal'
              dataTestIdPrefix='addPositionsContextModal'
              {...toolbarProps}
              {...drawerSideBarProps}
            />
          )}
          {!(categoryId || openModal) && (
            <LoaderBackground>
              <Spinner />
            </LoaderBackground>
          )}
        </ModalContent>
      </Root>
    </div>
  )
}
