import React from 'react'
import { Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { DialogTitleProps } from './IModalTitle'
import { CloseButton, Root } from './Style'

export const ModalTitle = (props: DialogTitleProps) => {
  const { children, onClose, dataTestIdPrefix, ...other } = props
  return (
    <Root disableTypography {...other}>
      <Typography data-test-id={`${dataTestIdPrefix}Heading`} variant='h6'>
        {children}
      </Typography>
      {onClose ? (
        <CloseButton
          data-test-id={`${dataTestIdPrefix}CloseBtn`}
          aria-label='close'
          onClick={onClose}
        >
          <CloseIcon />
        </CloseButton>
      ) : null}
    </Root>
  )
}
