import React from 'react'

import { TabPanelProps } from '../../types'

import { Root } from './Styles'

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  index,
  ...other
}): React.ReactElement => (
  <Root
    role='tabpanel'
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {children}
  </Root>
)
