import styled from 'styled-components'
import { IconButton, List, Drawer } from '@material-ui/core'

import { LoaderPL } from '../Loader/LoaderPL'
export const StyledLoaderPL = styled(LoaderPL)`
  && {
    position: relative;
  }
`

export const DrawerList = styled(Drawer)`
  && {
    & .MuiList-root:last-child {
      padding: 8px 24px 7px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 59px;
      box-shadow: 0px -5px 30px 0px rgba(50, 50, 50, 0.05);
    }
    & .MuiButton-label {
      font-weight: normal;
    }
    & .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0);
    }
    & > .MuiPaper-root {
      width: 660px;
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`
export const DrawerListHeader = styled(List)`
  && {
    padding: 20px 20px 5px 35px;
    display: flex;
    flex-direction: column;
    & .MuiList-padding {
      max-width: 625px;
    }
  }
`
export const DrawerListHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  & h5 {
    font-size: 24px;
    color: #000;
    font-weight: normal;
    word-wrap: break-word;
    max-width: 550px;
    padding: 0;
    margin: 0;
  }
`
export const DrawerListHeaderHash = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 15px 15px 0 0;
  & p {
    margin: 0 5px 0 0;
    padding: 0;
  }
`
export const DrawerListHeaderCloseBtn = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
  }
`
export const DrawerContent = styled(List)`
  && {
    min-height: calc(100vh - 160px);
    & hr {
      margin: 0 0 0 34px;
    }
    & .image-gallery-icon {
      z-index: 0;
      margin: 0;
      padding: 0;
      filter: drop-shadow(0 1px 1px #1a1a1a);
    }
    & .image-gallery-swipe {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .image-gallery-image {
      border: 1px solid #e2e4e9;
      max-width: 255px;
      min-width: 255px;
      min-height: 255px;
      max-height: 255px;
    }
    & .image-gallery-slides {
      max-width: 255px;
      min-width: 255px;
      min-height: 255px;
      max-height: 255px;
      display: flex;
      align-items: center;
    }
    & .image-gallery-thumbnail-inner {
      position: relative;
      max-width: 75px;
      max-height: 75px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    & .image-gallery-thumbnail {
      border: 1px solid #e2e4e9;
      max-width: 75px;
      min-height: 75px;
      cursor: pointer;
      margin: 7.5px 0;
    }
    & .image-gallery-thumbnail-image {
      max-width: 74px;
      max-height: 74px;
    }
    & .image-gallery-thumbnail:focus {
      border: 1px solid #e2e4e9;
    }
    & .image-gallery-thumbnail.active {
      border: 2px solid #807e7e;
    }
    & .image-gallery-thumbnails {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    & .image-gallery-content.fullscreen {
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 34px 20px 20px;
      background: #fff;
    }
    & .image-gallery-slide-wrapper {
      margin: 0 100px 0 0;
    }
    & .image-gallery-slide-wrapper.left,
    .image-gallery-slide-wrapper.right {
      width: calc(100% - 215px);
    }
    & .fullscreen .image-gallery-slides {
      max-width: 100%;
      max-height: 100%;
      margin: 0 60px;
    }
    & .fullscreen .image-gallery-image {
      max-width: 100%;
    }
    & .fullscreen .image-gallery-thumbnails-wrapper {
      min-height: 100vh;
    }
    & .fullscreen .image-gallery-thumbnails {
      display: flex;
      align-items: center;
    }
    & .fullscreen .image-gallery-icon {
      z-index: 1;
    }
    & .image-gallery:not(:root):fullscreen::backdrop {
      background: rgba(0, 0, 0, 0);
    }
    & .image-gallery-content.left .image-gallery-slide .image-gallery-image,
    .image-gallery-content.right .image-gallery-slide .image-gallery-image {
      max-height: calc(100vh - 200px);
    }
    & :focus {
      outline: none;
      border: none;
    }
  }
`
export const DrawerFooter = styled(List)`
  && {
    position: sticky;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
  }
`
export const CardRowContainer = styled.div`
  margin: 0 24px 20px 34px;
`
export const SecondPartFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & button {
    margin: 0 0 0 10px;
  }
`
export const CardRowTitle = styled.h4`
  color: #4c5ecf;
`
export const CardRowBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -10px;
  & .MuiFormControl-root {
    margin: 0 10px;
    max-width: 280px;
  }
  & .MuiInputBase-input {
    line-height: 25px;
  }
  & .MuiInputBase-root {
    margin-top: 0;
  }

  & label {
    margin-left: 0;
    position: relative;
  }
`
