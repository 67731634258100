import React, { useEffect, useState } from 'react'
import { MenuItem, IconButton } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

import {
  ButtonContainer,
  StyledSelect,
} from '../../../../pages/position/CustomPagination/CustomPagination.styled'
import { Pagination as PaginationType } from '../../../../actions/EventsLog'

import { Pagination, StyledIconButton } from './EventsListPagination.styled'

type EventsListPaginationProps = {
  perPageOptions: number[]
  fetchList: (props: PaginationType) => void
  total: number
  /** Количество соседних отображаемых номеров страниц */
  range?: number
}

export const EventsListPagination = ({
  perPageOptions,
  fetchList,
  total,
  range = 3,
}: EventsListPaginationProps) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(perPageOptions[0])

  useEffect(() => {
    fetchList({ page, perPage })
  }, [page, perPage, fetchList])

  return (
    <Pagination>
      Строк на странице{' '}
      <StyledSelect
        disableUnderline
        value={perPage}
        onChange={e => {
          const maxPage = Math.ceil(total / Number(e.target.value))
          if (page <= maxPage) {
            setPerPage(Number(e.target.value))
          } else {
            setPerPage(Number(e.target.value))
            setPage(maxPage)
          }
        }}
      >
        {perPageOptions.map(v => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </StyledSelect>{' '}
      {(page - 1) * perPage + 1}-
      {page * perPage < total ? page * perPage : total} из {total}
      <ButtonContainer>
        <IconButton
          disabled={page === 1}
          onClick={() => setPage(prev => prev - 1)}
        >
          <KeyboardArrowLeft />
        </IconButton>
        {Array(Math.ceil(total / perPage))
          .fill(null)
          .map((_, i) => {
            const pagesCount = Math.ceil(total / perPage)
            const pageNumber = i + 1
            const pageBtn = (
              <StyledIconButton
                disabled={pageNumber === page}
                key={`${pageNumber}page`}
                onClick={() => setPage(pageNumber)}
              >
                {pageNumber}
              </StyledIconButton>
            )

            if (pagesCount <= 10) {
              return pageBtn
            }

            if (pageNumber === 1) {
              return (
                <>
                  {pageBtn}
                  {page > pageNumber + (range + 1) ? ' ... ' : null}
                </>
              )
            }

            if (pageNumber === pagesCount) {
              return (
                <>
                  {page < pageNumber - (range + 1) ? ' ... ' : null}
                  {pageBtn}
                </>
              )
            }
            return pageNumber > page + range || pageNumber < page - range
              ? null
              : pageBtn
          })}
        <IconButton
          disabled={page * perPage >= total}
          onClick={() => setPage(prev => prev + 1)}
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonContainer>
    </Pagination>
  )
}
