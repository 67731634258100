import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

export const Root = styled(Dialog)`
  margin: 0;
  & .MuiDialog-paperWidthSm {
    max-width: 645px;
  }
  & .MuiSwitch-root {
    align-self: center;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  & button {
    margin: 0 5px;
  }
`
export const StyledAutocomplete = styled(Autocomplete)`
  display: flex;
  align-items: center;
  margin: 10px 0;
  & .MuiInputBase-input {
    font-size: 14px;
    text-indent: 15px;
    display: flex;
    align-content: center;
    background: #f9f9fb;
    border-radius: 5px;
    height: 53px;
    cursor: pointer;
    &:active,
    &:focus,
    &:hover {
      background: #f4f6f9;
    }
  }
  &
    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding: 0;
  }
  &
    .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
    padding: 15px;
  }
  & .MuiFormHelperText-root {
    margin: 5px 10px 0;
    background: rgba(0, 0, 0, 0);
  }
  & .MuiAutocomplete-root {
    transition: 0.3s width ease-in-out;
    color: inherit;
  }
  &.MuiAutocomplete-root {
    display: flex;
    align-items: center;
  }
  &.Mui-focused {
    cursor: text;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
    outline: none;
  }
  & .MuiAutocomplete-listbox {
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }
  }
  & .MuiAutocomplete-popupIndicator {
    display: none;
  }
  & .MuiFormControl-fullWidth {
    width: 590px;
    height: auto;
  }
` as typeof Autocomplete
