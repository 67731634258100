import styled, { css } from 'styled-components'

type RootProps = {
  width?: string
  dividers?: boolean
}

export const ModalContent = styled.div`
  width: ${({ width }: RootProps) => (width ? width : '100%')};
  padding: 25px;
  font-family: 'Proxima Nova Regular';
  ${({ dividers }: RootProps) =>
    dividers
      ? css`
          border-top: 1px solid rgba(0, 0, 0, 0.12);
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        `
      : ''}
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 8px !important;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  & .MuiFilledInput-root,
  .MuiFilledInput-root:focus {
    background-color: #f9f9fb;
  }
  & .MuiFilledInput-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  & .fullWidth {
    width: 100%;
    & .MuiInputBase-root {
      font-size: 20px;
      & .MuiTypography-root {
        font-size: 20px;
      }
    }
    & .MuiFilledInput-root {
      & .MuiInputBase-input {
        font-size: 20px;
      }
    }
  }
  & .MuiFilledInput-root:before,
  & .MuiFilledInput-root:after {
    content: none;
  }
  & .MuiInputBase-root {
    font-size: 14px;
    & .MuiTypography-root {
      font-size: 14px;
    }
  }
  & .MuiFilledInput-root {
    & .MuiInputBase-input {
      padding: 15px;
      font-size: 14px;
      line-height: 17px;
      color: #1b2b4b;
      font-family: 'Proxima Nova Regular';
    }
  }
  & .MuiFormControlLabel-root {
    margin: 10px 0;
  }
  & .MuiFormControlLabel-label {
    align-self: flex-start;
    font-size: 14px;
    font-family: 'Proxima Nova Light';
    margin-bottom: 15px;
    color: #1b2b4b;
    height: 17px;
    line-height: 17px;
  }
  & .MuiFormControl-root:not(.MuiFormControl-fullWidth) {
    margin: 10px 0;
  }
  & .MuiDivider-root {
    margin: 15px 0;
  }
  & h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4c5ecf;
    margin: 20px 0;
  }
  & .propContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  & .prop {
    max-width: 279px;
    min-width: 279px;
    margin: 10px 10px;
    justify-content: flex-end;
    & .MuiFormControl-root {
      max-width: 279px;
      min-width: 279px;
    }
  }
  & .prop.listProp {
    margin: 10px 10px;
    & .MuiFormControlLabel-root {
      margin: 0 0 10px 0;
    }
  }
`
