import styled from 'styled-components'

import { List, ListSubheader } from '@material-ui/core'

export const Root = styled(List)`
  && {
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: 350px;
    overflow: auto;
  }
`

export const ListSection = styled.li`
  background-color: #fff;
  width: 100%;
`

export const Ul = styled.ul`
  background-color: inherit;
  padding: 0;
`

export const StyledListSubheader = styled(ListSubheader)`
  && {
    width: 100%;
  }
`
