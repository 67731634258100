import styled from 'styled-components'
import { Accordion } from '@ifellow/ui-library'

export const SupplierHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  gap: 5px;
`
export const SupplierNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
`
export const SupplierName = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  text-align: right;
`
export const TableWrapper = styled.div`
  max-width: 100%;
  overflow: auto;
  & .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;
  }
  & .MuiDataGrid-root .MuiDataGrid-cell {
    position: relative;
  }
`
export const AccordionActions = styled.div`
  height: 28px;
  & .MuiCheckbox-root {
    & .MuiIconButton-label {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  & .MuiCheckbox-root.Mui-checked {
    & .MuiIconButton-label {
      color: #3f51b5;
    }
  }
  & .MuiCheckbox-root.Mui-disabled {
    & .MuiIconButton-label {
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }
`
export const StyledAccordion = styled(Accordion)`
  & .MuiAccordion-root.Mui-expanded:last-child {
    margin: 0;
  }
`
