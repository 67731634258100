import React from 'react'
import { CircularProgress } from '@material-ui/core'

import { StyledBackdrop } from './styles'

const SpinnedBackdrop = () => (
  <StyledBackdrop open={true}>
    <CircularProgress color='inherit' />
  </StyledBackdrop>
)

export { SpinnedBackdrop }
