import React, { useState } from 'react'

import { toastr } from 'react-redux-toastr'

import { Authorities } from '../../../services/auth-service/auth-constants'

import { ButtonComponent } from '../../Button/Button'

import { IImprovedTableToolbar } from '../../../types'
import { EButtonType, EButtonIcon } from '../../../constants'

import { PositionCatalogMini } from '../../PositionCatalogMini/PositionCatalogMini'

import {
  ButtonContainer,
  FilterButtonContainerList,
  MenuCatalog,
  Root,
  Title,
} from './Styles'

const emptyFunc = (): number => 0

export const ImprovedTableToolbar: React.FC<IImprovedTableToolbar> = ({
  selectedRowsCount = 0,
  showAddBtn = false,
  showMoveToLotsBtn = false,
  addBtnText = '',
  TitleComponent,
  onAddBtnClick = emptyFunc,
  onMoveToLotsBtnClick = emptyFunc,
  onSelectNode = emptyFunc,
  onGetCheckedCategories,
  authRoleChecker,
  isDeepTreeLevel,
  dataTestIdPrefix,
}: IImprovedTableToolbar): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <Root disableGutters={true} highlight={selectedRowsCount > 0}>
      {selectedRowsCount > 0 ? (
        <Title variant='subtitle1' component='div'>
          {selectedRowsCount} Выбрано
        </Title>
      ) : (
        <Title variant='subtitle2' id='tableTitle' component='div'>
          {TitleComponent}
          <ButtonContainer>
            <FilterButtonContainerList>
              <ButtonComponent
                text='Каталог'
                data-test-id={`${dataTestIdPrefix}OpenCatalogBtn`}
                type={EButtonType.PRIMARY}
                typeIcon={EButtonIcon.ARROW_UP}
                flagCatalog={true}
                onClick={
                  authRoleChecker && authRoleChecker(Authorities.ITEMS_PAGE)
                    ? handleClick
                    : (): void => toastr.error('', 'Доступ запрещен')
                }
              />
              <MenuCatalog
                anchorEl={anchorEl}
                id={'simple-popover'}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <PositionCatalogMini
                  anchorEl={anchorEl}
                  onSelectNode={onSelectNode}
                  onGetCheckedCategories={onGetCheckedCategories}
                  dataTestIdPrefix={dataTestIdPrefix}
                />
              </MenuCatalog>
            </FilterButtonContainerList>
            {isDeepTreeLevel && (
              <>
                <ButtonComponent
                  data-test-id={`${dataTestIdPrefix}AddPositionBtn`}
                  text={addBtnText}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.ADD}
                  hidden={!showAddBtn}
                  onClick={onAddBtnClick}
                />
              </>
            )}
          </ButtonContainer>
        </Title>
      )}
      {selectedRowsCount > 0 && (
        <ButtonContainer>
          <ButtonComponent
            data-test-id={`${dataTestIdPrefix}AddToLotBtn`}
            text='Добавить в лот'
            type={EButtonType.PRIMARY}
            typeIcon={EButtonIcon.ADD}
            hidden={showMoveToLotsBtn}
            onClick={onMoveToLotsBtnClick}
          />
        </ButtonContainer>
      )}
    </Root>
  )
}
