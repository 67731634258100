/* eslint-disable require-unicode-regexp */
/* eslint-disable no-undefined */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import {
  FormControlLabel,
  TextField,
  Switch,
  InputAdornment,
  Divider,
} from '@material-ui/core'
import InputMask from 'react-input-mask'
import * as _ from 'lodash'

import moment from 'moment'

import { debounceTime } from 'rxjs/operators'

import { BehaviorSubject, Subscription } from 'rxjs'

import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'

import { isNotEmpty } from '@gmini/utils'

import { AssignmentService } from '../../services/assignments-service'

import {
  TAgreementCreateRequest,
  IColumn,
  IKeyValuePair,
  Project,
  TObject,
} from '../../types'

import {
  EAPIResponseStatus,
  EButtonType,
  EColumnType,
  REVERSE_DISPLAY_DATE_FORMAT,
} from '../../constants'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'

import { ButtonComponent } from '../Button/Button'

import { SelectProjectsAndObjects } from '../SelectProjectsAndObjects/SelectProjectsAndObjects'

import * as I from './IAddAgreementModal'
import { ButtonContainer, DrawerAutocomplete, Root } from './Style'

const adaptProjectObjectsData = (
  projectObjectsData: {
    project: string
    objects: { id: number; hubName: string }[]
  }[],
) => {
  if (projectObjectsData.find(p => p.project === '0')) {
    return {}
  }

  return projectObjectsData.reduce(
    (acc, p) =>
      p.objects.map(o => o.id).includes(0)
        ? { ...acc, [p.project]: [] }
        : { ...acc, [p.project]: p.objects },
    {},
  )
}

export const AddAgreementModal: React.FC<I.OwnProps> = ({
  open,
  model,
  onClose,
  onSave,
  flagAgreementListHandled,
  allProjectsAndObjects,
  hasAgreements,
  refreshProjectList,
}): React.ReactElement => {
  const [projectsAndObjects, setProjectsAndObjects] = useState<
    ({ project: string; objects: { id: number; hubName: string }[] } | null)[]
  >([null])

  const [state, setState] = useState<TAgreementCreateRequest>({
    providerId: Number(model.supplier.key),
    number: model.number,
    dateEnd: '',
    dateStart: '',
    lotId: model.lotId,
    categoryId: '',
    projectObjects: {},
    data: model.columns.map(c => ({
      key: c.key,
      value: c.type === EColumnType.BOOLEAN ? 'false' : '',
    })),
  })

  useEffect(() => {
    if (model.supplier.key === '') {
      return
    }
    if (model.columns === null) {
      setState({
        ...state,
        number: model.number,
        providerId: Number(model.supplier.key),
        lotId: model.lotId,
      })
    }
    if (model.columns !== null) {
      setState({
        ...state,
        number: model.number,
        providerId: Number(model.supplier.key),
        lotId: model.lotId,
        data: model.columns.map(c => ({
          key: c.key,
          value: c.type === EColumnType.BOOLEAN ? 'false' : '',
        })),
      })
    }
  }, [model])

  const handleProjectChange = useCallback(
    (_, option: Project | null, idx: number) => {
      if (option?.urn === '0') {
        setProjectsAndObjects([
          { project: '0', objects: [{ id: 0, hubName: '' }] },
        ])
        return
      }
      setProjectsAndObjects(s =>
        s.map((item, i) => {
          if (i === idx) {
            return option ? { project: option.urn, objects: [] } : null
          }
          return item
        }),
      )
    },
    [state],
  )
  const handleObjectChange = useCallback(
    (_, objects: TObject[], idx: number) => {
      const newObjects = objects.map(o => ({ id: o.id, hubName: o.hubName }))
      if (newObjects.find(o => o.id === 0)) {
        setProjectsAndObjects(s =>
          s.map((item, i) => {
            if (i === idx) {
              return item
                ? { ...item, objects: [{ id: 0, hubName: '' }] }
                : null
            }
            return item
          }),
        )
        return
      }
      setProjectsAndObjects(s =>
        s.map((item, i) => {
          if (i === idx) {
            return item ? { ...item, objects: newObjects } : null
          }
          return item
        }),
      )
    },
    [state],
  )

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    dateEnd: '',
    dateStart: '',
    ..._.reduce(state.data, (acc, { key }) => ({ ...acc, [key]: '' }), {}),
  })
  const [searchValues, setSearchValues] = useState<Array<IKeyValuePair>>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [valueCatalog, setValueCatalog] = useState<{
    key: string
    value: string
  }>({
    key: '',
    value: '',
  })

  const [optionsListOpen, setOptionsListOpen] = React.useState(false)
  const [onSearch$] = useState(() => new BehaviorSubject<string>(''))
  const [optionsListLoading, setOptionsListLoading] = useState<boolean>(false)

  useEffect(() => {
    const subscriptions: Array<Subscription> = new Array<Subscription>()

    subscriptions.push(
      onSearch$.pipe(debounceTime(400)).subscribe(async (debounced: string) => {
        setOptionsListLoading(true)
        const [, result] = await AssignmentService.getArgeementCatalog(
          debounced,
        )
        if (result.data.status === EAPIResponseStatus.SUCCESS) {
          const sv: Array<IKeyValuePair> | undefined = result.data.data
          if (sv && sv.length > 0) {
            const arr: Array<IKeyValuePair> = []
            sv.map(d => arr.push({ key: d.key, value: d.value }))
            setSearchValues(arr)
          }
        }
        setOptionsListLoading(false)
        //TODO handle error
      }),
    )

    return (): void => {
      if (subscriptions.length) {
        subscriptions.forEach(s => s.unsubscribe())
      }
    }
  }, [])

  useEffect(() => {
    if (valueCatalog.value && valueCatalog.value.length > 0) {
      flagAgreementListHandled(true, valueCatalog.key)
    }
  }, [valueCatalog])

  const handleRequiredDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    let err = ''
    const { value, name } = event.target
    const diffDuration = moment.duration(moment(value).diff(new Date()))

    if (!value || !value.trim()) {
      err = 'Обязательное поле'
    } else if (validateDateProp(value)) {
      err = 'Неверная дата'
    } else if (name === 'dateEnd' && diffDuration.days() < 0) {
      err = 'Выбранная дата уже прошла'
    }

    if (
      name === 'dateStart' &&
      state.dateEnd.trim() !== '' &&
      moment(value).isAfter(state.dateEnd)
    ) {
      setErrors({ ...errors, [name]: err })
      setState({ ...state, [name]: value, dateEnd: value })
      return
    }

    setErrors({ ...errors, [name]: err })
    setState({ ...state, [name]: value })
  }

  const handleBooleanPropChanged = (key: string): void => {
    const newState = _.cloneDeep(state)
    const item = newState.data.find(prop => prop.key === key)

    if (item) {
      item.value = (item.value !== 'true').toString()
      setState(newState)
    }
  }

  const handleStringPropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value)

    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event.target.value
      setState(newState)
    }
  }

  const handleIntegerPropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value)

    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event.target.value && event.target.value.trim()
      setState(newState)
    }
  }

  const handleDatePropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value)

    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event.target.value && event.target.value.trim()
      item.value = moment(event.target.value).format('YYYY-MM-DDTHH:mm')
      setState(newState)
    }
  }

  const handlePhonePropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value)
    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value =
        event.target.value === '+7 (***) ***-**-**' ? '' : event.target.value
      setState(newState)
    }
  }

  const validatePhoneProp = (value: string): boolean => {
    const starsCnt = (value.match(/[*]/g) || []).length
    return starsCnt > 0 && starsCnt < 10
  }

  const validateRequiredProp = (value: string): boolean =>
    !value || !value.trim()

  const validateFloatProp = (value: string): boolean => {
    if (!value) {
      return false
    }
    return isNaN(parseFloat(value)) || parseFloat(value) < 0
  }

  const validateDateProp = (value: string): boolean => {
    if (!value) {
      return false
    }
    return !moment(
      value,
      [
        'YYYY-MM-DDTHH:mm:ss.msZ',
        'YYYY-MM-DDTHH:mm:ssZ',
        'YYYY-MM-DDTHH:mm',
        REVERSE_DISPLAY_DATE_FORMAT,
      ],
      true,
    ).isValid()
  }

  const validateBooleanProp = (value: string): boolean => {
    if (!value) {
      return false
    }
    return ['true', 'false'].indexOf(value) === -1
  }

  const validate = (prop: IColumn, value: string): string => {
    const msg =
      prop.required && validateRequiredProp(value)
        ? 'Обязательное поле'
        : prop.type === EColumnType.PHONE
        ? validatePhoneProp(value)
          ? 'Номер введён не полностью'
          : ''
        : prop.type === EColumnType.BOOLEAN
        ? validateBooleanProp(value)
          ? 'Только логическое значение'
          : ''
        : prop.type === EColumnType.DATE
        ? validateDateProp(value)
          ? 'Неверная дата'
          : ''
        : prop.type === EColumnType.DOUBLE || prop.type === EColumnType.CURRENCY
        ? validateFloatProp(value)
          ? 'Только целые или дробные числа'
          : ''
        : ''

    setErrors({ ...errors, [prop.key]: msg })
    return msg
  }

  const validateDateForm = (name: 'dateEnd' | 'dateStart') => {
    const error: { [key: string]: string } = {}

    if (!state[name] || !state[name].trim().length) {
      error[name] = 'Обязательное поле'
    } else if (validateDateProp(state[name])) {
      error[name] = 'Неверная дата'
    }
    const diffDuration = moment.duration(moment(state[name]).diff(new Date()))
    if (name === 'dateEnd' && diffDuration.days() < 0) {
      error[name] = 'Выбранная дата уже прошла'
    }

    return error
  }

  const validateForm = (): { [key: string]: string } => {
    const error: { [key: string]: string } = {}

    state.data.forEach(d => {
      const prop = model.columns.find(p => p.key === d.key)
      if (prop) {
        const msg = validate(prop, d.value)
        if (msg.length) {
          error[prop.key] = msg
        }
      }
    })

    return {
      ...error,
      ...validateDateForm('dateStart'),
      ...validateDateForm('dateEnd'),
    }
  }

  const saveBtnHandler = (): void => {
    const error = validateForm()

    if (_.keys(error).length) {
      setErrors({ ...errors, ...error })
      return
    }
    const filteredProjectsAndObjects = projectsAndObjects
      .filter(isNotEmpty)
      .filter(item => item.objects.length)
    if (!filteredProjectsAndObjects.length) {
      return
    }

    onSave({
      ...state,
      projectObjects: adaptProjectObjectsData(filteredProjectsAndObjects),
    })
  }

  const onDialogClose = (): void => {
    setErrors({})
    onClose()
  }

  const renderProperties = model.columns.map((prop, idx) => {
    switch (prop.type) {
      case EColumnType.BOOLEAN:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            control={
              <Switch
                data-test-id={`addAgreementModalPropInput_${idx}`}
                checked={
                  state.data.find(r => r.key === prop.key)?.value === 'true' ||
                  false
                }
                onChange={(): void => handleBooleanPropChanged(prop.key)}
                name={prop.key}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.STRING:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            control={
              <TextField
                type='text'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                variant='filled'
                defaultValue={
                  state.data.find(p => p.key === prop.key)?.value || ''
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleStringPropChanged(e, prop)
                }
                InputProps={{
                  endAdornment: prop.unit ? (
                    <InputAdornment position='end'>{prop.unit}</InputAdornment>
                  ) : (
                    undefined
                  ),
                  inputMode: 'text',
                }}
                inputProps={{
                  'data-test-id': `addAgreementModalPropInput_${idx}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.PHONE:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            control={
              <InputMask
                mask='+7 (999) 999-99-99'
                maskChar='*'
                alwaysShowMask={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handlePhonePropChanged(e, prop)
                }
                value={
                  state.data.find(p => p.key === prop.key)
                    ? state.data.find(p => p.key === prop.key)?.value
                    : ''
                }
              >
                {(): JSX.Element => (
                  <TextField
                    type='phone'
                    required={prop.required}
                    error={errors[prop.key]?.length > 0}
                    helperText={errors[prop.key]}
                    variant='filled'
                    inputProps={{
                      'data-test-id': `addAgreementModalPropInput_${idx}`,
                    }}
                  />
                )}
              </InputMask>
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.DOUBLE:
      case EColumnType.CURRENCY:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            control={
              <TextField
                type='number'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                variant='filled'
                onKeyDown={(event): boolean => {
                  if (
                    ![
                      '0',
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      '6',
                      '7',
                      '8',
                      '9',
                      ',',
                      '.',
                      'Backspace',
                    ].some(r => event.key === r)
                  ) {
                    event.preventDefault()
                    return false
                  }
                  return true
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleIntegerPropChanged(e, prop)
                }
                defaultValue={
                  state.data.find(p => p.key === prop.key)?.value || ''
                }
                InputProps={{
                  endAdornment: prop.unit ? (
                    <InputAdornment position='end'>{prop.unit}</InputAdornment>
                  ) : (
                    undefined
                  ),
                  inputMode: 'numeric',
                }}
                inputProps={{
                  'data-test-id': `addAgreementModalPropInput_${idx}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.DATE:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            style={{ display: 'flex', flexWrap: 'wrap' }}
            control={
              <TextField
                id='date'
                type='date'
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleDatePropChanged(e, prop)
                }
                style={{
                  width: 279,
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                value={
                  state.data.find(p => p.key === prop.key)?.value
                    ? moment(
                        state.data.find(p => p.key === prop.key)?.value,
                      ).format(REVERSE_DISPLAY_DATE_FORMAT)
                    : undefined
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  'data-test-id': `addAgreementModalPropInput_${idx}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      default:
        return <></>
    }
  })

  return (
    <div>
      {open && (
        <Root
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
        >
          <ModalTitle
            dataTestIdPrefix='addAgreementModal'
            id='customized-dialog-title'
            onClose={onDialogClose}
          >
            Добавить соглашение
          </ModalTitle>
          <ModalContent dividers>
            <div>
              <FormControlLabel
                className='fullWidth'
                control={
                  <DrawerAutocomplete
                    open={optionsListOpen}
                    clearOnEscape
                    fullWidth
                    onOpen={(): void => {
                      setOptionsListOpen(true)
                    }}
                    onClose={(): void => {
                      setOptionsListOpen(false)
                    }}
                    value={!valueCatalog.key ? null : valueCatalog}
                    onInputChange={(
                      e: React.ChangeEvent<{}>,
                      val: string,
                    ): void => {
                      onSearch$.next(val)
                    }}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      newValue: IKeyValuePair | null,
                    ): void => {
                      if (newValue) {
                        setValueCatalog(newValue)
                        setState({ ...state, categoryId: newValue.key })
                      }
                    }}
                    getOptionLabel={(option: IKeyValuePair): string =>
                      option.value
                    }
                    getOptionSelected={(
                      option: IKeyValuePair,
                      val: IKeyValuePair,
                    ): boolean => option.key === val.key}
                    renderOption={(option: IKeyValuePair): JSX.Element => (
                      <p style={{ margin: '0', padding: '0', width: '425px' }}>
                        {option.value}
                      </p>
                    )}
                    options={searchValues}
                    loading={optionsListLoading}
                    noOptionsText={'Ничего не найдено'}
                    loadingText={'Загрузка...'}
                    renderInput={(
                      params: AutocompleteRenderInputParams,
                    ): JSX.Element => (
                      <>
                        <TextField
                          {...params}
                          variant='outlined'
                          placeholder='Поиск...'
                          data-test-id='addAgreementModalAgreementInput'
                        />
                      </>
                    )}
                  />
                }
                label='Список соглашений'
                labelPlacement='top'
                style={{ cursor: 'default' }}
              />
            </div>
            {valueCatalog.key !== '' && (
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FormControlLabel
                    className='fullWidth'
                    control={
                      <TextField
                        type='text'
                        required={true}
                        variant='filled'
                        fullWidth
                        disabled
                        value={model.supplier.value}
                        inputProps={{
                          'data-test-id': 'addAgreementModalSupplierInput',
                        }}
                      />
                    }
                    label='Поставщик'
                    labelPlacement='top'
                  />
                  <FormControlLabel
                    className='fullWidth'
                    style={{ maxWidth: '75px', marginLeft: '20px' }}
                    control={
                      <TextField
                        type='text'
                        required={true}
                        variant='filled'
                        fullWidth
                        disabled
                        value={state.number}
                        inputProps={{
                          'data-test-id':
                            'addAgreementModalAgreementNumberInput',
                        }}
                      />
                    }
                    label='№'
                    labelPlacement='top'
                  />
                </div>
                <div className='propContainer'>
                  <FormControlLabel
                    className='prop'
                    control={
                      <TextField
                        id='dateStart'
                        type='date'
                        name='dateStart'
                        error={errors.dateStart.length > 0}
                        helperText={errors.dateStart}
                        required={true}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => handleRequiredDateChange(e)}
                        value={
                          state.dateStart.trim().length > 0
                            ? moment(state.dateStart).format(
                                REVERSE_DISPLAY_DATE_FORMAT,
                              )
                            : undefined
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          'data-test-id': 'addAgreementModalStartDateInput',
                        }}
                      />
                    }
                    label={'Дата начала действия соглашения'}
                    labelPlacement='top'
                    style={{ cursor: 'default' }}
                  />
                  <FormControlLabel
                    className='prop'
                    control={
                      <TextField
                        id='date'
                        type='date'
                        name='dateEnd'
                        error={errors.dateEnd.length > 0}
                        helperText={errors.dateEnd}
                        required={true}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => handleRequiredDateChange(e)}
                        value={
                          state.dateEnd.trim().length > 0
                            ? moment(state.dateEnd).format(
                                REVERSE_DISPLAY_DATE_FORMAT,
                              )
                            : undefined
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: state.dateStart,
                          'data-test-id': 'addAgreementModalEndDateInput',
                        }}
                      />
                    }
                    label={'Дата окончания действия соглашения'}
                    labelPlacement='top'
                    style={{ cursor: 'default' }}
                  />
                </div>
                <Divider />
              </>
            )}
            <div className='propContainer'>{renderProperties}</div>
            {valueCatalog.key && (
              <SelectProjectsAndObjects
                projectsAndObjects={projectsAndObjects}
                setProjectsAndObjects={setProjectsAndObjects}
                allProjectsAndObjects={allProjectsAndObjects}
                handleProjectChange={handleProjectChange}
                handleObjectChange={handleObjectChange}
                refreshProjectList={refreshProjectList}
              />
            )}
          </ModalContent>
          <ModalActions>
            <ButtonContainer>
              <ButtonComponent
                data-test-id='addAgreementModalCancelBtn'
                text='Отменить'
                type={EButtonType.DEFAULT}
                onClick={onDialogClose}
              />
              <ButtonComponent
                data-test-id='addAgreementModalSubmitBtn'
                text='Добавить соглашение'
                type={EButtonType.PRIMARY}
                onClick={saveBtnHandler}
              />
            </ButtonContainer>
          </ModalActions>
        </Root>
      )}
    </div>
  )
}
