import React, { useState } from 'react'
import * as _ from 'lodash'
import { Collapse, List, ListItemText, Popover } from '@material-ui/core'
import { ArrowForwardIos, ExpandLess, Save } from '@material-ui/icons'

import { ITreeListItem, TTreeList } from '../../../../../types'
import { ETreeListItemType } from '../../../../../constants'

import { useAppDispatch } from '../../../../../store'
import { MenuButton, MoveItemTree, StyledListItem } from '../../../styles/Style'
import { mergeNode } from '../../../store/catalog-slice'

export type MenuMoveActionProps = {
  handleCloseMenuItemAction: () => void
  setSelectedAnchorMenuItem: (param: HTMLElement | null) => void
  selectedAnchorMenuItem: HTMLElement | null
  selectedMenuItemAction: ITreeListItem
  items: TTreeList
}
export const MenuMoveAction: React.FC<MenuMoveActionProps> = ({
  handleCloseMenuItemAction,
  setSelectedAnchorMenuItem,
  selectedAnchorMenuItem,
  selectedMenuItemAction,
  items,
}) => {
  const dispatch = useAppDispatch()
  const [nodeOpen, setNodeOpen] = useState<{ [key: string]: boolean }>({})

  const handleNodeClick = (rowId: string) => (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.stopPropagation()

    const newState = _.cloneDeep(nodeOpen)
    const value = newState[rowId] || false

    newState[rowId] = !value
    setNodeOpen(newState)
  }

  const handleIconClick = (destinationId: string) => (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.stopPropagation()
    dispatch(mergeNode({ destinationId }))
    handleClose()
    handleCloseMenuItemAction()
  }

  const handleClose = (): void => {
    setSelectedAnchorMenuItem(null)
  }

  const renderNode = (
    node: ITreeListItem,
    level: number,
    dataTestIdPostfix: string,
  ): JSX.Element => (
    <div key={node.id}>
      <StyledListItem
        data-test-id={`catalogSettingsMoveMenuItem_${dataTestIdPostfix}`}
        button
        onClick={handleNodeClick(node.id)}
        disableGutters={true}
        style={{ paddingLeft: `${level === 0 ? 5 : level * 30}px` }}
      >
        {nodeOpen[node.id] ? (
          <ExpandLess
            style={{
              fontSize: '20px',
              color: '#5162d0',
            }}
          />
        ) : (
          <ArrowForwardIos
            style={{
              fontSize: '12px',
              marginRight: '5px',
              color: '#5162d0',
            }}
          />
        )}
        <ListItemText primary={node.name} />
        <MenuButton
          data-test-id={`catalogSettingsMoveMenuSaveBtn_${dataTestIdPostfix}`}
          onClick={handleIconClick(node.id)}
        >
          <Save />
        </MenuButton>
      </StyledListItem>
      {node.type === ETreeListItemType.FOLDER && (
        <Collapse in={nodeOpen[node.id]} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {node.content
              .filter(
                data =>
                  data.type === ETreeListItemType.FOLDER &&
                  data.id !== selectedMenuItemAction.id,
              )
              .map((data: ITreeListItem, idx) =>
                renderNode(data, level + 1, `${dataTestIdPostfix}_${idx}`),
              )}
          </List>
        </Collapse>
      )}
    </div>
  )

  return (
    <Popover
      id='tree-menu'
      getContentAnchorEl={null}
      anchorEl={selectedAnchorMenuItem}
      keepMounted
      open={Boolean(selectedAnchorMenuItem)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MoveItemTree>
        {items
          .filter(
            node =>
              node.type === ETreeListItemType.FOLDER &&
              node.id !== selectedMenuItemAction.id,
          )
          .map((node, idx) => renderNode(node, 0, `${idx}`))}
      </MoveItemTree>
    </Popover>
  )
}
