import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const Search = styled.div`
  position: relative;
  border-radius: 5px;
  background-color: #f9f9fb;
  &:hover {
    background-color: alpha(#f9f9fb, 0.6);
  }
  margin: 0 12px;
  width: 100%;
  @media (max-width: 600px) {
    margin-left: 32px;
    width: auto;
  }
`
export const CloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2px;
  cursor: pointer;
  & .MuiSvgIcon-root {
    font-size: 10px;
  }
`
export const StyledAutocomplete = styled(Autocomplete)`
  && {
    width: 177px;
    max-height: 40px;
  }
  & .MuiAutocomplete-root {
    transition: 0.3s width ease-in-out;
    height: 40px;
    color: inherit;
  }
  &.MuiAutocomplete-root {
    display: flex;
    align-items: center;
  }
  &.Mui-focused {
    width: 300px;
    cursor: text;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
    outline: none;
  }
  // & .MuiInputBase-input {
  //   textIndent: 20px;
  // }
  & .MuiAutocomplete-listbox {
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }
  }
  & .MuiAutocomplete-popupIndicator {
    display: none;
  }
  &.MuiAutocomplete-fullWidth {
    max-width: 397px;
  }
` as typeof Autocomplete
