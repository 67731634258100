/* eslint-disable no-undefined */
import React, { useRef, useState } from 'react'

import { Button, Table } from '@material-ui/core'

import { addDarkIcon, settingIcon, EEditableModalType } from '../../constants'
import { IKeyValuePair } from '../../types'
import { AddKeyValuePairModal } from '../AddKeyValuePairModal/AddKeyValuePairModal'

import * as I from './IAssignmentTableDisplayColumns'
import { AssignmentTableDisplayColumnsBody } from './AssignmentTableDisplayColumnsBody/AssignmentTableDisplayColumnsBody'
import {
  ActionButton,
  ColumnIconBtn,
  StyledPopover,
  StyledTableContainer,
  TitleContainer,
} from './Style'

export const AssignmentTableDisplayColumns: React.FC<I.OwnProps> = ({
  positionColumns,
  lotColumns,
  visibleColumns,
  visibleData,
  lotId,
  showSupplierButtons,
  onColumnVisibilityChanged,
  onDataVisibilityChanged,
  addPropertyHandler,
  editPropertyHandler,
  removePropertyHandler,
  dataTestIdPrefix,
}): React.ReactElement => {
  const editablePropertyModalTitle = {
    insert: 'Добавить свойство',
    edit: 'Редактировать свойство',
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [openEditablePropertyModal, setOpenEditablePropertyModal] = useState<
    boolean
  >(false)
  const [editablePropertyModalModel, setEditablePropertyModalModel] = useState<
    IKeyValuePair
  >({ key: '', value: '' })
  const [editablePropertyModalType, setEditablePropertyModalType] = useState<
    EEditableModalType
  >(EEditableModalType.INSERT)

  const handleClickPropertiesMenuBtn = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.stopPropagation()
    event.preventDefault()
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClosePropertiesMenu = (): void => {
    setAnchorEl(null)
  }

  const addPropertyBtnClick = (event: React.MouseEvent<HTMLElement>): void => {
    setEditablePropertyModalType(EEditableModalType.INSERT)
    setEditablePropertyModalModel({ key: lotId, value: '' })
    setOpenEditablePropertyModal(true)
  }

  const editPropertyBtnClick = (model: IKeyValuePair) => (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    setEditablePropertyModalType(EEditableModalType.EDIT)
    setEditablePropertyModalModel(model)
    setOpenEditablePropertyModal(true)
  }

  const savePropertyHandler = (model: IKeyValuePair): void => {
    setOpenEditablePropertyModal(false)
    if (editablePropertyModalType === EEditableModalType.INSERT) {
      addPropertyHandler(model.key, model.value, (): number => 0)
    } else {
      editPropertyHandler(model.key, model.value, (): number => 0)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const divRef = useRef<HTMLDivElement | null>(null)

  return (
    <div
      style={{ position: 'absolute', right: '0' }}
      className='no-border'
      ref={divRef}
    >
      <ColumnIconBtn>
        <Button
          data-test-id={`${dataTestIdPrefix}ColumnSettingsBtn`}
          aria-describedby={id}
          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
            event.stopPropagation()
            event.preventDefault()
            handleClickPropertiesMenuBtn(event)
          }}
        >
          {settingIcon}
        </Button>
        {openEditablePropertyModal && (
          <AddKeyValuePairModal
            open={openEditablePropertyModal}
            title={
              editablePropertyModalType === EEditableModalType.INSERT
                ? editablePropertyModalTitle.insert
                : editablePropertyModalTitle.edit
            }
            label={'Название'}
            model={editablePropertyModalModel}
            saveBtnText={
              editablePropertyModalType === EEditableModalType.INSERT
                ? 'Добавить'
                : 'Сохранить'
            }
            cancelBtnText={'Отменить'}
            onSave={savePropertyHandler}
            onClose={(): void => setOpenEditablePropertyModal(false)}
            dataTestIdPrefix={`${dataTestIdPrefix}ColumnsSettingsAddPropertyModal`}
          />
        )}
        <StyledPopover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={id}
          open={open}
          anchorEl={divRef.current}
          container={divRef.current}
          onClose={handleClosePropertiesMenu}
        >
          <StyledTableContainer>
            <TitleContainer>
              <p>Колонки позиции</p>
            </TitleContainer>
            <Table>
              <AssignmentTableDisplayColumnsBody
                columns={positionColumns}
                visibleColumns={visibleColumns}
                onColumnVisibilityChanged={onColumnVisibilityChanged}
                ifValue={true}
                showActionButtons={false}
                onEditPropertyBtnClick={() => (): number => 0}
                removePropertyHandler={(): number => 0}
                dataTestIdPrefix={`${dataTestIdPrefix}ColumnsSettings1`}
              />
              <AssignmentTableDisplayColumnsBody
                columns={positionColumns}
                visibleColumns={visibleColumns}
                onColumnVisibilityChanged={onColumnVisibilityChanged}
                ifValue={false}
                showActionButtons={false}
                onEditPropertyBtnClick={() => (): number => 0}
                removePropertyHandler={(): number => 0}
                dataTestIdPrefix={`${dataTestIdPrefix}ColumnsSettings2`}
              />
            </Table>
          </StyledTableContainer>
          <StyledTableContainer>
            <TitleContainer>
              <p>Колонки поставщика</p>
              {showSupplierButtons && (
                <ActionButton
                  data-test-id={`${dataTestIdPrefix}ColumnsSettings3_AddPropertyBtn`}
                  onClick={addPropertyBtnClick}
                >
                  {addDarkIcon}
                </ActionButton>
              )}
            </TitleContainer>
            <Table>
              <AssignmentTableDisplayColumnsBody
                columns={lotColumns}
                visibleColumns={visibleData}
                onColumnVisibilityChanged={onDataVisibilityChanged}
                ifValue={null}
                showActionButtons={showSupplierButtons}
                onEditPropertyBtnClick={editPropertyBtnClick}
                removePropertyHandler={removePropertyHandler}
                dataTestIdPrefix={`${dataTestIdPrefix}ColumnsSettings3`}
              />
            </Table>
          </StyledTableContainer>
        </StyledPopover>
      </ColumnIconBtn>
    </div>
  )
}
