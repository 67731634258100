import styled from 'styled-components'
import { ListItem } from '@material-ui/core'

import { ButtonComponent } from '../Button/Button'

export const CatalogWrapper = styled.div`
  height: 300px;
  overflow-y: scroll;
`
export const Root = styled.div`
  min-width: 270;
  & .MuiListSubheader-gutters {
    padding-right: 0;
  }
`
export const StyledListItem = styled(ListItem)`
  display: flex;
  height: auto;
  min-height: 45px;
  &:hover,
  &:active,
  &:focus {
    background-color: #f2f0f8;
    border-radius: 5px;
    height: auto;
    color: #4c5ecf;
  }
  & .MuiListItemText-primary {
    font-size: 14px;
    color: #05082e;
    max-width: 270px;
  }
`
export const Button = styled(ButtonComponent)`
  width: 100%;
`
