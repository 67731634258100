import { IconButton } from '@gmini/ui-kit'
import { Typography } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const SettingsButton = styled(IconButton)`
  && {
    margin-left: auto;
    margin-right: 0;
  }
`
export const PageRoot = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Proxima Nova Regular';
  margin: 0;
  height: 100%;
`
export const Root = styled.div`
  display: flex;
  font-family: 'Proxima Nova Regular';
  margin: 0 -15px;
  height: 100%;
`
export const AgreementName = styled(Typography)`
  && {
    font-size: 24px;
    line-height: 29px;
    margin: 10px 0;
    color: #1b2b4b;
  }
` as typeof Typography
export const AgreementInfo = styled.div`
  margin: 0;
`
export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & button {
    margin-right: 8px;
  }
`

export const Selected = styled(Typography)`
  flex: 1 1 auto;
` as typeof Typography

type FlexItemProps = {
  tabPanel?: boolean
  fullheight?: boolean
}

export const FlexItem = styled.div`
  margin: 0 15px;
  ${({ tabPanel }: FlexItemProps) =>
    tabPanel
      ? css`
          flex: 1 1 auto;
          width: 100px;
        `
      : ''}
  ${({ fullheight }: FlexItemProps) =>
    fullheight
      ? css`
          display: flex;
          flex-direction: column;
        `
      : ''}
`
