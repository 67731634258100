import styled, { css } from 'styled-components'
import { Checkbox, TableCell, TableRow, Popper } from '@material-ui/core'

type StyledTableRowProps = {
  selected: boolean
  error: boolean
}

export const StyledTableRow = styled(TableRow)`
  && {
    margin: 0;
    padding: 0;
    cursor: pointer;
    & .MuiAvatar-root {
      border-radius: 0;
      border: 1px solid #e2e4e9;
      display: inline-flex;
      margin-right: 5px;
    }
    & .flex-container {
      display: flex;
    }
    & span {
      align-self: center;
      word-wrap: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    ${({ selected }: StyledTableRowProps) =>
      selected
        ? css`
            && {
              background-color: rgba(0, 0, 0, 0);
            }
          `
        : ''}
    ${({ error }: StyledTableRowProps) =>
      error
        ? css`
            background-color: #fce6e6;
          `
        : ''}

  & .MuiTableCell-root {
      max-width: 350px;
    }
  }
`
export const CheckedRow = styled(Checkbox)`
  && {
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0);
    }
    & .MuiTableCell-root {
      border: 0;
    }
  }
`

export const CustomTooltip = styled(Popper)`
  && {
    & .MuiTooltip-tooltip {
      background: rgba(244, 246, 249, 1);
      margin: 10px 0 0 0;
      padding: 0;
      min-width: 200px;
      width: 100%;
      max-width: none;
      box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }
  }
`

export const customArrow = styled.div`
  color: rgba(244, 246, 249, 0.9);
  text-align: center;
`
export const StickyCheckbox = styled(TableCell)`
  && {
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    & + td {
      left: 64px;
    }
    &.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
      width: 64px;
    }
  }
`

type StyledCellProps = {
  sticky: boolean
}

export const StyledCell = styled(TableCell)`
  && {
    ${({ sticky }: StyledCellProps) =>
      sticky
        ? css`
            position: sticky;
            top: 0;
            left: 0;
            background: #fff;
            z-index: 2;
          `
        : ''}
  }
`
export const SettingsEmptyBodyCell = styled(TableCell)`
  && {
    &:nth-child(odd) {
      background: #fafafa;
    }
  }
`
