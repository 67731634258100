import React, { useState } from 'react'

import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core'

import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'
import { ButtonComponent } from '../Button/Button'
import { EButtonType } from '../../constants'

import { Label, Root, Title } from './Styles'

type TPositionExportModal = {
  open: boolean
  onClose: () => void
  onSubmitExport?: (isExportOneFile: boolean) => void
  onSubmitExportPosition?: (isExportAllPosition: boolean) => void
  exportPageOrTabOption?: boolean
  exportBySelectedPositionOption?: boolean
}

export const PositionExportModal: React.FC<TPositionExportModal> = ({
  open,
  onClose,
  onSubmitExport,
  onSubmitExportPosition,
  exportPageOrTabOption,
  exportBySelectedPositionOption,
}) => {
  const [isExportOneFile, setIsExportOneFile] = useState<boolean>(true)
  const [isExportAllPosition, setIsExportAllPosition] = useState<boolean>(false)

  const handleSwitchExportFileOption = () =>
    setIsExportOneFile(!isExportOneFile)

  const handleSwitchExportPosition = () =>
    setIsExportAllPosition(!isExportAllPosition)

  const handleSubmit = () => {
    if (exportPageOrTabOption) {
      onSubmitExport && onSubmitExport(isExportOneFile)
    } else {
      onSubmitExportPosition && onSubmitExportPosition(isExportAllPosition)
    }
  }

  return (
    <>
      {open && (
        <Root
          onClose={onClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
        >
          <ModalTitle
            dataTestIdPrefix='positionsExportModal'
            id='position-export-dialog-title'
            onClose={onClose}
          >
            <Title>Выгрузить список позиций в файл (xlsx)</Title>
          </ModalTitle>
          <ModalContent>
            <div>
              {exportPageOrTabOption && (
                <RadioGroup
                  aria-label='exportType'
                  defaultValue='singleFile'
                  name='radio-buttons-group'
                >
                  <FormControlLabel
                    value='singleFile'
                    control={
                      <Radio
                        data-test-id='positionsExportModalSinglePageRadioBtn'
                        color='primary'
                      />
                    }
                    label={<Label>Выгрузить всё на одном листе</Label>}
                    onChange={handleSwitchExportFileOption}
                  />
                  <FormControlLabel
                    value='byTabs'
                    control={
                      <Radio
                        data-test-id='positionsExportModalMultiplePagesRadioBtn'
                        color='primary'
                      />
                    }
                    label={<Label>Разбить на вкладки</Label>}
                    onChange={handleSwitchExportFileOption}
                  />
                </RadioGroup>
              )}
              {exportBySelectedPositionOption && (
                <RadioGroup
                  aria-label='exportType'
                  defaultValue='selectedPosition'
                  name='radio-buttons-group'
                >
                  {exportBySelectedPositionOption && (
                    <>
                      <FormControlLabel
                        value='selectedPosition'
                        control={
                          <Radio
                            data-test-id='positionsExportModalSelectedPositionsRadioBtn'
                            color='primary'
                          />
                        }
                        label={<Label>Выбранные позиции</Label>}
                        onChange={handleSwitchExportPosition}
                      />
                      <FormControlLabel
                        value='allPosition'
                        control={
                          <Radio
                            data-test-id='positionsExportModalAllPositionsRadioBtn'
                            color='primary'
                          />
                        }
                        label={<Label>Все позиции</Label>}
                        onChange={handleSwitchExportPosition}
                      />
                    </>
                  )}
                </RadioGroup>
              )}
            </div>
          </ModalContent>
          <ModalActions>
            <ButtonComponent
              data-test-id='positionsExportModalSubmitBtn'
              text='Выгрузить'
              type={EButtonType.PRIMARY}
              onClick={handleSubmit}
            />
          </ModalActions>
        </Root>
      )}
    </>
  )
}
