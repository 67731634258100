import styled from 'styled-components'
import { Checkbox, IconButton, ListItem, List } from '@material-ui/core'

export const Root = styled.div`
  & .before-after-insert {
    background: yelow !important;
  }
`
export const StyledListItem = styled(ListItem)`
  && {
    display: flex;
    height: auto;
    min-height: 45px;
    &:hover,
    &:active,
    &:focus {
      background-color: #f2f0f8;
      border-radius: 5px;
      height: auto;
      color: #4c5ecf;
    }
    & .MuiListItemText-primary {
      font-size: 14px;
      color: #05082e;
      max-width: 270px;
    }
  }
`
export const MenuButton = styled(IconButton)`
  && {
    padding: 0;
    border-radius: 5px;
    width: 27px;
    height: 27px;
    &.active {
      background: #4c5ecf;
      color: #ffffff;
    }
  }
`
export const CheckedRow = styled(Checkbox)`
  && {
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0);
    }
    & .MuiTableCell-root {
      border: 0;
    }
    &.MuiCheckbox-colorPrimary {
      color: #e2e4e9;
      &.Mui-checked {
        color: #4c5ecf;
      }
    }
  }
`
export const MenuItemIcons = styled.div`
  display: flex;
  align-items: center;
`
export const SettingsTree = styled(List)`
  && {
    margin: 0 0 0 24px;
    min-width: 270;
    min-height: calc(100vh - 140px);
    border-radius: 5px;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    & .MuiListItem-root {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
` as typeof List
