import React, { memo } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

import { TTenderCondition } from '../../tender-conditions.types'

import { ListSection, Root, StyledListSubheader, Ul } from './styles'

export type TCondition = {
  name: string
}

type TConditionList = {
  list: TTenderCondition
  handleSelectedList?: (conditions: Array<string>) => void
}

export const ConditionListPL = ({
  list,
  handleSelectedList,
}: TConditionList) => {
  const [checkedList, setCheckedList] = React.useState<Array<string>>([])

  const handleChange = (condition: string) => (
    event: React.ChangeEvent<HTMLElement>,
  ): void => {
    const hasItem = checkedList.find(el => el === condition)

    const newCheckedList = !hasItem
      ? [...checkedList, condition]
      : checkedList.filter(el => el !== condition)

    setCheckedList(newCheckedList)
    if (handleSelectedList) {
      handleSelectedList(newCheckedList)
    }
  }

  if (!list.length) {
    return null
  }

  return (
    <Root subheader={<li />}>
      {list.map(({ label, conditions }, idx) => (
        <ListSection data-test-id={`conditionListItem_${idx}`} key={label}>
          <Ul>
            <StyledListSubheader>{label}</StyledListSubheader>
            {conditions.map((condition: string, index) => (
              <ListItem
                data-test-id={`conditionListItemCondition_${index}`}
                key={condition}
              >
                <ListItemText primary={condition} />
                <Checkbox
                  data-test-id={`conditionListItemCondition_${index}Checkbox`}
                  color='primary'
                  checked={Boolean(checkedList.find(el => el === condition))}
                  onChange={handleChange(condition)}
                />
              </ListItem>
            ))}
          </Ul>
        </ListSection>
      ))}
    </Root>
  )
}

export const ConditionList = memo(ConditionListPL)
