import { FormControl, Input, FormLabel } from '@material-ui/core'
import styled from 'styled-components'

export const Root = styled(FormControl)`
  && {
    width: 100%;
  }
`
export const StyledInput = styled(Input)`
  && input {
    color: #1b2b4b;
    padding: 15px;
    font-size: 14px;
    font-family: 'Proxima Nova Regular';
    line-height: 17px;
    background-color: #f9f9fb;
    transition: background-color 200ms;
    border-radius: 4px;
    border: 1px solid transparent;
    &:hover {
      background-color: #f5f5f5;
    }
    .Mui-error > & {
      border-color: #f44336;
    }
  }
`
export const Label = styled(FormLabel)`
  && {
    color: #1b2b4b;
    height: 17px;
    font-size: 14px;
    align-self: flex-start;
    font-family: 'Proxima Nova Light';
    line-height: 17px;
    &.Mui-focused {
      color: #1b2b4b;
    }
    &.Mui-error {
      color: #f44336;
    }
  }
`
