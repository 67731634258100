import styled from 'styled-components'

type WrapperProps = {
  diameter: number
}

export const Wrapper = styled.div`
  position: relative;
  ${({ diameter }: WrapperProps) => `max-height: ${diameter}px;`}
`
export const Text = styled.span`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-family: 'Roboto';
  color: #4c5ecf;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.23px;
  letter-spacing: 0.15px;
`

export const BigGreyText = styled.span`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  color: #fff;
`
