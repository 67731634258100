import styled from 'styled-components'
import { IconButton } from '@material-ui/core'

export const Lot = styled.div`
  display: flex;
  width: 100%;
`
export const DrawerEditBtn = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
    & .MuiSvgIcon-root {
      font-size: 16px;
    }
  }
`
export const CardRowBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  margin: 0 -10px;
  & .MuiFormControl-root {
    margin: 5px 10px;
    max-width: 280px;
  }
  & div.MuiInput-formControl {
    margin-top: 0 !important;
  }
  & .MuiInputBase-input {
    line-height: 25px;
  }
  & label {
    margin-left: 0;
    position: relative;
  }
`
