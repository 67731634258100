import React from 'react'
import _ from 'lodash'
import { Typography, TextField } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import { editIcon } from '../../../../../../constants'
import { formatValue } from '../../../../../../utils'
//Уточнить по составу интерфейса
import { TRowData } from '../../../../../../types'

import * as I from './IAgreementDetailsSection'
import {
  AccordionDetailsRoot,
  AccordionRoot,
  AccordionSummaryHeader,
  AccordionSummaryRoot,
  CardRowBody,
  DrawerEditBtn,
  Lot,
} from './Style'

export const AgreementDetailsSection: React.FC<I.OwnProps> = ({
  data,
  onOpenEditAgreementModalClick,
  dataTestIdPrefix,
}): React.ReactElement => {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const valuesData: TRowData = []

  data.listView.data.forEach(d => _.extend(valuesData, d.data))

  return (
    <AccordionRoot
      square
      expanded={expanded}
      TransitionProps={{ unmountOnExit: false }}
    >
      <AccordionSummaryRoot
        aria-controls='panel1d-content'
        id='panel1d-header'
        data-test-id={`agreementDetailsSection_${dataTestIdPrefix}_Accordion`}
      >
        <AccordionSummaryHeader>
          <div className='accordionSummaryHeaderContentLeft'>
            <ExpandMore
              style={{
                transform: `rotate(${expanded ? '0' : '-90'}deg)`,
                color: '#4C5ECF',
              }}
              onClick={(): void => {
                setExpanded(!expanded)
              }}
            ></ExpandMore>
            <Typography
              onClick={(): void => {
                setExpanded(!expanded)
              }}
            >
              {data.sectionName}
            </Typography>
          </div>
          {data.listView.columns && data.listView.columns.length ? (
            <div className={'accordionSummaryHeaderContentRight'}>
              <DrawerEditBtn
                data-test-id={`agreementDetailsSection_${dataTestIdPrefix}_EditBtn`}
                onClick={(): void => {
                  onOpenEditAgreementModalClick(
                    data.listView.columns,
                    data.listView.data[0],
                    data.sectionName,
                  )
                }}
              >
                {editIcon}
              </DrawerEditBtn>
            </div>
          ) : null}
        </AccordionSummaryHeader>
      </AccordionSummaryRoot>
      <AccordionDetailsRoot>
        <Lot>
          <CardRowBody>
            {data.listView.columns.map(c => (
              <TextField
                key={c.key}
                label={c.title}
                multiline={true}
                maxRows={50}
                value={
                  data.listView.data
                    ? formatValue(
                        c.type,
                        valuesData.find(d => d.key === c.key)?.value,
                        '-',
                        c.unit,
                      )
                    : '-'
                }
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            ))}
          </CardRowBody>
        </Lot>
      </AccordionDetailsRoot>
    </AccordionRoot>
  )
}
