import React from 'react'
import { FormHelperText, InputBaseProps } from '@material-ui/core'

import { Label, Root, StyledInput } from './Style'

type Props = {
  error?: string | null | boolean
  label?: string | null
  maxLength?: number
} & Omit<InputBaseProps, 'error'>

export const TextField: React.FC<Props> = ({
  error,
  label,
  maxLength,
  inputProps,
  ...restProps
}) => (
  <Root error={!!error}>
    <Label>{label}</Label>
    <StyledInput
      fullWidth
      disableUnderline
      {...restProps}
      inputProps={{
        maxLength,
        ...inputProps,
      }}
    />
    <FormHelperText>{error}</FormHelperText>
  </Root>
)
