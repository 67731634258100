import styled from 'styled-components'
import { IconButton } from '@material-ui/core'

export const ActionsContainer = styled.div`
  display: flex;
  float: right;
  justify-content: center;
  margin-right: 6px;
`
export const ActionButton = styled(IconButton)`
  flex: 1 1 20px;
  padding: 14px;
  width: 20px;
  height: 20px;
  margin: 0 -3px;
  & .MuiSvgIcon-root {
    font-size: 18px;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
