import { IconButton, DialogTitle } from '@material-ui/core'
import styled from 'styled-components'

export const Root = styled(DialogTitle)`
  && {
    margin: 0;
    padding: 25px;
    & .MuiTypography-h6 {
      font-family: 'Proxima Nova Regular';
    }
  }
`
export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 8px;
    top: 18px;
    color: #9e9e9e;
  }
`
