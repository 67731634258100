import styled, { css } from 'styled-components'
import { Dialog } from '@material-ui/core'

type RootProps = {
  hide: boolean
}

export const Root = styled(Dialog)`
  && {
    margin: 0;
    padding: 8px;
    & .MuiDialog-paperWidthSm {
      max-width: 645px;
    }
    ${({ hide }: RootProps) =>
      hide
        ? css`
            opacity: 0;
            transition: 0.5s;
          `
        : ''}
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  & button {
    margin: 0 5px;
  }
`
