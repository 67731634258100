import React from 'react'
import { Divider, IconButton } from '@material-ui/core'

import { collapseIcon, settingIcon } from '../../../../../constants'
import { TTreeList } from '../../../../../types'

import { CloseButton, IconContainer, ListTitle } from '../../../styles/Style'

export type CatalogViewHeaderProps = {
  editable: boolean
  categoryId: number
  treeList: TTreeList
  setIsCatalogExpanded: (val: boolean) => void
  handleOpenSettingCatalog: (isOpen: boolean, treeData: TTreeList) => void
}

export const CatalogViewHeader: React.FC<CatalogViewHeaderProps> = ({
  editable,
  categoryId,
  treeList,
  setIsCatalogExpanded,
  handleOpenSettingCatalog,
}) => (
  <ListTitle
    component='div'
    id='nested-list-subheader'
    data-test-id='catalogHeading'
  >
    Каталог
    <IconContainer>
      {editable && (
        <CloseButton
          data-test-id='catalogOpenSettingsBtn'
          onClick={(): void => handleOpenSettingCatalog(true, treeList)}
        >
          {settingIcon}
        </CloseButton>
      )}
      {categoryId !== 0 && (
        <>
          <Divider orientation='vertical' flexItem />
          <IconButton
            data-test-id='catalogCollapseBtn'
            onClick={() => setIsCatalogExpanded(false)}
          >
            {collapseIcon}
          </IconButton>
        </>
      )}
    </IconContainer>
  </ListTitle>
)
