import React, { Key, useCallback, useEffect, useState } from 'react'
import { get } from 'lodash'
import { DraggableCore } from 'react-draggable'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import {
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Tooltip,
  Checkbox,
} from '@material-ui/core'

import {
  ActionHeadCell,
  ActionsCell,
  Cell,
  CheckBoxCell,
  EmptyData,
  ResizeHandle,
  Root,
  StyledTable,
  StyledTooltip,
} from './Style'

export type DumbColumn = {
  sticky?: boolean
  key: Key
  title: string
}

export type DumbRow = {
  id: Key
  [K: string]: string | number | boolean | null | React.ReactChild
}

type DumbTable = {
  columns: DumbColumn[]
  rows: DumbRow[]
  actions?: React.ComponentType<{ id: string | number }>
  minColumnWidth?: number
  defaultColumnWidth?: number
  showCheckBox?: boolean
  checkBoxTitle?: string
  onChangeCheckBox?: (rowId: Key) => void
  onDragEnd?: (row: DumbRow[]) => void
  disabledDragDrop?: boolean
  dataTestIdPrefix?: string
}

const reorder = (list: DumbRow[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => ({
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
    display: 'table',
  }),
})

const getRowComponent = (
  disabledDragDrop: boolean,
  editable: boolean,
  id: string,
  index: number,
) => {
  if (disabledDragDrop) {
    return TableRow
  }

  if (!editable) {
    return TableRow
  }

  return DraggableComponent(id, index)
}

export const DumbTable: React.FC<DumbTable> = ({
  columns,
  rows,
  actions: Actions,
  minColumnWidth = 60,
  defaultColumnWidth = 150,
  showCheckBox = false,
  checkBoxTitle = '',
  onChangeCheckBox,
  onDragEnd: onDragEndProp,
  disabledDragDrop,
  dataTestIdPrefix,
}): React.ReactElement => {
  const [widths, setWidths] = useState<{ [K: string]: Key }>({})
  const [items, setItems] = useState<DumbRow[]>(() => rows)
  useEffect(() => {
    setItems(rows)
  }, [setItems, rows])

  const calculateWidth = useCallback(
    (key, delta) => {
      const prevWidth = widths[key] || 0
      const width = prevWidth + delta
      setWidths({
        ...widths,
        [key]: width < minColumnWidth ? minColumnWidth : width,
      })
    },
    [widths, minColumnWidth],
  )

  const calculateStartWidth = useCallback(
    (key, width) => {
      if (!widths[key]) {
        calculateWidth(key, width)
      }
    },
    [calculateWidth, widths],
  )

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems: DumbRow[] = reorder(
      items,
      result.source.index,
      result.destination.index,
    )

    if (onDragEndProp) {
      onDragEndProp(newItems)
    }

    setItems(newItems)
  }

  return (
    <Root>
      <TableContainer>
        <StyledTable stickyHeader>
          <colgroup>
            {columns.map(({ key }) => (
              <col
                key={key}
                style={{ width: widths[key] || defaultColumnWidth }}
              />
            ))}
            {showCheckBox && <col />}
            {!!Actions && <ActionHeadCell />}
          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map(({ key, title, sticky }) => (
                <Cell key={key} sticky={!!sticky}>
                  <Tooltip PopperComponent={StyledTooltip} title={title || ''}>
                    <span>{title}</span>
                  </Tooltip>
                  <DraggableCore
                    onDrag={(_, { deltaX }) => {
                      calculateWidth(key, deltaX)
                    }}
                    onStart={(_, { node }) => {
                      calculateStartWidth(key, node.parentElement?.offsetWidth)
                    }}
                  >
                    <ResizeHandle />
                  </DraggableCore>
                </Cell>
              ))}
              {showCheckBox && (
                <CheckBoxCell align='center'>
                  {checkBoxTitle ?? ''}
                </CheckBoxCell>
              )}
              {!!Actions && <ActionsCell align='center'>Действия</ActionsCell>}
            </TableRow>
          </TableHead>
          <TableBody component={DroppableComponent(onDragEnd)}>
            {!!items.length &&
              items.map((row, index) => (
                <TableRow
                  data-test-id={`${dataTestIdPrefix}TableRow_${index}`}
                  component={getRowComponent(
                    Boolean(disabledDragDrop),
                    Boolean(row.editable),
                    String(row.id),
                    index,
                  )}
                  hover
                  tabIndex={-1}
                  key={row.id}
                >
                  {columns.map(({ key, sticky }) => {
                    const value = get(row, key, null)

                    return (
                      <Cell sticky={!!sticky} key={key}>
                        <Tooltip
                          PopperComponent={StyledTooltip}
                          title={typeof value === 'object' ? '' : value}
                        >
                          <span>{value}</span>
                        </Tooltip>
                      </Cell>
                    )
                  })}
                  {showCheckBox && (
                    <CheckBoxCell align='center'>
                      <Checkbox
                        data-test-id={`${dataTestIdPrefix}TableRow_${index}_Checkbox`}
                        checked={Boolean(row.sendFile)}
                        onChange={() =>
                          onChangeCheckBox && onChangeCheckBox(row.id)
                        }
                      />
                    </CheckBoxCell>
                  )}
                  {!!Actions && (
                    <ActionsCell align='center'>
                      <Actions id={row.id} />
                    </ActionsCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {!rows.length && <EmptyData>Нет данных</EmptyData>}
    </Root>
  )
}

const DraggableComponent = (id: string, index: number) => (props: any) => (
  <Draggable draggableId={id} index={index}>
    {(provided, snapshot) => (
      <TableRow
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        {...props}
      >
        {props.children}
      </TableRow>
    )}
  </Draggable>
)

const DroppableComponent = (
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void,
) => (props: any) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId={'1'} direction='vertical'>
      {provided => (
        <TableBody
          ref={provided.innerRef}
          {...provided.droppableProps}
          {...props}
        >
          {props.children}
          {provided.placeholder}
        </TableBody>
      )}
    </Droppable>
  </DragDropContext>
)
