import { Typography } from '@gmini/ui-kit/lib/Typography'
import { IconButton } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

export const StyledIconButton = styled(IconButton)`
  && {
    path,
    rect {
      fill: rgba(255, 255, 255, 0.5);
      transition: all 0.2s ease-out;
    }
    :hover {
      background: rgba(255, 255, 255, 0.1);
      path,
      rect {
        fill: rgba(255, 255, 255, 1);
      }
    }
  }
`
type EventsLogButtonWrapperProps = {
  active: boolean
}
export const EventsLogButtonWrapper = styled.div`
  && {
    margin: 0px 10px;
    align-self: center;
    border-radius: 4px;
    padding: 6px;
    ${({ active }: EventsLogButtonWrapperProps) =>
      active
        ? css`
            background: rgba(67, 83, 180, 1);
            path,
            rect {
              fill: rgba(255, 255, 255, 1);
            }
            ${StyledIconButton} {
              pointer-events: none;
            }
          `
        : ''}
  }
`

export const NotificationsWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 1101;
  margin: 0 10px;
  padding: 6px;
  && path {
    fill: rgba(255, 255, 255, 0.5);
  }
  && button:hover {
    background: rgba(255, 255, 255, 0.1);
    path {
      fill: #fff;
    }
  }
`
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex: 0 0 65px;
  background-color: #4c5ecf;
  padding-left: 24px;
`
export const Column = styled.div`
  display: flex;
`
export const Logo = styled.div`
  display: flex;
  align-items: center;
  padding-right: 19px;
`
export const LogoName = styled(Typography)`
  && {
    font-family: 'Roboto';
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 22px;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 8px;
    color: #fff;
    margin-right: 8px;
  }
`
export const Divider = styled.div`
  background: rgba(255, 255, 255, 0.2);
  width: 1px;
  margin: 14px 0 15px;
`
export const UserAccountWrapper = styled.div`
  position: relative;
`

type SidebarBtnProps = {
  active: boolean
}

export const SidebarBtn = styled.div`
  fill: rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  padding: 0 19px;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${({ active }: SidebarBtnProps) =>
    active
      ? css`
          background-color: #4353b4;
          fill: #fff;
          color: #fff;
        `
      : ''}

  &:hover {
    fill: #fff;
    color: #fff;
    cursor: pointer;
  }
`
