import styled from 'styled-components'
import { Menu, MenuItem, IconButton } from '@material-ui/core'

import { ButtonComponent } from '../../../Button/Button'

export const AssignmentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  width: 100%;
  & .left-side {
    display: flex;
    align-items: center;
    & h3 {
      margin-left: 10px;
    }
  }
  & .right-side {
    display: flex;
    align-items: center;
    & .status-info {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: flex-end;
      & .name {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4c5ecf;
        margin: 0;
      }
      & .date {
        font-size: 11px;
        line-height: 13px;
        text-align: right;
        color: #616284;
      }
    }
  }
`
export const DetailsBtn = styled(ButtonComponent)`
  && {
    margin-left: 25px;
  }
  & p {
    overflow: hidden;
    max-width: 194px;
    word-wrap: break-word;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
export const MenuButton = styled(IconButton)`
  && {
    padding: 0;
    border-radius: 5px;
    width: 44px;
    height: 44px;
    margin-left: 15px;
    background-color: #f9f9fb;
  }
  &.active {
    background: #4c5ecf;
    color: #ffffff;
  }
`

export const AssignmentMenu = styled(Menu)`
  & li {
    font-family: 'Proxima Nova Regular';
    font-size: 14px;
  }
  & .MuiListItem-button:hover {
    background-color: #f2f0f8;
    border-radius: 5px;
  }
  & ul {
    margin: 7px;
    padding: 0;
    min-width: 270px;
  }
  & .MuiSvgIcon-root {
    background-color: rgba(0, 0, 0, 0);
    color: white;
    font-size: 10px;
  }
  & .MuiListItem-gutters {
    padding-left: 8px;
    padding-right: 8px;
  }
`
export const MenuTitle = styled(MenuItem)`
  opacity: 1 !important;
`
