import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

export const ContentContainer = styled.div`
  position: relative;
`
export const ActionButton = styled(IconButton)`
  width: 40px;
  height: 40px;
`
