import React, { useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { map } from 'lodash'
import { MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

import { Tooltip } from '@gmini/ui-kit'

import { useQuery } from '@gmini/utils'

import {
  AssignmentService,
  getMailerUrlSendAllTz,
} from '../../../../services/assignments-service'
import { Authorities } from '../../../../services/auth-service/auth-constants'

import {
  IKeyValuePair,
  IRow,
  TAssignmentSectionData,
  TColumn,
  TEditModalInitialState,
  TSectionModalInitialState,
} from '../../../../types'
import {
  EButtonType,
  EEditableModalType,
  EAPIResponseStatus,
  EColumnType,
  TAssignmentStatus,
  arrowBackIcon,
  detailsIcon,
} from '../../../../constants'

import { EditableModal } from '../../../EditableModal/EditableModal'
import { DialogModal } from '../../../DialogModal/DialogModal'
import { SectionEditableModal } from '../../../SectionEditableModal/SectionEditableModal'

import { LotModal } from '../../../lot-modal/lot-modal'
import { usePermissions } from '../../../../hooks/usePermissions'

import { useAppSelector } from '../../../../store'

import {
  AssignmentName,
  AssignmentsHeader,
  BackButton,
  DetailsBtn,
  DividerSmall,
  MenuButton,
  Name,
  Side,
  StatusInfo,
  Title,
  AssignmentMenu,
} from './Style'
import * as I from './IAppHeaderAssignment'
import { AssignmentDetails } from './AssignmentDetails/AssignmentDetails'

const setStatus: Array<IKeyValuePair> = [
  { key: 'NEW', value: 'Новое' },
  { key: 'SEND_TO_PROVIDERS', value: 'Отправлено поставщикам' },
  { key: 'CHOSE_PROVIDER', value: 'Выбор поставщика' },
  { key: 'CLOSED', value: 'Закрыто' },
]

export const AppHeaderAssignment: React.FC<I.OwnProps> = ({
  isSticky,
  assignment,
  changeStatusAssignment,
  addLotToAssignment,
  deleteLotAssignment,
  editLotAssignment,
  getAssignmentSectionData,
  getAssignmentDetail,
  onSetExportSectionFlag,
}): React.ReactElement => {
  const checkPermissions = usePermissions()
  const history = useHistory()
  const specialisations = useAppSelector(state => state.specialisations.list)
  const query = useQuery()
  const { tab } = useParams<{ tab: string }>()

  const [assignmentMenuAnchorEl, setAssignmentMenuAnchorEl] =
    useState<HTMLElement | null>(null)
  const [assignmentMenuStatusAnchorEl, setAssignmentMenuStatusAnchorEl] =
    useState<HTMLElement | null>(null)

  const [opeModal, setOpenModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openDialogModal, setOpenDialogModal] = useState(false)

  const [openAssignmentDetails, setOpenAssignmentDetails] = useState(false)
  const [assignmentDetailsData, setAssignmentDetailsData] =
    useState<TAssignmentSectionData>({
      sectionsTenderRequests: [],
      tenderId: Number(assignment.id),
    })

  const [openEditAssignmentSectionModal, setOpenEditAssignmentSectionModal] =
    useState(false)

  const [
    editAssignmentSectionModalConfig,
    setEditAssignmentSectionModalConfig,
  ] = useState<TSectionModalInitialState>({
    data: {
      row: {
        rowId: '0',
        data: [],
      },
      settings: [],
    },
    title: '',
  })

  const modalInitialState = useRef<TEditModalInitialState>({
    settings: [],
    row: {
      rowId: '',
      photo: '',
      data: [],
    },
  })

  const openAssignmentMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (event.isPropagationStopped()) {
        return
      }
      event.stopPropagation()
      setAssignmentMenuAnchorEl(event.currentTarget.parentElement || null)
    },
    [],
  )

  const closeAssignmentMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAssignmentMenuAnchorEl(null)
    },
    [],
  )

  const closeAssignmentMenuStatus = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAssignmentMenuStatusAnchorEl(null)
    },
    [],
  )

  const addLotAssignment = React.useCallback(
    data => {
      const specializationIds =
        data?.specialization && map(data.specialization, spec => spec.id)
      const addLotData = {
        idAddTo: Number(assignment.id),
        name: data.name,
        discription: data.description,
        specializationIds,
        callback: () => getAssignmentDetail(assignment.id),
      }
      setAssignmentMenuAnchorEl(null)
      addLotToAssignment(addLotData)
      setOpenModal(false)
    },
    [addLotToAssignment, assignment.id, getAssignmentDetail],
  )

  const removeAssignment = React.useCallback(() => {
    setAssignmentMenuAnchorEl(null)
    deleteLotAssignment(Number(assignment.id), () =>
      history.push('/assignments/0'),
    )
    setOpenDialogModal(false)
  }, [assignment.id, deleteLotAssignment, history])

  const saveChangeEditAssignment = React.useCallback(() => {
    setAssignmentMenuAnchorEl(null)
    setOpenEditModal(false)
    editLotAssignment(modalInitialState.current.row, () =>
      history.push(history.location.pathname),
    )
  }, [editLotAssignment, history])

  const openEditAssignment = React.useCallback(async (): Promise<void> => {
    setAssignmentMenuAnchorEl(null)
    const [err, result] = await AssignmentService.getAssignmentById(
      assignment.id,
    )

    if (result.data.status === EAPIResponseStatus.SUCCESS) {
      if (result.data.data) {
        const rowId = result.data.data.data[0]?.rowId
        const excludeColumnList = [
          'специализация',
          'номер',
          'статус',
          'создано',
          'дата',
          '№',
          'количество поставщиков',
          'количество соглашений',
          'проект',
          'объект',
        ]
        const settings = result.data.data.columns.filter(
          el => !excludeColumnList.includes(el.title.toLowerCase()),
        )
        const data = result.data.data.data[0]?.data

        if (rowId && settings.length && data && data.length) {
          modalInitialState.current = {
            row: {
              rowId,
              data: data.filter(d =>
                settings
                  .filter(c => c.editable)
                  .some(c => Number(c.key) === Number(d.key)),
              ),
            },
            settings,
          }
          setOpenEditModal(true)
        }
      }
    } else {
      const msg =
        (result.data.status === EAPIResponseStatus.ERROR
          ? result.data.message
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (result as any).data.data) || ''
      console.error(msg)
      toastr.error('', msg)
    }
    if (err) {
      console.error(err)
    }
  }, [assignment.id])

  const changeStatusAssignmentHandler = React.useCallback(
    (key: string) => {
      setAssignmentMenuAnchorEl(null)
      setAssignmentMenuStatusAnchorEl(null)
      changeStatusAssignment(Number(assignment.id), key, () =>
        history.push(history.location.pathname),
      )
    },
    [assignment.id, changeStatusAssignment, history],
  )

  const toggleAssignmentDetails = (open: boolean) => {
    if (open) {
      const cb = (data: TAssignmentSectionData) => {
        setAssignmentDetailsData(data)
        setOpenAssignmentDetails(true)
      }
      getAssignmentSectionData(assignment.id, cb)
      getAssignmentDetail(assignment.id)
    } else {
      setAssignmentDetailsData({
        sectionsTenderRequests: [],
        tenderId: Number(assignment.id),
      })
      setOpenAssignmentDetails(false)
    }
  }

  const onOpenEditAssignmentModalClick = (
    columns: TColumn,
    row: IRow,
    sectionName: string,
  ) => {
    const data = columns.map(d => ({
      key: d.key,
      value:
        d.type === EColumnType.BOOLEAN
          ? row.data.find(rd => rd.key === d.key)?.value || 'false'
          : row.data.find(rd => rd.key === d.key)?.value || '',
    }))

    setEditAssignmentSectionModalConfig({
      data: {
        row: {
          ...row,
          data,
        },
        settings: columns,
      },
      title: sectionName,
    })

    setOpenEditAssignmentSectionModal(true)
  }

  const saveEditAssignmentSectionHandler = (row: IRow) => {
    const cb = () => {
      toggleAssignmentDetails(true)

      setOpenEditAssignmentSectionModal(false)
    }
    editLotAssignment(row, cb)
  }

  const lotsHasAgreements = assignment.lots.every(l => l.agreements.length)

  const closeAssignmentTooltipText = !lotsHasAgreements
    ? 'Чтобы закрыть тендерное задание необходимо иметь не менее одного соглашения на каждый лот'
    : ''
  return (
    <AssignmentsHeader>
      <Side>
        <BackButton
          data-test-id='assignmentHeaderBackBtn'
          onClick={() =>
            history.push({
              pathname: `/assignments/${tab}`,
              search: query.toString(),
            })
          }
        >
          {arrowBackIcon}
        </BackButton>
        <Title data-test-id='assignmentHeaderHeading'>
          {isSticky ? `ТЗ №` : `Тендерное задание №`}
          {assignment.id}
        </Title>
        {isSticky ? (
          <>
            <DividerSmall />{' '}
            <Tooltip title={assignment.name}>
              <AssignmentName>
                {assignment.name.length > 60
                  ? `${assignment.name.substring(0, 60)}...`
                  : assignment.name}
              </AssignmentName>
            </Tooltip>
          </>
        ) : null}
      </Side>
      <Side>
        <StatusInfo>
          <Name>{assignment.status}</Name>
        </StatusInfo>
        <DetailsBtn
          data-test-id='assignmentHeaderDetailsBtn'
          leftIcon={detailsIcon}
          color='secondary'
          onClick={() => toggleAssignmentDetails(true)}
        >
          Детали тендерного задания
        </DetailsBtn>
        <MenuButton
          data-test-id='assignmentHeaderMenuBtn'
          onClick={openAssignmentMenu}
          className={`${assignmentMenuAnchorEl ? ' active' : ''}`}
        >
          <MoreVert />
        </MenuButton>
        <AssignmentMenu
          id='long-menu'
          getContentAnchorEl={null}
          anchorEl={assignmentMenuAnchorEl}
          keepMounted
          open={Boolean(assignmentMenuAnchorEl)}
          onClose={closeAssignmentMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuItem
            disabled
            style={{
              backgroundColor: 'rgba(0, 0, 0 ,0)',
              cursor: 'default',
              color: 'rgba(0, 0, 0, 0.87)',
              opacity: 1,
              fontSize: '12px',
            }}
          >
            <b>Действия с Тендерным Заданием</b>
          </MenuItem>
          <MenuItem
            data-test-id='assignmentHeaderMenuAddLotBtn'
            disabled={
              assignment.status === 'Закрыто' ||
              !checkPermissions(Authorities.ASSIGNMENT_DETAILS_CREATE_LOT)
            }
            onClick={() => {
              setAssignmentMenuAnchorEl(null)
              setOpenModal(true)
            }}
          >
            Добавить лот
          </MenuItem>
          <MenuItem
            data-test-id='assignmentHeaderMenuEditBtn'
            disabled={
              assignment.status === 'Закрыто' ||
              !checkPermissions(Authorities.ASSIGNMENTS_EDIT_ITEM)
            }
            onClick={openEditAssignment}
          >
            Редактировать
          </MenuItem>
          <MenuItem
            data-test-id='assignmentHeaderMenuSendBtn'
            disabled={
              assignment.status === 'Закрыто' ||
              !checkPermissions(Authorities.ASSIGNMENTS_REMOVE_ITEM) ||
              assignment.lots.length === 0 ||
              assignment.lots.some(lot => !lot.positions.length)
            }
            onClick={() => {
              window.open(getMailerUrlSendAllTz(assignment.id), '_blank')
            }}
          >
            Отправить
          </MenuItem>
          <MenuItem
            data-test-id='assignmentHeaderMenuDeleteBtn'
            disabled={
              assignment.status === 'Закрыто' ||
              !checkPermissions(Authorities.ASSIGNMENTS_REMOVE_ITEM)
            }
            onClick={() => {
              setAssignmentMenuAnchorEl(null)
              setOpenDialogModal(true)
            }}
          >
            Удалить
          </MenuItem>
          <Tooltip
            styleContent={{ display: 'block' }}
            title={closeAssignmentTooltipText || ''}
          >
            <MenuItem
              data-test-id='assignmentHeaderMenuCloseAssignmentBtn'
              disabled={
                !assignment.lots.length ||
                assignment.status === 'Закрыто' ||
                !lotsHasAgreements
              }
              onClick={async () => {
                const [err] = await AssignmentService.changeStatusAssignment(
                  Number(assignment.id),
                  TAssignmentStatus.Closed,
                )
                //TODO error handling
                if (err) {
                  return
                }
                getAssignmentDetail(assignment.id)
              }}
            >
              Закрыть тендерное задание
            </MenuItem>
          </Tooltip>
        </AssignmentMenu>
        <AssignmentMenu
          id='long-menu'
          getContentAnchorEl={null}
          anchorEl={assignmentMenuStatusAnchorEl}
          keepMounted
          open={Boolean(assignmentMenuStatusAnchorEl)}
          onClose={closeAssignmentMenuStatus}
          anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {setStatus.map(data => (
            <MenuItem
              key={data.key}
              onClick={() => changeStatusAssignmentHandler(data.key)}
              disabled={data.value === assignment.status}
            >
              {data.value}
            </MenuItem>
          ))}
        </AssignmentMenu>
        {opeModal && (
          <LotModal
            onClose={() => setOpenModal(false)}
            onSave={addLotAssignment}
            specialization={specialisations ?? []}
          />
        )}
        {openDialogModal && (
          <DialogModal
            open={openDialogModal}
            handleDiscardChanges={() => setOpenDialogModal(false)}
            handleChanges={removeAssignment}
            modalTitle={'Удаление'}
            modalContent={`Вы действительно хотите удалить тендерное задание?`}
            modalButtonRightText={'Удалить'}
            modalButtonRightType={EButtonType.WARNING}
            modalButtonLeftText={'Отменить'}
            modalButtonLeftType={EButtonType.DEFAULT}
            dataTestIdPrefix='assignmentHeaderDeleteAssignmentModal'
          />
        )}
        {openEditModal && (
          <EditableModal
            open={openEditModal}
            initialState={modalInitialState}
            type={EEditableModalType.EDIT}
            title={{ edit: 'Редактировать тендерное задание', insert: '' }}
            specialisations={specialisations}
            onClose={() => setOpenEditModal(false)}
            onSave={saveChangeEditAssignment}
            path='assignment'
          />
        )}
      </Side>
      {openAssignmentDetails && (
        <AssignmentDetails
          open={openAssignmentDetails}
          attachments={assignment.attachments}
          data={assignmentDetailsData}
          toggleOpen={toggleAssignmentDetails}
          onOpenEditAssignmentModalClick={onOpenEditAssignmentModalClick}
          onSetExportSectionFlag={onSetExportSectionFlag}
        />
      )}
      {openEditAssignmentSectionModal && (
        <SectionEditableModal
          open={openEditAssignmentSectionModal}
          initialState={editAssignmentSectionModalConfig.data}
          title={editAssignmentSectionModalConfig.title}
          onSave={saveEditAssignmentSectionHandler}
          onClose={() => setOpenEditAssignmentSectionModal(false)}
        />
      )}
    </AssignmentsHeader>
  )
}
