import { Tooltip } from '@gmini/ui-kit'
import { Popper, PopperProps } from '@material-ui/core'
import styled from 'styled-components'

export const PositionNumber = styled.div`
  position: absolute;
  top: 11px;
  left: 3px;
  font-family: Roboto;
  font-size: 38px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.15000000596046448px;
  text-align: right;
  color: rgba(26, 26, 26, 0.1);
`

type PositionBackgroundProps = {
  background?: string
  fullWidth?: boolean
}
export const PositionBackground = styled.div`
  position: absolute;
  width: ${({ fullWidth }: PositionBackgroundProps) =>
    fullWidth ? '100%' : 'calc(100% - 1px)'};
  height: 100%;
  left: 0;
  background: ${({ background }: PositionBackgroundProps) =>
    background && `${background} `};
`

type AgreementTooltipProps = {
  width?: number
}

export const AgreementTooltip = styled.div`
  padding: 7px 6px 11px 6px;
  width: ${({ width }: AgreementTooltipProps) =>
    width ? `${width}px` : '350px'};
`
export const AgreementTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  span {
    font-weight: 700;
  }
  & svg {
    max-width: 20px;
    max-height: 20px;
  }
`
export const AgreementDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  span {
    font-size: 10px;
    line-height: 12px;
    opacity: 0.7;
  }
`
export const AgreementProject = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  span {
    font-weight: 400;
    opacity: 0.8;
  }
`

export const UpdatePriceButton = styled.div`
  width: max-content;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: rgba(85, 163, 255, 1);
  margin-top: 8px;
  cursor: pointer;
`

export const PriceUpdatedBadge = styled.div`
  background: rgba(53, 59, 96, 0.25);
  padding: 0 5px;
  border-radius: 4px;
  color: rgba(53, 59, 96, 0.6);
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-left: 4px;
`

export const HeaderWrapper = styled.div`
  width: 100%;
`

export const PriceUpdatedBadgeTooltip = styled(Tooltip)`
  margin-right: auto;
`

type PriceTextProps = {
  alignRight?: boolean
}

export const PriceText = styled.div`
  display: flex;
  justify-content: end;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  ${({ alignRight }: PriceTextProps) =>
    alignRight ? 'margin-left: auto;' : ''}
`

export const StyledPopper = styled(Popper)`
  ${({ open }: PopperProps) => (!open ? 'display: none;' : '')}

  && .MuiTooltip-tooltip {
    max-width: none;
  }
`
