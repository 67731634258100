import styled from 'styled-components'
import { Drawer, IconButton, List } from '@material-ui/core'

export const DrawerList = styled(Drawer)`
  && {
    & .MuiList-root:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    & .MuiList-root:last-child {
      padding: 8px 34px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      height: 59px;
      box-shadow: none;
      width: 100%;
    }
    & .MuiButton-label {
      font-weight: normal;
    }
    & .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0);
    }
    & .MuiDrawer-paper {
      width: 768px;
    }
    & .MuiPaper-root {
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`
export const DrawerListHeader = styled(List)`
  && {
    & h5 {
      font-size: 24px;
      color: #000;
      margin: 20px 0 15px 34px;
      font-weight: normal;
    }
    & .MuiList-padding {
      max-width: 625px;
    }
  }
`
export const DrawerListHeaderCloseBtn = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
`
export const DrawerContent = styled(List)`
  && {
    min-height: calc(100vh - 100px);
    & hr {
      margin: 0;
      width: 100%;
    }
  }
`
export const CardRowContainer = styled.div`
  margin: 24px 0;
  width: 100%;
`
