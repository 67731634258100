import React from 'react'

import { Collapse, List, ListItemText } from '@material-ui/core'
import { ArrowForwardIos, ExpandLess, MoreVert } from '@material-ui/icons'
import ListAltIcon from '@material-ui/icons/ListAlt'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial'

import { ETreeListItemType } from '../../../../../constants'
import { ITreeListItem } from '../../../../../types'

import {
  CheckedRow,
  MenuButton,
  MenuItemIcons,
  Root,
  StyledListItem,
} from '../../../styles/useListItemStyles'

export type SettingListItemProps = {
  node: ITreeListItem
  level: number
  handleMenuItemAction: (
    node: ITreeListItem,
    depthLevel: number,
  ) => (e: React.MouseEvent<HTMLElement>) => void
  handleItemClick: (id: string, type: ETreeListItemType) => () => void
  handleCheckbox: (id: string) => () => void
  open: Record<string, boolean>
  checked: string[]
  handleDragAndDrop: (params: {
    sourceId: string
    destinationId?: string
    beforeId?: string
  }) => void
  setDraggedItem: (item: ITreeListItem) => void
  draggedItem?: ITreeListItem
  dataTestIdPostfix?: string
}

export const SettingListItem: React.FC<SettingListItemProps> = ({
  node,
  level,
  handleMenuItemAction,
  handleItemClick,
  handleCheckbox,
  open,
  checked,
  handleDragAndDrop,
  setDraggedItem,
  draggedItem,
  dataTestIdPostfix,
  ...other
}) => {
  /**
   * drag and drop start
   */
  const createBeforeAfterDivTemplate = (): HTMLDivElement => {
    const div = document.createElement('div')
    div.classList.add('before-after-insert')
    div.setAttribute('data-id', node.id)
    div.setAttribute('data-parent', node.parentId || '-1')
    return div
  }

  const prepareBeforeDiv = (elem: HTMLDivElement): void => {
    elem.addEventListener<'dragenter'>('dragenter', (e: DragEvent) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()
      e.preventDefault()

      const currentNode = e.target as HTMLDivElement

      if (currentNode) {
        currentNode.classList.add('hovered')
      }
    })

    elem.addEventListener<'dragover'>('dragover', (e: DragEvent) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()

      const currentNode = e.target as HTMLDivElement

      if (currentNode) {
        const id = Number(currentNode.getAttribute('data-id'))
        const parentId = Number(currentNode.getAttribute('data-parent'))
        if (
          (!draggedItem || Number(draggedItem.id) !== id) &&
          (parentId !== -1 || draggedItem?.type !== ETreeListItemType.LIST)
        ) {
          e.preventDefault()
          return true
        }
        return false
      }

      return false
    })

    elem.addEventListener<'dragleave'>('dragleave', (e: Event) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()
      e.preventDefault()

      const currentNode = e.target as HTMLDivElement
      if (currentNode) {
        currentNode.classList.remove('hovered')
      }
    })

    elem.addEventListener<'drop'>('drop', (e: DragEvent) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()
      e.preventDefault()

      const currentNode = e.target as HTMLDivElement
      if (currentNode) {
        currentNode.classList.remove('hovered')

        const id = currentNode.getAttribute('data-id')
        const parentId = currentNode.getAttribute('data-parent') || '-1'

        const nextSibling = currentNode.nextSibling as HTMLDivElement
        if (nextSibling) {
          nextSibling.children[0].classList.remove('hovered')
        }

        document
          .querySelectorAll(`div[data-remove=""], .before-after-insert`)
          .forEach(n => n.remove())
        handleDragAndDrop({
          sourceId: draggedItem?.id || '',
          // eslint-disable-next-line no-undefined
          destinationId: Number(parentId) !== -1 ? parentId : undefined,
          // eslint-disable-next-line no-undefined
          beforeId: id || undefined,
        })
      }
    })

    elem.addEventListener<'dragend'>('dragend', (e: DragEvent) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()

      const currentNode = e.target as HTMLDivElement
      if (currentNode) {
        const nextSibling = currentNode.nextSibling as HTMLDivElement
        if (nextSibling) {
          nextSibling.children[0].classList.remove('hovered')
        }
      }
      document
        .querySelectorAll(`div[data-remove=""], .before-after-insert`)
        .forEach(n => n.remove())
    })
  }

  const prepareAfterDiv = (elem: HTMLDivElement): void => {
    elem.addEventListener<'dragenter'>('dragenter', (e: DragEvent) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()
      e.preventDefault()

      const currentNode = e.target as HTMLDivElement

      if (currentNode) {
        currentNode.classList.add('hovered')
      }
    })

    elem.addEventListener<'dragover'>('dragover', (e: DragEvent) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()

      const currentNode = e.target as HTMLDivElement

      if (currentNode) {
        const id = Number(currentNode.getAttribute('data-id'))
        const parentId = Number(currentNode.getAttribute('data-parent'))

        if (
          (!draggedItem || Number(draggedItem.id) !== id) &&
          (parentId !== -1 || draggedItem?.type !== ETreeListItemType.LIST)
        ) {
          e.preventDefault()
          return true
        }
        return false
      }

      return false
    })

    elem.addEventListener<'dragleave'>('dragleave', (e: Event) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()
      e.preventDefault()

      const currentNode = e.target as HTMLDivElement
      if (currentNode) {
        currentNode.classList.remove('hovered')
      }
    })

    elem.addEventListener<'drop'>('drop', (e: DragEvent) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()
      e.preventDefault()

      const currentNode = e.target as HTMLDivElement
      if (currentNode) {
        currentNode.classList.remove('hovered')
        const parentId = currentNode.getAttribute('data-parent') || '-1'
        let beforeId: string | undefined
        const nextElem = currentNode.nextSibling as HTMLDivElement

        if (nextElem) {
          const nextId = nextElem.getAttribute('data-id')
          // eslint-disable-next-line no-undefined
          beforeId = nextId || undefined
        }

        const prevSibling = currentNode.previousSibling as HTMLDivElement
        if (prevSibling) {
          prevSibling.children[0].classList.remove('hovered')
        }

        document
          .querySelectorAll(`div[data-remove=""], .before-after-insert`)
          .forEach(n => n.remove())

        handleDragAndDrop({
          sourceId: draggedItem?.id || '',
          // eslint-disable-next-line no-undefined
          destinationId: Number(parentId) !== -1 ? parentId : undefined,
          beforeId,
        })
      }
    })

    elem.addEventListener<'dragend'>('dragend', (e: DragEvent) => {
      if (e.defaultPrevented) {
        return
      }
      e.stopPropagation()

      const currentNode = e.target as HTMLDivElement
      if (currentNode) {
        const prevSibling = currentNode.previousSibling as HTMLDivElement
        if (prevSibling) {
          prevSibling.children[0].classList.remove('hovered')
        }
      }

      document
        .querySelectorAll(`div[data-remove=""], .before-after-insert`)
        .forEach(n => n.remove())
    })
  }

  const createGhostDragImage = (
    elem: EventTarget & HTMLDivElement,
  ): HTMLElement => {
    const ghost = elem.cloneNode() as HTMLElement
    ghost.innerHTML = elem.innerHTML
    ghost.style.position = 'absolute'
    ghost.style.zIndex = '10000'
    ghost.style.opacity = '1'
    ghost.setAttribute('data-remove', '')
    document.body.append(ghost)

    return ghost
  }
  /**
   * drag and drop end
   */

  return (
    <Root
      {...other}
      onDragEnter={(ev: React.DragEvent<HTMLDivElement>): boolean => {
        if (ev.isPropagationStopped()) {
          return false
        }

        ev.stopPropagation()
        ev.preventDefault()

        const currentNode = ev.currentTarget
        if (currentNode) {
          // Удаляем все блоки до и после у всех нод, кроме текущей
          document
            .querySelectorAll(
              `.before-after-insert:not([data-id="${node.id}"])`,
            )
            .forEach(n => n.remove())
          // Если у текущей ноды нет блоков до и после, то добавляем их
          if (
            document.querySelectorAll(
              `.before-after-insert[data-id="${node.id}"]`,
            ).length === 0 &&
            Number(draggedItem?.id) !==
              Number(currentNode.getAttribute('data-id'))
          ) {
            const prevSibling = currentNode.previousSibling
            if (prevSibling) {
              const id = Number(
                (prevSibling as HTMLElement).getAttribute('data-id'),
              )
              if (id !== Number(draggedItem?.id)) {
                const divBefore = createBeforeAfterDivTemplate()
                prepareBeforeDiv(divBefore)
                currentNode.parentNode?.insertBefore(divBefore, currentNode)
              }
            } else {
              const divBefore = createBeforeAfterDivTemplate()
              prepareBeforeDiv(divBefore)
              currentNode.parentNode?.insertBefore(divBefore, currentNode)
            }

            const { nextSibling } = currentNode
            if (nextSibling) {
              const id = Number(
                (nextSibling as HTMLElement).getAttribute('data-id'),
              )
              if (id !== Number(draggedItem?.id)) {
                const divAfter = createBeforeAfterDivTemplate()
                prepareAfterDiv(divAfter)
                currentNode.parentNode?.insertBefore(
                  divAfter,
                  currentNode.nextSibling,
                )
              }
            } else {
              const divAfter = createBeforeAfterDivTemplate()
              prepareAfterDiv(divAfter)
              currentNode.parentNode?.insertBefore(
                divAfter,
                currentNode.nextSibling,
              )
            }
          }
        }

        return true
      }}
      onDragOver={(ev: React.DragEvent<HTMLDivElement>): boolean => {
        if (ev.isPropagationStopped() || ev.isDefaultPrevented()) {
          return false
        }
        ev.stopPropagation()
        // Разрешить бросить элемент на текущую ноду только в том случае, если она не является перетаскиваемой
        const elem = ev.currentTarget
        elem.children[0].classList.add('hovered')

        const id = Number(elem.getAttribute('data-id'))
        if (
          (!draggedItem || Number(draggedItem.id) !== id) &&
          elem.getAttribute('data-node-type') === 'folder'
        ) {
          ev.preventDefault()
          return true
        }
        return false
      }}
      onDragLeave={(ev: React.DragEvent<HTMLDivElement>): void => {
        if (ev.isPropagationStopped() || ev.isDefaultPrevented()) {
          return
        }
        ev.stopPropagation()
        const elem = ev.currentTarget
        elem.children[0].classList.remove('hovered')
      }}
      onDrop={(ev: React.DragEvent<HTMLDivElement>): boolean => {
        ev.persist()
        ev.stopPropagation()

        ev.currentTarget.children[0].classList.remove('hovered')
        document
          .querySelectorAll(`div[data-remove=""], .before-after-insert`)
          .forEach(n => n.remove())
        handleDragAndDrop({
          sourceId: draggedItem?.id || '',
          destinationId: ev.currentTarget.getAttribute('data-id') || '',
        })
        return false
      }}
      onDragStart={(ev: React.DragEvent<HTMLDivElement>): boolean => {
        if (ev.defaultPrevented || ev.isPropagationStopped()) {
          return false
        }

        ev.stopPropagation()

        ev.dataTransfer.effectAllowed = 'move'
        ev.dataTransfer.setData('Text', JSON.stringify(node))

        const ghost = createGhostDragImage(ev.currentTarget)

        ev.dataTransfer.setDragImage(ghost as HTMLElement, 0, 0)

        setDraggedItem(node)

        return true
      }}
      onDragEnd={(ev: React.DragEvent<HTMLDivElement>): void => {
        if (ev.defaultPrevented) {
          return
        }
        ev.stopPropagation()

        ev.currentTarget.children[0].classList.remove('hovered')
        document
          .querySelectorAll(`div[data-remove=""], .before-after-insert`)
          .forEach(n => n.remove())
      }}
      draggable={true}
      key={node.id}
      data-node-type={node.type}
      data-id={node.id}
      style={{ marginRight: level === 0 ? '10px' : 0, background: 'white' }}
    >
      <StyledListItem
        button
        disableGutters={true}
        style={{ paddingLeft: `${level === 0 ? 5 : level * 30}px` }}
        data-test-id={`catalogSettingsListItem_${dataTestIdPostfix}`}
        onClick={handleItemClick(node.id, node.type)}
      >
        {node.type === ETreeListItemType.LIST &&
          !node.disabledActions?.check && (
            <CheckedRow
              data-test-id={`catalogSettingsListItem_${dataTestIdPostfix}_Checkbox`}
              color='primary'
              disableRipple
              checked={checked.includes(node.id)}
              onChange={handleCheckbox(node.id)}
            />
          )}
        {open[node.id] && (
          <ExpandLess
            style={{
              fontSize: '20px',
              color: '#5162d0',
            }}
          />
        )}
        {node.type !== ETreeListItemType.LIST && !open[node.id] && (
          <ArrowForwardIos
            style={{
              fontSize: '12px',
              marginRight: '5px',
              color: '#5162d0',
            }}
          />
        )}
        <ListItemText primary={node.name} />
        <MenuItemIcons>
          {node.type === ETreeListItemType.LIST && (
            <ListAltIcon color='primary' />
          )}
          {node.type === ETreeListItemType.FOLDER && (
            <FolderOpenIcon color='primary' />
          )}
          {node.type === ETreeListItemType.GROUP && <FolderSpecialIcon />}
          {!node?.disabledActions?.all && (
            <MenuButton
              data-test-id={`catalogSettingsListItem_${dataTestIdPostfix}_MenuBtn`}
              onClick={handleMenuItemAction(node, level)}
            >
              <MoreVert />
            </MenuButton>
          )}
        </MenuItemIcons>
      </StyledListItem>
      {node.type !== ETreeListItemType.LIST && (
        <Collapse in={open[node.id]} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {node.content.map((data: ITreeListItem, idx) => (
              <SettingListItem
                key={data.id}
                level={level + 1}
                node={data}
                handleMenuItemAction={handleMenuItemAction}
                handleItemClick={handleItemClick}
                handleCheckbox={handleCheckbox}
                open={open}
                checked={checked}
                setDraggedItem={setDraggedItem}
                draggedItem={draggedItem}
                handleDragAndDrop={handleDragAndDrop}
                dataTestIdPostfix={`${dataTestIdPostfix}_${idx}`}
              />
            ))}
          </List>
        </Collapse>
      )}
    </Root>
  )
}
