import React from 'react'

import { Spinner } from '../Spinner/Spinner'

import { LoaderBackground } from './Style'

type Props = {
  visible: boolean
}

export const DocumentsFieldLoader: React.FC<Props> = ({ visible }) =>
  visible ? (
    <LoaderBackground>
      <Spinner />
    </LoaderBackground>
  ) : null
