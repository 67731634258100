import React, { useEffect } from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'

import { TextField } from '../../../../../components/TextField/TextField'

import { FilterText } from '../Filter.styled'

import { RadioButton, RadioGroupWrapper } from './PositionFilter.styled'

enum FilterPosition {
  ALL = 'ALL',
  BY_DATE = 'BY_DATE',
}

type PositionFilterComponentProps = {
  readonly setDate: (value: string | null) => void
  readonly date: string | null
  readonly onReset: () => void
}
export const PositionFilter = ({
  date,
  setDate,
  onReset,
}: PositionFilterComponentProps) => {
  const [filterValue, setFilterValue] = React.useState<FilterPosition>(
    FilterPosition.ALL,
  )

  useEffect(() => {
    if (date && filterValue !== FilterPosition.BY_DATE) {
      setFilterValue(FilterPosition.BY_DATE)
    }
  }, [date, filterValue])

  useEffect(() => {
    if (!date) {
      setFilterValue(FilterPosition.ALL)
    }
  }, [date])

  return (
    <>
      <FilterText>
        По дате соглашения в прошедших гранд-тендер позициях:
      </FilterText>

      <RadioGroupWrapper>
        <FormControlLabel
          data-test-id='filterByAllDatesRadioBtn'
          onClick={() => {
            onReset()
            setFilterValue(FilterPosition.ALL)
          }}
          checked={filterValue === FilterPosition.ALL}
          control={<RadioButton color='primary' />}
          label='Все даты'
        />
        <FormControlLabel
          data-test-id='filterByCertainDateRadioBtn'
          onClick={() => setFilterValue(FilterPosition.BY_DATE)}
          checked={filterValue === FilterPosition.BY_DATE}
          control={<RadioButton color='primary' />}
          label='Позиции с соглашениями, актуальными на дату'
        />

        <TextField
          type='date'
          id='date'
          name='date'
          value={date || ''}
          onChange={event => {
            setDate(event.target.value)
          }}
          style={{
            width: '240px',
            visibility:
              filterValue === FilterPosition.BY_DATE ? 'visible' : 'hidden',
            paddingLeft: '30px',
            margin: 0,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '21px',
          }}
          error={false}
          inputProps={{ 'data-test-id': 'filterByCertainDateInput' }}
        />
      </RadioGroupWrapper>
    </>
  )
}
