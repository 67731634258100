import React, { useCallback, useEffect, useState } from 'react'

import { PopoverOrigin } from '@material-ui/core/Popover/Popover'

import { EButtonType, ETreeListItemType } from '../../constants'

import { ButtonComponent } from '../Button/Button'

import { PositionCatalogMini } from '../PositionCatalogMini/PositionCatalogMini'

import { flatten } from '../../utils'

import * as I from './IListEditor'

import { ListEditorItem } from './ListEditorItem/ListEditorItem'
import { Catalog, Root, StyledList } from './Style'

const popupAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}

const popupTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

export const ListEditor: React.FC<I.OwnProps> = ({
  ids,
  catalog,
  onAdd,
  onDelete,
  dataTestIdPrefix,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const [names, setNames] = useState<Record<string, string>>({})

  useEffect(() => {
    const obj: Record<string, string> = {}
    flatten(catalog)
      .filter(item => item.type === ETreeListItemType.LIST)
      .forEach(item => {
        obj[item.id] = item.name
      })
    setNames(obj)
  }, [catalog])

  const openPopup = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const closePopup = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const selectItem = useCallback(
    (selectedId: string) => {
      closePopup()
      if (!ids.some(id => id === selectedId)) {
        onAdd(selectedId)
      }
    },
    [closePopup, ids, onAdd],
  )

  return (
    <Root>
      <ButtonComponent
        data-test-id={`${dataTestIdPrefix}ListEditorAddBtn`}
        text='Добавить'
        type={EButtonType.DEFAULT}
        onClick={openPopup}
      />
      <Catalog
        anchorEl={anchorEl}
        id='catalog-popover'
        anchorOrigin={popupAnchorOrigin}
        transformOrigin={popupTransformOrigin}
        open={Boolean(anchorEl)}
        onClose={closePopup}
      >
        <PositionCatalogMini
          dataTestIdPrefix={`${dataTestIdPrefix}ListEditor`}
          anchorEl={anchorEl}
          catalog={catalog}
          onSelectNode={selectItem}
        />
      </Catalog>
      <StyledList dense={true}>
        {ids.map((id: string, idx) => (
          <ListEditorItem
            dataTestIdPrefix={`${dataTestIdPrefix}ListEditorItem_${idx}`}
            key={id}
            value={names[id] ?? '???'}
            onDelete={(): void => onDelete(id)}
          />
        ))}
      </StyledList>
    </Root>
  )
}
