import { IconButton } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'
import { AutocompleteSearch, Tabs } from '@ifellow/ui-library'

export const SettingsButton = styled(IconButton)`
  && {
    margin-left: auto;
    margin-right: 0;
  }
`
export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const StyledTabs = styled(Tabs)`
  && {
    flex-grow: 1;
    flex-shrink: 1;
    z-index: 1;
    & .tab.Mui-selected {
      pointer-events: none;
    }
  }
`
export const Search = styled(AutocompleteSearch)`
  && {
    width: 350px;
    flex-shrink: 0;
  }
`
export const Content = styled.div`
  display: flex;
  font-family: 'Proxima Nova Regular';
  margin: 0 -15px;
  height: calc(100% - 82px);
`

type FlexItemProps = {
  tabPanel?: boolean
}

export const FlexItem = styled.div`
  margin: 0 15px;
  ${({ tabPanel }: FlexItemProps) =>
    tabPanel
      ? css`
          flex: 1 1 auto;
          width: 100px;
          margin-top: 25px;
        `
      : ''}
`

export const Actions = styled.div`
  display: flex;
  margin: 0 0 20px 0;
  align-items: center;
  & button {
    margin-right: 5px;
  }
`
export const FloatedActions = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`
export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
