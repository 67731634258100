/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete'

import { IKeyValuePair } from '../../types'
import { EButtonType } from '../../constants'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'

import { ButtonComponent } from '../Button/Button'

import { SearchInput } from '../SearchInput/SearchInput'

import * as I from './IAddToAssignmentModal'
import { ButtonContainer, Root } from './Style'

export const AddToAssignmentModal: React.FC<I.EnchancedProps> = ({
  open,
  dataIsLoading,
  assignmentOptionsListOpen,
  lotOptionsListOpen,
  assignmentValues,
  lotValues,
  errors,
  onSelectedValueChanged,
  toggleAssignmentOptionsList,
  toggleLotOptionsList,
  onAssignmentSearchInputChange,
  onSave,
  onClose,
}): React.ReactElement => (
  <div>
    {open && (
      <Root
        onClose={onClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        disableBackdropClick={true}
      >
        <ModalTitle
          dataTestIdPrefix='addToAssignmentModal'
          id='customized-dialog-title'
          onClose={onClose}
        >
          Добавить в тендерное задание
        </ModalTitle>
        <ModalContent dividers>
          <SearchInput
            label={'Тендерное задание:'}
            open={assignmentOptionsListOpen}
            data={assignmentValues}
            dataIsLoading={dataIsLoading}
            helperText={errors.assignmentId}
            onOpen={(): void => toggleAssignmentOptionsList(true)}
            onClose={(): void => toggleAssignmentOptionsList(false)}
            filterFunc={(
              options: Array<IKeyValuePair>,
              s: FilterOptionsState<IKeyValuePair>,
            ): Array<IKeyValuePair> => options}
            onChange={onSelectedValueChanged('assignmentId')}
            onInputChange={onAssignmentSearchInputChange}
            dataTestIdPrefix='addToAssignmentModalAssignment'
          />

          <SearchInput
            label={'Лот:'}
            open={lotOptionsListOpen}
            data={lotValues}
            dataIsLoading={dataIsLoading}
            helperText={errors.lotId}
            onOpen={(): void => toggleLotOptionsList(true)}
            onClose={(): void => toggleLotOptionsList(false)}
            onChange={onSelectedValueChanged('lotId')}
            dataTestIdPrefix='addToAssignmentModalLot'
          />
        </ModalContent>
        <ModalActions>
          <ButtonContainer>
            <ButtonComponent
              data-test-id='addToAssignmentModalCancelBtn'
              text='Отменить'
              type={EButtonType.DEFAULT}
              onClick={onClose}
            />
            <ButtonComponent
              data-test-id='addToAssignmentModalSubmitBtn'
              text='Добавить в тендерное задание'
              type={EButtonType.PRIMARY}
              disabled={dataIsLoading}
              onClick={onSave}
            />
          </ButtonContainer>
        </ModalActions>
      </Root>
    )}
  </div>
)
