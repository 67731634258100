import React from 'react'
import { Tooltip } from '@material-ui/core'

import { HeaderCell } from './TableHeader.styled'

type TTableHeader = {
  headerName: string
}

export const TableHeader: React.FC<TTableHeader> = ({ headerName }) => (
  <div>
    <Tooltip title={headerName}>
      <HeaderCell>{headerName}</HeaderCell>
    </Tooltip>
  </div>
)
