import { Drawer, List } from '@material-ui/core'
import styled from 'styled-components'

export const Root = styled(Drawer)`
  & .MuiList-root:last-child {
    padding: 8px 24px 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 59px;
    box-shadow: 0px -5px 30px 0px rgba(50, 50, 50, 0.05);
  }
  & .MuiButton-label {
    font-weight: normal;
  }
  & .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0);
  }
  & > .MuiPaper-root {
    width: 660px;
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`
export const DrawerHeader = styled(List)`
  && {
    padding: 20px 20px 5px 26px;
  }
`
export const DrawerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const DrawerContent = styled(List)`
  min-height: calc(100vh - 160px);
`
export const CardRowContainer = styled.div`
  margin: 0 16px 20px 16px;
`
export const CardRowBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  & .MuiFormControl-root {
    margin: 0 10px;
    max-width: 280px;
  }
  & .MuiInputBase-input {
    line-height: 25px;
  }
  & .MuiInputBase-root {
    margin-top: 0;
  }

  & label {
    margin-left: 0;
    position: relative;
  }
`
export const AccordionBody = styled.div`
  padding: 10px 0;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const DrawerFooter = styled(List)`
  position: sticky;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
`
export const SecondPartFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & button {
    margin: 0 0 0 10px;
  }
`
