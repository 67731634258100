import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'

export const FormContent = styled.div`
  min-height: 230px;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`

export const Divider = styled.div`
  margin-bottom: 20px;
`

export const EditNoteIcon = styled(EditIcon)`
  && {
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
`
