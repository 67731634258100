import styled from 'styled-components'
import { Button, IconButton } from '@gmini/ui-kit'
import { Menu } from '@material-ui/core'

export const AssignmentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 100%;
`

export const Side = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const StatusInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
`

export const Name = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #4c5ecf;
  margin: 0;
`

export const AssignmentName = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #353b60;
`

export const DividerSmall = styled.div`
  height: 20px;
  width: 1px;
  background: #e5e7f1;
`

export const DetailsBtn = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353b60;
  gap: 6px;
  height: 36px;
  svg {
    padding: 3px;
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
  }
`

export const BackButton = styled(IconButton)`
  width: 36px;
  height: 36px;
  background: rgba(0, 0, 61, 0.05);
  svg {
    width: 16px;
    height: 16px;
    min-height: 16px;
    min-width: 16px;
  }
  path {
    fill: #4c5ecf;
  }
`

export const MenuButton = styled(IconButton)`
  padding: 0;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  background-color: #f9f9fb;

  &.active {
    background: #4c5ecf;
    path {
      fill: #ffffff;
    }
  }
`

export const Title = styled.div`
  font-size: 17px;
  line-height: 20px;
  color: #353b60;
`

export const AssignmentMenu = styled(Menu)`
  & li {
    font-family: 'Proxima Nova Regular';
    font-size: 14px;
  }

  & .MuiListItem-button:hover {
    background-color: #f2f0f8;
    border-radius: 5px;
  }

  & ul {
    margin: 7px;
    padding: 0;
    min-width: 270px;
  }

  & .MuiSvgIcon-root {
    background-color: rgba(0, 0, 0, 0);
    color: white;
    font-size: 10px;
  }

  & .MuiListItem-gutters {
    padding-left: 8px;
    padding-right: 8px;
  }
`
