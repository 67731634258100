import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export const Root = styled(Dialog)`
  && {
    margin: 0 auto;
    & .MuiDialog-paperWidthSm {
      max-width: 535px;
      height: 347px;
    }
    & .MuiPaper-root {
      margin: 32px;
      position: relative;
      overflow: hidden;
    }
  }
`
export const Title = styled.div`
  width: 635px;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  color: #1b2b4b;
`

export const Label = styled.div`
  padding-top: 12px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  font-style: normal;
  font-weight: bold;
`
