import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

type StyledLinkProps = {
  active?: boolean
}

export const StyledLink = styled(Link)`
  color: rgba(255, 255, 255, 0.6);
  fill: rgba(255, 255, 255, 0.6);
  padding: 0 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ active }: StyledLinkProps) =>
    active
      ? css`
          background-color: #4353b4;
          fill: #fff;
          color: #fff;
          pointer-events: none;
        `
      : ''}
  &:hover {
    color: #fff;
    fill: #fff;
  }
`

export const LinkText = styled.div`
  margin-left: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  -webkit-font-smoothing: auto;
`
