import styled from 'styled-components'
import { Toolbar, Typography, Popover } from '@material-ui/core'

type RootProps = {
  highlight: boolean
}

export const Root = styled(Toolbar)`
  padding-left: 8px;
  padding-right: 8px;
  min-height: 44px !important;
  margin: 25px 0 20px 0;
  &.MuiToolbar-root {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  &.MuiToolbar-regular {
    min-height: 0;
  }
  ${({ highlight }: RootProps) => (highlight ? 'color: #000000;' : '')}
`

export const Title = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & .title {
    margin-bottom: -5px;
  }
  & .header {
    font-size: 24px;
    line-height: 29px;
    font-family: 'Proxima Nova Regular';
    color: #1b2b4b;
    word-break: break-all;
  }
  & .content {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    font-family: 'Proxima Nova Regular';
    color: #535f77;
    margin-top: 5px;
    word-break: break-all;
  }
` as typeof Typography
export const ButtonContainer = styled.div`
  display: flex;
  margin: 0 -5px;
  & button {
    margin: 0 5px;
  }
`

export const FilterButtonContainerList = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    border-radius: 5px;
    & .MuiSvgIcon-root {
      width: 14px;
      height: 10px;
      margin: 0 0 0 5px;
    }
  }
`

export const MenuCatalog = styled(Popover)`
  & .MuiPaper-root {
    max-height: 550px;
    min-height: 250px;
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`
