export const keycloakUrl = getEnv('SPA_KEYCLOAK_URL')
export const gtmId = getEnv('SPA_GTM_ID')
export const wsUrlV2 = getEnv('SPA_WS_URL_V2')
export const mailerUrl = getEnv('SPA_MAILER_URL')
export const omniManagementServerUrl = getEnv('SPA_OMNI_MANAGEMENT_SERVER_URL')
export const realm = getEnv('SPA_KEYCLOAK_REALM')
export const envLinks = getEnv('SPA_LINKS_CONFIG')
export const sentryDsn = getEnv('SPA_SENTRY_DSN')
export const spaEnv = getEnv('SPA_ENV')

function getEnv(name: string): string {
  const value: null | undefined | string = (globalThis as any)[name]

  if (value == null) {
    console.warn(`\`${name}\` environment variable is not defined`)
    return ''
  }

  return value
}
