import { Popover, TableContainer, IconButton } from '@material-ui/core'
import styled from 'styled-components'

export const StyledTableContainer = styled(TableContainer)`
  && {
    width: 270px;
  }
  & .MuiTableCell-root {
    padding: 0;
  }
  & .MuiIconButton-label:hover,
  .MuiIconButton-label:focus,
  .MuiIconButton-label:active {
    background-color: rgba(0, 0, 0, 0);
  }
`
export const ColumnIconBtn = styled.div`
  & button:hover,
  & button:focus,
  & button:active {
    background-color: rgba(0, 0, 0, 0);
  }
  & .MuiButton-root {
    min-width: 16px;
    float: right;
  }
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & p {
    font-size: 12px;
    color: #535f77;
    margin: 16px 0 10px 13px;
    font-weight: bold;
    padding: 0;
  }
`
export const ActionButton = styled(IconButton)`
  && {
    flex: 1 1 20px;
    padding: 14px;
    max-width: 20px;
    max-height: 20px;
    margin-right: 3px;
  }
  & .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0);
    font-size: 18px;
  }
`
export const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`
