import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export const StyledDialog = styled(Dialog)`
  margin: 0;
  padding: theme.spacing(2);
  & .MuiDialog-paperWidthSm {
    max-width: 645px;
  }
`
export const CloseButton = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  color: #9e9e9e;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  & button {
    margin: 0 5px;
  }
`
export const Autocomplete = styled.div`
  & .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  & .MuiFormHelperText-root {
    color: red;
  }
`
export const AutocompletePaper = styled.div`
  & .MuiAutocomplete-listbox {
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }
  }
`
