import {
  Drawer,
  IconButton,
  List,
  TableContainer,
  Checkbox,
  TextField,
  Popover,
} from '@material-ui/core'
import styled from 'styled-components'

export const CardRowSpaceBetweenText = styled(TextField)`
  & input {
    color: #616284;
    font-size: 0.85em;
  }
  & .MuiInputBase-input {
    text-align: right;
  }
`

export const CardMultiline = styled.div`
  display: flex;
  margin: 0 -5px;
  justify-content: flex-end;
  & .flex-item {
    margin: 0 5px;
  }
  & .column {
    display: flex;
    flex-direction: column;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & p {
    font-size: 12px;
    color: #535f77;
    font-weight: bold;
    padding: 0;
  }
`

export const DrawerListHeaderCloseBtn = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
`
export const DrawerFooter = styled(List)`
  position: sticky;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
`
export const SecondPartFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & button {
    margin: 0 0 0 10px;
  }
`
export const DrawerContent = styled(List)`
  min-height: calc(100vh - 145px);
  & hr {
    margin: 0 0 0 34px;
  }
`
export const CardRowContainer = styled.div`
  margin: 0 24px 20px 34px;
`
export const CardRowTitle = styled.h4`
  color: #4c5ecf;
`
export const CardRowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    flex: 1 1 48%;
  }
`

export const TableContainerStyled = styled(TableContainer)`
  width: 270px;
  & .MuiTableCell-root {
    padding: 0;
  }
  & .MuiIconButton-label:hover,
  .MuiIconButton-label:focus,
  .MuiIconButton-label:active {
    background-color: rgba(0, 0, 0, 0);
  }
`
export const CheckedRow = styled(Checkbox)`
  margin: 0 0 0 1px;
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0);
  }
  & .checkboxChecked,
  & .checkboxChecked:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  & .MuiTableCell-root {
    border: 0;
  }
`
export const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    box-shadow: 0px 5px 10px rgba(53, 60, 96, 0.15);
    border-radius: 4px;
    margin: 3px 0 0 0;
    width: 270px;
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`
export const DrawerList = styled(Drawer)`
  & .MuiList-root:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  & .MuiList-root:last-child {
    padding: 8px 24px 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 59px;
    box-shadow: 0px -5px 30px 0px rgba(50, 50, 50, 0.05);
    width: 645px;
  }
  & .MuiButton-label {
    font-weight: normal;
  }
  & .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0);
  }
  & .MuiDrawer-paper {
    width: 660px;
    &::-webkit-scrollbar {
      width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`
export const DrawerListHeader = styled(List)`
  & h5 {
    font-size: 24px;
    color: #000;
    margin: 20px 0 15px 34px;
    font-weight: normal;
  }
  & .MuiList-padding {
    max-width: 625px;
  }
`
export const CardRowBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -10px;
  & .MuiFormControl-root {
    margin: 0 10px;
  }
  & .MuiInputBase-input {
    line-height: 25px;
  }
  & label {
    margin-left: 0;
  }
`
