import styled from 'styled-components'
import Radio from '@material-ui/core/Radio'

export const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 28px;
`
export const PositionFilterWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const RadioButton = styled(Radio)`
  & .MuiIconButton-label {
    color: #353b60;
  }
`
