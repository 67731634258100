import styled from 'styled-components'
import { Drawer, TextField, List, IconButton } from '@material-ui/core'

export const DrawerList = styled(Drawer)`
  && {
    & .MuiList-root:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    & .MuiList-root:last-child {
      padding: 8px 24px 7px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 59px;
      box-shadow: 0px -5px 30px 0px rgba(50, 50, 50, 0.05);
      width: 645px;
    }
    & .MuiButton-label {
      font-weight: normal;
    }
    & .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0);
    }
    & .MuiDrawer-paper {
      width: 660px;
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`
export const DrawerListHeader = styled(List)`
  && {
    & h5 {
      font-size: 24px;
      color: #000;
      margin: 20px 0 15px 34px;
      font-weight: normal;
    }
    & .MuiList-padding {
      max-width: 625px;
    }
  }
`
export const DrawerListHeaderCloseBtn = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
`
export const SecondPartFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & button {
    margin: 0 0 0 10px;
  }
`
export const DrawerContent = styled(List)`
  && {
    min-height: calc(100vh - 145px);
    & hr {
      margin: 0 0 0 34px;
    }
  }
`
export const CardRowContainer = styled.div`
  margin: 0 24px 20px 34px;
`
export const CardRowTitle = styled.h4`
  color: #4c5ecf;
`
export const CardRowBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -10px;
  & .MuiFormControl-root {
    margin: 0 10px;
  }
  & .MuiInputBase-input {
    line-height: 25px;
  }
  & label {
    margin-left: 0;
  }
`
export const CardRowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`
export const CardRowSpaceBetweenText = styled(TextField)`
  && {
    & textarea {
      color: #616284;
      font-size: 0.85em;
    }
    & .MuiInputBase-input {
      text-align: right;
    }
  }
`
export const CardMultiline = styled.div`
  display: flex;
  margin: 0 -5px;
`

export const FlexItem = styled.span`
  margin: 0 5px;
  white-space: wrap;
`

export const Column = styled.div`
  ${FlexItem}
  display: flex;
  flex-direction: column;
`
