import React from 'react'
import _ from 'lodash'
import { Checkbox, TextField, Tooltip } from '@material-ui/core'

import { editIcon } from '../../../../../../constants'
import { formatValue } from '../../../../../../utils'
import { TRowData } from '../../../../../../types'

import { Accordion } from '../../../../../Accordion/Accordion'

import * as I from './IAssignmentDetailsSection'
import { CardRowBody, DrawerEditBtn, Lot } from './Style'

export const AssignmentDetailsSection: React.FC<I.OwnProps> = ({
  data,
  onOpenEditAssignmentModalClick,
  onSetExportSectionFlag,
}): React.ReactElement => {
  const valuesData: TRowData = []

  data.listView.data.forEach(d => _.extend(valuesData, d.data))

  return (
    <Accordion
      dataTestIdPrefix='assignmentDetailsSection'
      title={data.sectionName}
      actions={
        data.listView.columns?.length ? (
          <>
            <Tooltip title='Отображать секцию в экспорте'>
              <Checkbox
                data-test-id='assignmentDetailsSectionIncludeInExportCheckbox'
                checked={data.export}
                onClick={(event): void => {
                  event.stopPropagation()
                  onSetExportSectionFlag(data.sectionId, !data.export)
                }}
              />
            </Tooltip>
            <Tooltip title='Редактировать поля в секции'>
              <DrawerEditBtn
                data-test-id='assignmentDetailsSectionEditBtn'
                onClick={(event): void => {
                  event.stopPropagation()
                  onOpenEditAssignmentModalClick(
                    data.listView.columns,
                    data.listView.data[0],
                    data.sectionName,
                  )
                }}
              >
                {editIcon}
              </DrawerEditBtn>
            </Tooltip>
          </>
        ) : (
          <></>
        )
      }
    >
      <Lot>
        <CardRowBody>
          {data.listView.columns.map(c => (
            <TextField
              key={c.key}
              label={c.title}
              multiline={true}
              maxRows={50}
              value={
                data.listView.data
                  ? formatValue(
                      c.type,
                      valuesData.find(d => d.key === c.key)?.value,
                      '-',
                      c.unit,
                    )
                  : '-'
              }
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
          ))}
        </CardRowBody>
      </Lot>
    </Accordion>
  )
}
