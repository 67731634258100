/* eslint-disable no-undefined */
import React, { useState } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import InputMask from 'react-input-mask'
import {
  FormControl,
  FormControlLabel,
  TextField,
  InputAdornment,
  Switch,
  FormHelperText,
} from '@material-ui/core'

import { IColumn, IKeyValuePair, IRow } from '../../types'
import {
  EColumnType,
  EButtonType,
  TValidateResult,
  EValidationMessage,
  REVERSE_DISPLAY_DATE_FORMAT,
} from '../../constants'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'

import { ButtonComponent } from '../Button/Button'
import { DialogModal } from '../DialogModal/DialogModal'
import { useModalValidation } from '../../hooks/useModalValidation'

import * as I from './ISectionEditableModal'
import { ButtonContainer, Root } from './Style'

export const SectionEditableModal: React.FC<I.OwnProps> = ({
  open,
  initialState,
  title,
  onClose,
  onSave,
}): React.ReactElement => {
  const [state, setState] = useState<IRow>({ ...initialState.row })
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    ..._.reduce(
      initialState.row.data,
      (acc, { key }) => ({ ...acc, [key]: '' }),
      {},
    ),
  })

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)

  const validationHelper = useModalValidation()

  const validate = React.useCallback(
    (prop: IColumn, value: string): TValidateResult => {
      let validationResult: TValidateResult

      switch (prop.type) {
        case EColumnType.STRING: {
          validationResult = prop.required
            ? validationHelper.validateRequiredFiled(value)
            : { valid: true, message: EValidationMessage.NONE }
          break
        }
        case EColumnType.PHONE: {
          validationResult = validationHelper.validatePhoneField(
            value,
            prop.required,
          )
          break
        }
        case EColumnType.BOOLEAN: {
          validationResult = validationHelper.validateBooleanField(value)
          break
        }
        case EColumnType.DATE: {
          validationResult = validationHelper.validateDateField(
            value,
            prop.required,
          )
          break
        }
        case EColumnType.DOUBLE:
        case EColumnType.CURRENCY: {
          validationResult = validationHelper.validateFloatField(
            value,
            prop.required,
          )
          break
        }
        default: {
          throw new Error(`Неизвестный тип колонки: ${JSON.stringify(prop)}`)
        }
      }

      setErrors({ ...errors, [prop.key]: validationResult?.message })
      return validationResult
    },
    [errors, validationHelper],
  )

  const handleBooleanPropChanged = React.useCallback(
    (key: string): void => {
      const newState = _.cloneDeep(state)
      const item = newState.data.find(prop => prop.key === key)

      if (item) {
        item.value = (item.value !== 'true').toString()
        setState(newState)
      }
    },
    [state],
  )

  const handleStringPropChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, prop: IColumn): void => {
      validate(prop, event.target.value)

      const newState = _.cloneDeep(state)
      const item = newState.data.find(p => p.key === prop.key)

      if (item) {
        item.value = event.target.value
        setState(newState)
      }
    },
    [state, validate],
  )

  const handlePhonePropChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, prop: IColumn): void => {
      validate(prop, event.target.value)

      const newState = _.cloneDeep(state)
      const item = newState.data.find(p => p.key === prop.key)

      if (item) {
        item.value =
          event.target.value === '+7 (***) ***-**-**' ? '' : event.target.value
        setState(newState)
      }
    },
    [state, validate],
  )

  const handleIntegerPropChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, prop: IColumn): void => {
      validate(prop, event.target.value)

      const newState = _.cloneDeep(state)
      const item = newState.data.find(p => p.key === prop.key)

      if (item) {
        item.value = event.target.value && event.target.value.trim()
        setState(newState)
      }
    },
    [state, validate],
  )

  const handleDatePropChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, prop: IColumn): void => {
      validate(prop, event.target.value)

      const newState = _.cloneDeep(state)
      const item = newState.data.find(p => p.key === prop.key)

      if (item) {
        item.value = event.target.value && event.target.value.trim()
        item.value = moment(event.target.value).format('YYYY-MM-DDTHH:mm')
        setState(newState)
      }
    },
    [state, validate],
  )

  const saveBtnHandler = (): void => {
    const validationResults = validationHelper.validateSectionEditableModal(
      initialState.settings,
      state.data,
    )

    if (_.values(validationResults).some(vr => !vr.valid)) {
      setErrors(
        _.keys(validationResults).reduce((prev, curr) => {
          prev[curr as keyof IKeyValuePair] =
            validationResults[curr as keyof IKeyValuePair].message
          return prev
        }, {} as { [key in keyof IKeyValuePair]: string }),
      )
      return
    }

    onSave(state)
  }

  const checkIfHasChanges = React.useCallback((): boolean => {
    if (
      state.data.some(
        d =>
          d.value !== initialState.row.data.find(fd => fd.key === d.key)?.value,
      )
    ) {
      return true
    }

    return false
  }, [initialState.row.data, state.data])

  const closeWindow = React.useCallback((): void => {
    setErrors({
      ..._.reduce(
        initialState.row.data,
        (acc, { key }) => ({ ...acc, [key]: '' }),
        {},
      ),
    })
    onClose()
  }, [initialState.row.data, onClose])

  const onDialogClose = React.useCallback((): void => {
    if (checkIfHasChanges()) {
      setOpenConfirmModal(true)
    } else {
      closeWindow()
    }
  }, [checkIfHasChanges, closeWindow])

  const renderBaseProperties = initialState.settings.map(prop => {
    switch (prop.type) {
      case EColumnType.BOOLEAN:
        return (
          <FormControl key={prop.key} error={errors[prop.key]?.length > 0}>
            <FormControlLabel
              key={prop.key}
              className='prop'
              control={
                <Switch
                  checked={
                    state.data.find(r => r.key === prop.key)?.value ===
                      'true' || false
                  }
                  onChange={(): void => handleBooleanPropChanged(prop.key)}
                  name={String(prop.key)}
                  required={prop.required}
                  data-test-id={`sectionEditableModalPropInput_${prop.key}`}
                />
              }
              label={prop.title}
              labelPlacement='top'
            />
            <FormHelperText>{errors[prop.key]}</FormHelperText>
          </FormControl>
        )
      case EColumnType.STRING:
        return (
          <FormControlLabel
            key={prop.key}
            className='prop'
            control={
              <TextField
                type='text'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                variant='filled'
                value={state.data.find(p => p.key === prop.key)?.value || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleStringPropChanged(e, prop)
                }
                InputProps={{
                  endAdornment: prop.unit ? (
                    <InputAdornment position='end'>{prop.unit}</InputAdornment>
                  ) : (
                    undefined
                  ),
                  inputMode: 'text',
                }}
                inputProps={{
                  'data-test-id': `sectionEditableModalPropInput_${prop.key}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.PHONE:
        return (
          <FormControlLabel
            key={prop.key}
            className='prop'
            control={
              <InputMask
                mask='+7 (999) 999-99-99'
                maskChar='*'
                alwaysShowMask={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handlePhonePropChanged(e, prop)
                }
                value={
                  state.data.find(p => p.key === prop.key)
                    ? state.data.find(p => p.key === prop.key)?.value
                    : ''
                }
              >
                {(): JSX.Element => (
                  <TextField
                    type='phone'
                    required={prop.required}
                    error={errors[prop.key]?.length > 0}
                    helperText={errors[prop.key]}
                    variant='filled'
                    inputProps={{
                      'data-test-id': `sectionEditableModalPropInput_${prop.key}`,
                    }}
                  />
                )}
              </InputMask>
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.DOUBLE:
      case EColumnType.CURRENCY:
        return (
          <FormControlLabel
            key={prop.key}
            className='prop'
            control={
              <TextField
                type='number'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                variant='filled'
                value={state.data.find(p => p.key === prop.key)?.value || ''}
                onKeyDown={(event): boolean => {
                  if (
                    ![
                      '0',
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      '6',
                      '7',
                      '8',
                      '9',
                      ',',
                      '.',
                      'Backspace',
                    ].some(r => event.key === r)
                  ) {
                    event.preventDefault()
                    return false
                  }
                  return true
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleIntegerPropChanged(e, prop)
                }
                InputProps={{
                  endAdornment: prop.unit ? (
                    <InputAdornment position='end'>{prop.unit}</InputAdornment>
                  ) : (
                    undefined
                  ),
                  inputMode: 'numeric',
                }}
                inputProps={{
                  'data-test-id': `sectionEditableModalPropInput_${prop.key}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.DATE:
        return (
          <FormControlLabel
            key={prop.key}
            className='prop'
            control={
              <TextField
                type='date'
                id='date'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleDatePropChanged(e, prop)
                }
                style={{
                  width: 279,
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
                value={
                  state.data.find(p => p.key === prop.key)?.value
                    ? moment(
                        state.data.find(p => p.key === prop.key)?.value,
                      ).format(REVERSE_DISPLAY_DATE_FORMAT)
                    : undefined
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  'data-test-id': `sectionEditableModalPropInput_${prop.key}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      default:
        throw new Error(`Неизвестный тип колонки: ${JSON.stringify(prop)}`)
    }
  })

  return (
    <div>
      {openConfirmModal && (
        <DialogModal
          open={openConfirmModal}
          modalTitle={'Внимание!'}
          modalContent={'Есть несохранённые изменения. Сохранить?'}
          handleDiscardChanges={closeWindow}
          handleChanges={saveBtnHandler}
          modalButtonRightText={'Да'}
          modalButtonRightType={EButtonType.PRIMARY}
          modalButtonLeftText={'Нет'}
          modalButtonLeftType={EButtonType.DEFAULT}
          dataTestIdPrefix='sectionLeaveChangesWarningModal'
        />
      )}
      {open && (
        <Root
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          hidden={openConfirmModal}
        >
          <ModalTitle
            dataTestIdPrefix='sectionEditableModal'
            id='customized-dialog-title'
            onClose={onDialogClose}
          >
            {title}
          </ModalTitle>
          <ModalContent dividers>
            <div className='propContainer'>{renderBaseProperties}</div>
          </ModalContent>
          <ModalActions>
            <ButtonContainer>
              <ButtonComponent
                data-test-id={`sectionEditableModalCancelBtn`}
                text='Отменить'
                type={EButtonType.DEFAULT}
                onClick={onDialogClose}
              />
              <ButtonComponent
                data-test-id={`sectionEditableModalSubmitBtn`}
                text={'Сохранить'}
                type={EButtonType.PRIMARY}
                onClick={saveBtnHandler}
              />
            </ButtonContainer>
          </ModalActions>
        </Root>
      )}
    </div>
  )
}
