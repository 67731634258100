import styled from 'styled-components'
import { Typography, Toolbar, Popper } from '@material-ui/core'

export const Description = styled.div`
  width: 370px;
  display: flex;
  flex-direction: column;
  @media (min-width: 1536px) {
    width: 500px;
  }
  @media (min-width: 1680px) {
    width: 700px;
  }
`

export const Title = styled(Typography).attrs({ component: 'div' })`
  && {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 29px;
    font-family: Proxima Nova Regular;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const MenuList = styled.div`
  & li {
    font-family: 'Proxima Nova Regular';
  }
  & .menu-title {
    opacity: 1;
  }
`

type RootProps = {
  highlight?: boolean
  isSticky: boolean
  offsetTop: number
}

export const Root = styled(Toolbar)`
  && {
    position: sticky;
    z-index: 9;
    background: #fff;
    margin: 0 -34px;
    padding: 16px 34px;
    & .MuiToolbar-root {
      display: flex;
      justify-content: space-between;
    }
    & .MuiToolbar-regular {
      min-height: 0;
    }
    & .MuiTypography-root {
      max-width: 100vw;
      word-break: break-word;
      padding: 0 10px 0 0;
    }
    ${({ highlight }: RootProps) => (highlight ? 'color: #000000;' : '')}
    box-shadow: ${({ isSticky }: RootProps) =>
      isSticky ? '0px 4px 14px rgba(0, 0, 0, 0.1)' : 'none'};
    top: ${({ offsetTop }: RootProps) => `${offsetTop}px`};
  }
`

export const ListItemPopper = styled(Popper)`
  & .MuiTooltip-tooltip {
    margin-top: 0;
  }
`
